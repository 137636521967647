import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  static DATE_FORMAT_BACK = /[0-9]{4}-[0-9]{2}-[0-9]{2}/g;
  static DATE_FORMAT = 'YYYY-MM-DD';
  static TIME_ZONE = 'Europe/Paris';

  public static secureDateHourAndMSecToZero(date): moment.Moment {
    if (date != null) {
      if (moment.isMoment(date)) {
        date.set({ millisecond: 0 });
      } else {
        console.error('Date format unknown');
        console.error(date);
      }
    }
    return date;
  }

  public static getDate(year: any, hour: any, second: any): moment.Moment {
    return moment(`${year}-${hour}-${second}`, DateService.TIME_ZONE);
  }

  public static nowAsMoment(): moment.Moment {
    const date = moment();
    return DateService.secureDateHourAndMSecToZero(date);
  }

  public static nowAsString(): string {
    return DateService.nowAsMoment().format(DateService.DATE_FORMAT);
  }

  public static string2Moment(date: string | moment.Moment): moment.Moment {
    let dateToFormat = null;
    if (typeof date === 'string' && date) {
      dateToFormat = moment(date, DateService.DATE_FORMAT);
    } else {
      dateToFormat = date;
    }

    return DateService.secureDateHourAndMSecToZero(dateToFormat);
  }

  public static format(valeur: string, longeur: number) {
    const output = valeur;
    if (valeur.length < longeur) {
      return DateService.format('0' + output, longeur);
    } else {
      return output;
    }
  }

  public static convertDate2Text(date: any): string {
    let dateOut = null;
    if (date != null) {
      switch (date.constructor.name) {
        case 'String':
          if (date.match(DateService.DATE_FORMAT_BACK) != null) {
            dateOut = date.replace('-', '/');
          } else {
            dateOut = date;
          }
          break;
        case 'Date':
          dateOut =
            DateService.format(date.getFullYear().toString(), 4) +
            '/' +
            DateService.format((date.getMonth() + 1).toString(), 2) +
            '/' +
            DateService.format(date.getDate().toString(), 2);
          break;
        case 'Moment':
          dateOut = date.format('YYYY/MM/DD');
          break;
        default:
          dateOut =
            DateService.format(date.year().toString(), 4) +
            '/' +
            DateService.format(date.month().toString(), 2) +
            '/' +
            DateService.format(date.day().toString(), 2);
      }
    }
    return dateOut;
  }
}
