import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpEvent } from '@angular/common/http';

import { ICrudService } from '@shared/api/crud/i-crud.service';
import { AbstractRtlqService } from '@shared/api/crud/abstract-main.service';

import { IDriveModelLike } from '@drive/models/drive.model';
import { DriveBuilder } from '@drive/builder/drive.builder';
import { DriveConfig } from '@drive/resource/drive.config';

export interface IDriveServiceLike extends ICrudService<IDriveModelLike> {
  extractUrlByKey(id: string, key: Object): string;
}
@Injectable({
  providedIn: 'root',
})
export class DriveService extends AbstractRtlqService<IDriveModelLike> implements IDriveServiceLike {
  public limitUpload = DriveConfig.LIMIT_UPLOAD; // 125 Mo

  constructor(protected http: HttpClient, protected builder: DriveBuilder) {
    super(http, builder, DriveConfig.REST_URL);
  }

  createUrlEntityById(id: any): Observable<Object> {
    return this.getHttp().post(`${this.getFullUrl()}/${id}/key`, {});
  }

  extractUrlByKey(id: string, key: Object): string {
    return `${this.baseUrl}/${this.getUrl()}/${id}/key?key=` + encodeURIComponent(<string>key);
  }

  add(driveToSave: IDriveModelLike): Observable<IDriveModelLike> {
    return of(driveToSave);
  }

  upload(driveToSave: IDriveModelLike): Observable<HttpEvent<unknown>> {
    this._checkBeforeUploading(driveToSave.formData, driveToSave.formDataName);

    return this.http.post(`${this.getFullUrl()}`, driveToSave.formData, {
      reportProgress: true,
      observe: 'events',
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  private _checkBeforeUploading(content: FormData, name: string) {
    const fileSize = (<File>content.get(name)).size;
    if (fileSize > this.limitUpload) {
      const fileSizeMega = Math.round((fileSize / 1000000) * 100) / 100;
      const limitUploadMega = Math.round((this.limitUpload / 1000000) * 100) / 100;

      const msg = 'Taille du fichier ' + fileSizeMega + ' Mo trop importante (limite: ' + limitUploadMega + ' Mo) \n';
      console.error(msg);
      throw new Error(msg);
    }
  }
  
}
