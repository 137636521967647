import { AbstractCrudModel } from '@shared/modeles/crud/absract-crud.model';
import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';

export interface ITaoHistoModelLike extends ICrudModelLike {
  tao_name: string;
  action: string;
  date_update: moment.Moment;
}

export class TaoHistoModel extends AbstractCrudModel implements ITaoHistoModelLike {
  constructor(public id = null, public tao_name = null, public action = null, public date_update = null) {
    super(id);
  }
}
