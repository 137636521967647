import { InjectionToken, Inject, Injectable } from '@angular/core';
import { EntityCollectionServiceBase } from '@ngrx/data';

import { IDropdownModelLike } from '@shared/modeles/dropdown/dropdown.model';

import { app_config } from 'app/app.config';


export const DROPDOWN_SERVICE_TOKEN = new InjectionToken<EntityCollectionServiceBase<IDropdownModelLike>[]>('services');
@Injectable({ providedIn: 'root' })
export class DropDownService {
  static DROPDOWNS: { [key: string]: string } = {
    SAISON: app_config.STORE.SaisonConfig,
    TRESORERIE_CATEGORIE: app_config.STORE.TresorerieCategorieConfig,
    TRESORERIE_ETAT: app_config.STORE.TresorerieEtatConfig,
    PHOTO_DIRECTORY: app_config.STORE.PhotoDirectoryConfig,
    COTISATION: app_config.STORE.CotisationConfig,
    USER: app_config.STORE.UserConfig,
    KUNGFU_NIVEAU: app_config.STORE.KungFuNiveauConfig,
    KUNGFU_STYLE: app_config.STORE.KungFuStyleConfig,
  };
  private _services: { [key: string]: EntityCollectionServiceBase<IDropdownModelLike> } = {};
  constructor(
    @Inject(DROPDOWN_SERVICE_TOKEN) private _servicesInjected: EntityCollectionServiceBase<IDropdownModelLike>[]
  ) {
    this._servicesInjected.forEach((element) => {
      this._services[element.entityName] = element;
    });
  }
  get(name: string): EntityCollectionServiceBase<IDropdownModelLike> {
    const serviceDropDown = this._services[name];
    if (serviceDropDown) {
      return serviceDropDown;
    } else {
      console.error("Echec d'initialisation : " + name + ' did you forget to add the service in app.module?');
      return null;
    }
  }
}
