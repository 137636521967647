<!-- new syntax for ng-if -->
<section class="container" id="changePassword">
  <div class="changePasswordWindow">
    <span>Changer votre mot de passe</span>

    <div class="logo"></div>
    <form (ngSubmit)="f.valid && changePassword(f)" #f="ngForm">
      <div span class="mb-3" [ngClass]="{ 'has-error': password.touched && !password.valid }">
        <label for="password">Password</label>
        <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required />
        <div *ngIf="password.touched && !password.valid" class="help-block">Password is required</div>
      </div>
      <div class="mb-3" [ngClass]="{ 'has-error': confirmation.touched && !confirmation.valid }">
        <label for="confirmation">Confirmation</label>
        <input type="password" class="form-control" name="confirmation" [(ngModel)]="model.confirmation"  #confirmation="ngModel" required />
        <div *ngIf="confirmation.touched && !confirmation.valid" class="help-block">Confirmation is required</div>
      </div>
      <div class="mb-3 right">
        <div class="mb-3 text-center">
          <app-button
            type="submit"
            [(disabled)]="!f.valid"
            [action_status]="loading"
            action_name="Changer mon mot de passe"
            action_name_inprogress="Changement"
            action_icone="fa-sign-out"
          ></app-button>
        </div>
      </div>
    </form>
    <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
    <div *ngIf="info" class="alert alert-success">{{ info }}</div>
    <div class="mb-3 text-right" *ngIf="showHomeRouterLink">
      <a [routerLink]="getLoginRoute()">s'authentifier</a>
    </div>
  </div>
</section>
