import { Subject, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityCollectionService } from '@ngrx/data';

import { RessourceService } from '@shared/api/config/ressource.service';
import { Dropdown } from '@shared/modeles/dropdown/dropdown.model';
import { CrudConfigModel } from '@shared/modeles/crud/crud-config.model';
import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';
import { NotificationService } from '@shared/api/notif/notifier.service';

import { UploadImage } from '../photo-field/upload-image.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RtlqModalComponent } from '@shared/components/rtlq-modal.component';

export abstract class AbstractModalEditorComponent<T extends ICrudModelLike> extends RtlqModalComponent<T> {
  private _runOnHideSub: Subscription;

  protected entityService: EntityCollectionService<T> = null;
  protected initColsForm: CrudConfigModel[] = [];
  protected usedColsForm: CrudConfigModel[] = [];

  parentForm: FormGroup;

  public entityForm: T = null;
  public title = 'Edition';

  suggestion;
  uploadImage: UploadImage;
  // output event
  showSave = true;
  showCancel = true;

  constructor(
    protected formBuilder: FormBuilder,
    protected modalService: NgbModal,
    protected notificationService: NotificationService
  ) {
    super(modalService, notificationService);
    this.uploadImage = new UploadImage();
  }

  customSetup(args: { entityForm: T; colsForm: CrudConfigModel[]; entityService: EntityCollectionService<T> }) {
    this.entityForm = args.entityForm;
    this.initColsForm = args.colsForm;
    this.entityService = args.entityService;
    this.loading$ = args.entityService.loading$;
  }

  customNgOnInit() {
    //creation du formulaire parent
    this.parentForm = this.formBuilder.group({});
    this.updateColsForm();
    this.loading$ = this.entityService.loading$;
  }

  public updateColsForm() {
    this.usedColsForm = Object.assign([], this.initColsForm);
  }

  /**
   * onSave
   */
  public onSave(): void {
    this.resetInfoMessage();
    this.resetErrorMessage();
    // this.startLoader();

    //merging form into entityFrom
    this.entityForm = { ...this.entityForm, ...this.parentForm.value };

    this.onSaveAction();
  }

  abstract onSaveAction(): void;
}
