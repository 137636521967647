import { Component, Input, Output, EventEmitter } from '@angular/core';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';

import { map, tap, last, catchError } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService } from '@shared/api/notif/notifier.service';
import { RtlqModalComponent } from '@shared/components/rtlq-modal.component';

import { DriveService } from '@drive/api/drive/drive.service';
import {IDriveBuilderLike } from '@drive/builder/drive.builder';
import { DriveEntityService } from '@drive/api/drive/drive.entity-service';
import { IDriveModelLike } from '@drive/models/drive.model';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class UploaderComponent extends RtlqModalComponent<IDriveModelLike> {
  /** Link text */
  @Input() text = 'Téléchager ma vidéo';
  /** Name used in form which will be sent in HTTP request. */
  @Input() param = 'source';

  /** File extension that accepted, same as 'accept' of <input type="file" />.
      By the default, it's set to 'image/*'. */
  @Input() accept = 'video/*,image/*';

  private filename: string = null;
  public hasError = false;
  public files: Array<FileUploadModel> = [];
  public abstractController: any;

  constructor(
    protected driveEntityService: DriveEntityService,
    protected service: DriveService,
    protected modalService: NgbModal,
    protected notificationService: NotificationService
  ) {
    super(modalService, notificationService);
  }
  
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    super.ngOnInit();
  }

  doUpload() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];

        let filename = file.name;
        if (this.filename != null) {
          // extract extension from the file
          const extension = file.name.split('.').pop();
          filename = this.filename + '.' + extension;
        }
        this.files.push({
          data: file,
          custom_name: filename,
          state: 'in',
          inProgress: false,
          inUpload: false,
          inConvert: false,
          progress: 0,
          canRetry: false,
          error: null,
          canCancel: true,
        });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  cancelFile(file: FileUploadModel) {
    if (file.sub) {
      file.sub.unsubscribe();
    }
    this.removeFileFromArray(file);
  }

  retryFile(file: FileUploadModel) {
    this.uploadFile(file);
    file.canRetry = false;
  }

  private uploadFile(file: FileUploadModel) {
    const driveToSave = this.builder.createEntityWithFormData(this.param, file);
    try {
      file.inProgress = true;

      file.sub = this.service
        .upload(driveToSave)
        .pipe(
          map((event) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                file.progress = Math.round((event.loaded * 100) / event.total);
                if (file.progress === 100) {
                  file.inUpload = false;
                  file.inConvert = file.data.type.startsWith('video/');
                } else {
                  file.inUpload = true;
                  file.inConvert = false;
                }
                break;
              case HttpEventType.Response:
                // update the local entities store
                return event;
            }
          }),
          tap((message) => {}),
          last(),
          catchError((error: HttpErrorResponse) => {
            file.inProgress = false;
            file.inUpload = false;
            file.inConvert = false;
            file.canRetry = true;
            this.hasError = true;
            return of(`${file.data.name} upload failed.`);
          })
        )
        .subscribe((event: any) => {
          if (typeof event === 'object') {
            this.removeFileFromArray(file);
            this.driveEntityService.add(this.builder.convertJsonToModele(event.body));
            if (this.files.length === 0) {
              this.setCompleted(event.body);
            }
          }
        });
    } catch (error) {
      file.inProgress = false;
      file.inConvert = false;
      file.inUpload = false;
      file.canCancel = true;
      file.canRetry = false;
      file.error = error;
      this.hasError = true;
    }
  }

  public get builder(): IDriveBuilderLike {
    return <IDriveBuilderLike>this.service.getBuilder();
  }

  private uploadFiles() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.value = '';
    this.hasError = false;
    this.files.forEach((file) => {
      this.uploadFile(file);
    });
  }

  private removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  close() {
    this.setCompleted(null);
    this.modalService.dismissAll();
  }

  setFilename(value) {
    this.filename = value;
  }
}

export class FileUploadModel {
  data: File;
  custom_name: string;
  state: string;
  error: string;
  inProgress: boolean;
  inConvert: boolean;
  inUpload: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  sub?: Subscription;
}
