import { Injectable } from '@angular/core';

import { RessourceService } from '@shared/api/config/ressource.service';
import { DropDownService } from '@shared/api/dropdown/dropdown.service';
import { ICrudConfigModel } from '@shared/modeles/crud/crud-config.model';
import { IRouteItemModelLike } from '@shared/modeles/menu/route-item.model';
import { AbstractRtlqConfig } from '@shared/resource/rtlq.config';

import { app_config } from 'app/app.config';



@Injectable({
  providedIn: 'root',
})
export class TresorerieEtatConfig extends AbstractRtlqConfig {
  static MENU: IRouteItemModelLike = RessourceService.MENUS.URL_TRESORERIE_ETAT;
  static REST_URL = 'tresorerie/etats';
  static MODULE = TresorerieEtatConfig.MENU.module;
  static DOMAINE = TresorerieEtatConfig.MENU.uri;
  static STORE = app_config.STORE.TresorerieEtatConfig;

  get module(): string {
    return TresorerieEtatConfig.MODULE;
  }

  get domaine(): string {
    return TresorerieEtatConfig.DOMAINE;
  }

  get id(): string {
    return TresorerieEtatConfig.MENU.id;
  }
  getCols(): Array<ICrudConfigModel> {
    const config: Array<ICrudConfigModel> = [];

    config.push(this.createCrudConfigModel('id').withType(RessourceService.CONST.TYPE_NUMBER).withoutFormOrTable());

    config.push(this.createCrudConfigModel('id').withType(RessourceService.CONST.TYPE_NUMBER).withOnlyTable());
    config.push(this.createCrudConfigModel('nom').isFormRequired());
    config.push(
      this.createCrudConfigModel('next_etat')
        .withOnlyForm()
        .withDropDown(DropDownService.DROPDOWNS.TRESORERIE_ETAT)
    );
    config.push(this.createCrudConfigModel('next_etat_name').withOnlyTable());

    return config;
  }
}
