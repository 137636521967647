import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { PageNotFoundComponent } from './components/pagenotfound/page-not-found.component';
import { MenuComponent } from './components/menu/menu.component';

@NgModule({
  imports: [RouterModule, HttpClientModule, BrowserAnimationsModule, FormsModule],
  declarations: [FooterComponent, HeaderComponent, PageNotFoundComponent, MenuComponent],
  exports: [FooterComponent, HeaderComponent, PageNotFoundComponent, MenuComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
