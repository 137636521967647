<div class="mb-3">
  <!------------------------------------------------------------------------------------------------>
  <mat-form-field [formGroup]="parentForm" class="mb-3" *ngIf="checkRole && !col.user_edit">
    <input
      matInput
      [formControlName]="col.field"
      class="form-control"
      [id]="col.field"
      [placeholder]="col.form_header"
    />
  </mat-form-field>
  <!------------------------------------------------------------------------------------------------>

  <mat-form-field
    [formGroup]="parentForm"
    class="mb-3"
    *ngIf="col.type == 'checkbox' && ((checkRole && col.user_edit) || !checkRole)"
  >
    <mat-checkbox
      [formControlName]="col.field"
      class="form-control"
      [id]="col.field"
      color="accent"
      [labelPosition]="labelPosition"
      [required]="col.form_required"
    >
      {{ col.form_header }}
    </mat-checkbox>

    <app-generic-field-error [field]="parentForm.get(col.field)"></app-generic-field-error>
  </mat-form-field>

  <!------------------------------------------------------------------------------------------------>
  <div
    [formGroup]="parentForm"
    class="mb-3"
    *ngIf="col.type == 'boolean' && ((checkRole && col.user_edit) || !checkRole)"
  >
    <mat-slide-toggle
      [formControlName]="col.field"
      class="form-control"
      [id]="col.field"
      [required]="col.form_required"
    >
      {{ col.form_header }}
    </mat-slide-toggle>
    <app-generic-field-error [field]="parentForm.get(col.field)"></app-generic-field-error>
  </div>

  <!------------------------------------------------------------------------------------------------>
  <mat-form-field
    class="mb-3"
    [formGroup]="parentForm"
    *ngIf="col.type == 'string' && ((checkRole && col.user_edit) || !checkRole)"
  >
    <mat-label>{{ col.form_header }}</mat-label>
    <input
      matInput
      [id]="col.field"
      [formControlName]="col.field"
      class="form-control"
      [placeholder]="col.form_header"
      [required]="col.form_required"
      (change)="(change)"
    />
    <button
      *ngIf="!col.form_readonly"
      class="btn btn-outline-danger resetbtn"
      type="button"
      matSuffix
      (click)="entity[col.field] = null"
    >
      <i class="fas fa-times"></i>
    </button>
    <app-generic-field-error [field]="parentForm.get(col.field)"></app-generic-field-error>
  </mat-form-field>

  <!------------------------------------------------------------------------------------------------>
  <mat-form-field
    class="mb-3"
    [formGroup]="parentForm"
    *ngIf="col.type == 'number' && ((checkRole && col.user_edit) || !checkRole)"
  >
    <input
      matInput
      [id]="col.field"
      [formControlName]="col.field"
      class="form-control"
      type="number"
      [step]="col.form_step"
      [placeholder]="col.form_header"
      [required]="col.form_required"
    />
    <button
      *ngIf="!col.form_readonly"
      class="btn btn-outline-danger resetbtn"
      type="button"
      matSuffix
      (click)="entity[col.field] = null"
    >
      <i class="fas fa-times"></i>
    </button>
    <app-generic-field-error [field]="parentForm.get(col.field)"></app-generic-field-error>
  </mat-form-field>
  <!------------------------------------------------------------------------------------------------>
  <mat-form-field
    class="mb-3"
    [formGroup]="parentForm"
    *ngIf="col.type == 'textArea' && ((checkRole && col.user_edit) || !checkRole)"
  >
    <textarea
      matInput
      [formControlName]="col.field"
      class="form-control"
      [id]="col.field"
      [placeholder]="col.form_header"
      [required]="col.form_required"
      rows="3"
    >
    </textarea>
    <button
      *ngIf="!col.form_readonly"
      class="btn btn-outline-danger resetbtn"
      type="button"
      matSuffix
      (click)="entity[col.field] = null"
    >
      <i class="fas fa-times"></i>
    </button>
    <app-generic-field-error [field]="parentForm.get(col.field)"></app-generic-field-error>
  </mat-form-field>
  <!------------------------------------------------------------------------------------------------>
  <mat-form-field
    class="mb-3"
    [formGroup]="parentForm"
    *ngIf="col.type == 'date' && ((checkRole && col.user_edit) || !checkRole)"
  >
    <input
      matInput
      [formControlName]="col.field"
      class="form-control"
      [id]="col.field"
      [matDatepicker]="picker"
      [placeholder]="col.form_header"
      [required]="col.form_required"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
    <mat-datepicker #picker touchUi="true"></mat-datepicker>
    <button
      *ngIf="!col.form_readonly"
      class="btn btn-outline-danger resetbtn"
      type="button"
      matSuffix
      (click)="entity[col.field] = null"
    >
      <i class="fas fa-times"></i>
    </button>
    <app-generic-field-error [field]="parentForm.get(col.field)"></app-generic-field-error>
  </mat-form-field>

  <img *ngIf="col.type == 'urlImage' && ((checkRole && col.user_edit) || !checkRole)" src="{{ entity[col.field] }}" />

  <!------------------------------------------------------------------------------------------------>
  <div *ngIf="(col.type == 'dropdown' || col.type == 'multiSelect') && ((checkRole && col.user_edit) || !checkRole)">
    <app-dropdown
      [multiple]="col.type == 'multiSelect'"
      [parentForm]="parentForm"
      [entityServiceName]="col.entityService"
      [entity]="entity"
      [label]="col.form_header"
      [field]="col.field"
      [required]="col.form_required"
      [readonly]="col.form_readonly"
    >
    </app-dropdown>
  </div>

  <!------------------------------------------------------------------------------------------------>

  <div *ngIf="(col.type == 'autoComplete') && ((checkRole && col.user_edit) || !checkRole)">
    <app-suggestion
      [parentForm]="parentForm"
      [entityServiceName]="col.entityService"
      [entity]="entity"
      [label]="col.form_header"
      [field]="col.field"
      [required]="col.form_required"
      [readonly]="col.form_readonly"
      (select)="col.onSelect($event)"
      (clear)="col.onClearSelect()"
    >
    </app-suggestion>
  </div>

 
  <!------------------------------------------------------------------------------------------------>

  <div
    class="mb-3"
    [formGroup]="parentForm"
    *ngIf="col.type == 'listString' && ((checkRole && col.user_edit) || !checkRole)"
  >
    <mat-label>{{ col.form_header }}**** </mat-label>
    <mat-select
      [formControlName]="col.field"
      class="form-control"
      [compareWith]="compareObjects"
      [required]="col.form_required"
      (selectionChange)="selectionValue(entity, col.field, $event)"
    >
      <mat-option *ngFor="let value of col.form_values" [value]="value.value">
        {{ value.value }}
      </mat-option>
    </mat-select>
  </div>
  <!------------------------------------------------------------------------------------------------>

  <app-photo-field
    *ngIf="col.type == 'photo' && ((checkRole && col.user_edit) || !checkRole)"
    [parentForm]="parentForm"
    [propertyToSet]="col.field"
    [propertyToShow]="col.field_ToShow"
    [uploadImage]="uploadImage"
    [(entity)]="entity"
  >
  </app-photo-field>
  <!------------------------------------------------------------------------------------------------>
  <div *ngIf="col.type == 'video' && ((checkRole && col.user_edit) || !checkRole)">
    <vg-player (onPlayerReady)="onViewerReady($event)">
      <vg-overlay-play></vg-overlay-play>
      <vg-buffering></vg-buffering>

      <vg-controls [vgAutohide]="true" [vgAutohideTime]="5">
        <vg-play-pause></vg-play-pause>
        <vg-playback-button [playbackValues]="['1.0', '2.0', '4.0']"></vg-playback-button>

        <div class="video-button-content" (click)="video_api.currentTime = video_api.currentTime - 10">
          <i class="fas fa-undo video-button-icon"></i>
          <div class="video-button-legend">-10s</div>
        </div>
        <vg-scrub-bar>
          <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
          <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>
        <div class="video-button-content" (click)="video_api.currentTime = video_api.currentTime + 10">
          <i class="fas fa-redo video-button-icon"></i>
          <div class="video-button-legend">+10s</div>
        </div>
        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
        <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
        <vg-mute></vg-mute>
        <vg-fullscreen></vg-fullscreen>
      </vg-controls>
      <video [vgMedia]="media" #media id="singleVideo" preload="auto">
        <source [src]="col.value" [type]="col.sub_type" />
      </video>
    </vg-player>
  </div>
</div>
