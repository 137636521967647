import { ICrudModelLike } from '../crud/i-crud.model';



// used for object model to insure the existance of the attribut id and nom.
export interface IDropdownModelLike extends ICrudModelLike {
  nom: string;
  convertToDropDown(): IDropdown;
}

export interface IDropdown {
  id: number;
  value: string;
  }

export class Dropdown implements IDropdown {
  public id: number;
  public value: string;
  
  constructor(id, value) {
    this.id = id;
    this.value = value;
  }

  toArray() {
    const arrayDD = [];
    arrayDD['id'] = this.id;
    arrayDD['value'] = this.value;
    return arrayDD;
  }
}
