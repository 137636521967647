import { AbstractCrudDropDownModel } from '@shared/modeles/crud/absract-crud.model';
import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';
import { IDropdownModelLike } from '@shared/modeles/dropdown/dropdown.model';

export interface ITresorerieEtatModelLike extends IDropdownModelLike, ICrudModelLike {
  next_etat_id: number;
  next_etat_name: string;
}

export class TresorerieEtatModel extends AbstractCrudDropDownModel implements ITresorerieEtatModelLike {
  constructor(
    public id = null,
    public nom = null,
    public next_etat_id: number = null,
    public next_etat_name: string = null
  ) {
    super(id);
  }

  public setNextEtat(selectedObject) {
    this.next_etat_id = selectedObject.id;
    this.next_etat_name = selectedObject.value;
  }

  public consolidation() {
    super.consolidation();

    this.initDropDown('next_etat');
  }
}
