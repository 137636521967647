import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';

import { RtlqDataService } from '@shared/api/ngrx/rtlq.data-service';

import { ITaoHistoModelLike } from '@tao/models/tao-histo.model';
import { TaoHistoConfig } from '@tao/resource/tao-histo.config';
import { TaoHistoService } from './tao-histo.service';

@Injectable({ providedIn: 'root' })
export class TaoHistoDataService extends RtlqDataService<ITaoHistoModelLike> {
  constructor(
    protected apiService: TaoHistoService,
    protected http: HttpClient,
    protected httpUrlGenerator: HttpUrlGenerator
  ) {
    super(TaoHistoConfig.STORE, apiService, http, httpUrlGenerator);
  }
}
