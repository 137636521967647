import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ICrudService } from '@shared/api/crud/i-crud.service';
import { AbstractRtlqService } from '@shared/api/crud/abstract-main.service';

import { IKungFuNiveauModelLike } from '@kungfu/models/kungfu-niveau.model';
import { KungFuNiveauBuilder } from '@kungfu/builder/kungfu-niveau.builder';
import { KungFuNiveauConfig } from '@kungfu/resource/kungfu-niveau.config';

export interface IKungFuNiveauServiceLike extends ICrudService<IKungFuNiveauModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class KungFuNiveauService
  extends AbstractRtlqService<IKungFuNiveauModelLike>
  implements IKungFuNiveauServiceLike {
  constructor(protected http: HttpClient, protected builder: KungFuNiveauBuilder) {
    super(http, builder, KungFuNiveauConfig.REST_URL);
  }
}
