import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';

import { RtlqDataService } from '@shared/api/ngrx/rtlq.data-service';
import { IUserModelLike } from '@user/models/user.model';
import { UserConfig } from '@user/resource/user.config';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class UserDataService extends RtlqDataService<IUserModelLike> {
  constructor(
    protected apiService: UserService,
    protected http: HttpClient,
    protected httpUrlGenerator: HttpUrlGenerator
  ) {
    super(UserConfig.STORE, apiService, http, httpUrlGenerator);
  }
}
