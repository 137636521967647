import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';

import { RtlqDataService } from '@shared/api/ngrx/rtlq.data-service';
import { ITresorerieEtatModelLike } from '@tresorerie/models/tresorerie-etat.model';
import { TresorerieEtatConfig } from '@tresorerie/resource/tresorerie-etat.config';
import { TresorerieEtatService } from './tresorerie-etat.service';

@Injectable({ providedIn: 'root' })
export class TresorerieEtatDataService extends RtlqDataService<ITresorerieEtatModelLike> {

  constructor(
    protected apiService: TresorerieEtatService,
    protected http: HttpClient,
    protected httpUrlGenerator: HttpUrlGenerator
  ) {
    super(TresorerieEtatConfig.STORE, apiService, http, httpUrlGenerator);
  }
}
