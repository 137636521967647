import { Injectable } from '@angular/core';

import { RessourceService } from '@shared/api/config/ressource.service';
import { ICrudConfigModel } from '@shared/modeles/crud/crud-config.model';
import { IRouteItemModelLike } from '@shared/modeles/menu/route-item.model';
import { AbstractRtlqConfig } from '@shared/resource/rtlq.config';
import { app_config } from 'app/app.config';

@Injectable({
  providedIn: 'root',
})
export class SaisonConfig extends AbstractRtlqConfig {
  static MENU: IRouteItemModelLike = RessourceService.MENUS.URL_SAISON;
  static REST_URL = 'saisons';
  static REST_URI_SAISON_DUPLICATE = 'duplicate';
  static REST_URI_SAISON_ACTIVE = 'active';
  static MODULE = SaisonConfig.MENU.module;
  static DOMAINE = SaisonConfig.MENU.uri;
  static STORE = app_config.STORE.SaisonConfig;

  get module(): string {
    return SaisonConfig.MODULE;
  }

  get domaine(): string {
    return SaisonConfig.DOMAINE;
  }

  get id(): string {
    return SaisonConfig.MENU.id;
  }
  getCols(): Array<ICrudConfigModel> {
    const config: Array<ICrudConfigModel> = [];

    config.push(this.createCrudConfigModel('id').withType(RessourceService.CONST.TYPE_NUMBER).withoutFormOrTable());
    config.push(this.createCrudConfigModel('nom').withClazzLarge().isFormRequired());
    config.push(this.createCrudConfigModel('date_debut').withType(RessourceService.CONST.TYPE_DATE).isFormRequired());
    config.push(this.createCrudConfigModel('date_fin').withType(RessourceService.CONST.TYPE_DATE).isFormRequired());
    config.push(
      this.createCrudConfigModel('nb_adherents').withType(RessourceService.CONST.TYPE_NUMBER).withOnlyTable()
    );
    config.push(this.createCrudConfigModel('actif').withType(RessourceService.CONST.TYPE_BOOLEAN));

    return config;
  }
}
