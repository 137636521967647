import { Component, OnInit } from '@angular/core';
import { RessourceService } from '@shared/api/config/ressource.service';
import { PageConfigModel } from '@shared/modeles/config/page-config.model';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  static URI = 'not-found';

  homeUri: string[];

  constructor() {
    this.homeUri = PageConfigModel.getUri(RessourceService.MENU.URL_LOGIN);
  }

  ngOnInit(): void {}
}
