import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RessourceService } from '@shared/api/config/ressource.service';
import { AutoUnsubDecorator } from '@shared/components/decorator/auto-unsub.decorator';

import { ISessionUserModelLike } from '@authenticate/models/session-user.model';
import { SessionUserBuilder } from '@authenticate/builder/session-user.builder';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
@AutoUnsubDecorator()
export class CredentialService implements OnDestroy {
  static LOCAL_STORAGE_USER = 'currentAuthUser';
  private _localStorage: Storage;
  constructor(private http: HttpClient, protected builder: SessionUserBuilder) {
    try {
      this._localStorage = localStorage ? localStorage : null;
    } catch (e) {}
  }

  /**
   * get the userSession in the locale storage.
   */
  public retrieveUserSessionFromLocaleStorage(): ISessionUserModelLike {
    if (this._localStorage) {
      const localStorageToken = this._localStorage.getItem(RessourceService.CONST.X_AUTH_TOKEN);
      const authenticateFromStorage = JSON.parse(localStorageToken);
      return authenticateFromStorage ? this.builder.convertJsonToModele(authenticateFromStorage) : null;
    } else {
      return null;
    }
  }

  /**
   * write the userSession in the locale storage.
   */
  public storeUserSessionInLocaleStorage(userSession: ISessionUserModelLike): void {
    if (this._localStorage) {
      if (userSession) {
        this._localStorage.setItem(RessourceService.CONST.X_AUTH_TOKEN, JSON.stringify(userSession));
      } else {
        this.removeUserSessionFromLocaleStorage();
      }
    }
  }

  /**
   * remove the locale storage cookie.
   */
  public removeUserSessionFromLocaleStorage(): void {
    if (this._localStorage) {
      this._localStorage.removeItem(RessourceService.CONST.X_AUTH_TOKEN);
    }
  }

  /**
   * Methode asyncrhone permettant de valider l'authentification de l'utilisateur.
   */
  public checkUserAuthentication(): Observable<ISessionUserModelLike> {
    return this.http
      .get(`${AuthenticationService.URL_BASE}/${AuthenticationService.URL_CHECK_USER}`)
      .pipe(map((data) => this.builder.convertJsonToModele(data)));
  }

  public ngOnDestroy(): void {}
}
