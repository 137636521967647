import { Injectable } from '@angular/core';
import { AbstractCrudBuilder, IBuilderLike } from '@shared/builder/abstract-rtlq.build';
import { TaoHistoModel, ITaoHistoModelLike } from '@tao/models/tao-histo.model';

export interface ITaoHistoBuilderLike extends IBuilderLike<ITaoHistoModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class TaoHistoBuilder extends AbstractCrudBuilder<ITaoHistoModelLike> implements ITaoHistoBuilderLike {
  getEntity() {
    return TaoHistoModel;
  }
}
