import { ImageCroppedEvent } from 'ngx-image-cropper';

export class UploadImage {
  private imageChangedEvent: any = '';
  private croppedImage: any = '';
  private selecting = false;
  private selected = false;

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.selecting = true;
    this.selected = false;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageHasChanged() {
    return this.croppedImage !== '';
  }

  reset() {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.selected = false;
    this.selecting = false;
  }

  select() {
    this.selected = true;
    this.selecting = false;
  }

  isImageSelected() {
    return this.selected && !this.selecting;
  }

  isImageSelecting() {
    return this.selecting && !this.selected;
  }
  getCroppedImage() {
    return this.croppedImage;
  }
}
