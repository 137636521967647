import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IEntitiesActionsBuilderLike } from '@association/_store/rtlq.actions';
import { EntityCollectionService } from '@ngrx/data';
import { CrudConfigModel } from '@shared/modeles/crud/crud-config.model';
import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AbstractModalEditorComponent } from './abstract-modal-editor.component';

@Component({
  selector: 'app-bulk-edit-modal-editor',
  templateUrl: './modal-editor.component.html',
  styleUrls: ['./modal-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkEditModalEditorComponent<T extends ICrudModelLike> extends AbstractModalEditorComponent<T> {
  public entitiesToUpdate: T[] = [];
  private _updateSub: Subscription;

  public updateColsForm() {
    this.usedColsForm = [];
    this.initColsForm.forEach((colForm) => {
      const cloneColForm = <CrudConfigModel>colForm.clone();
      cloneColForm.isFormNotRequired();
      this.usedColsForm.push(cloneColForm);
    });
  }

  customSetup(args: {
    entityForm: T;
    entitiesToUpdate: T[];
    colsForm: CrudConfigModel[];
    entityService: EntityCollectionService<T>;
  }) {
    super.customSetup(args);
    this.entitiesToUpdate = args.entitiesToUpdate;
  }

  public onSaveAction(): void {
    const obsCalled: Observable<any>[] = [];
    const updateEntities: { [key: number]: T } = {};
    // merge the payload into the editedEntities
    this.entitiesToUpdate.forEach((elt) => {
      const newData = JSON.parse(
        JSON.stringify(this.entityForm, (key, value) => (value === null || value === undefined ? undefined : value))
      );

      const update: T = <T>{ ...elt, ...newData };
      update.processing = true;
      updateEntities[update.id] = update;
      obsCalled.push(
        this.entityService.update(update).pipe(
          map((resData) => {
            updateEntities[resData.id] = null;
          })
        )
      );
    });

    this.showInfoMessage('Mise à jour en cours - [' + this.entitiesToUpdate.length + ']');

    this._updateSub = forkJoin([...obsCalled]).subscribe(
      (results) => {
        this.showSuccessMessage(
          'Mise à jour effectuée - [' + results.length + ' sur ' + this.entitiesToUpdate.length + ']'
        );
        this.runOnHide();
      },
      (errors) => {
        this.showErrorMessage(errors.message);
        Object.getOwnPropertyNames(updateEntities).forEach((prop) => {
          if (updateEntities[prop]) {
            const entityKo = updateEntities[prop].clone();
            entityKo.afterRunningUnSuccessfully();
            this.entityService.updateOneInCache(entityKo);
          }
        });
      }
    );
  }

}
