import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { IBuilderLike } from '@shared/builder/abstract-rtlq.build';
import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';
import { AbstractRootService } from './abstract-root.service';
import { ICrudService } from './i-crud.service';

export interface IMainWithBuilderServiceLike<T extends ICrudModelLike> {
  mapEntities(data): T[];
  mapEntity(data): T;
  toEntity(r: any): T;
  getFullUrl(): string;
  getCustomFullUrl(url: string): string;
  getHttp();
  getUrl();
}

export abstract class AbstractRootWithBuilderService<T extends ICrudModelLike>
  extends AbstractRootService
  implements IMainWithBuilderServiceLike<T> {
  constructor(protected http: HttpClient, protected builder: IBuilderLike<T>, protected url: string) {
    super(http);
  }

  mapEntities(response): T[] {
    return response.map(this.toEntity, this);
  }

  mapEntity(response): T {
    return this.toEntity(response);
  }

  getUrl(): string {
    return this.url;
  }

  getFullUrl() {
    return `${this.baseUrl}/${this.getUrl()}`;
  }

  toEntity(r: any): T {
    return this.getBuilder().convertJsonToModele(r);
  }

  getBuilder(): IBuilderLike<T> {
    return this.builder;
  }

  getCustomFullUrl(url: string) {
    return `${this.baseUrl}/${url}`;
  }
}

export abstract class AbstractRtlqNotImplementedService<T extends ICrudModelLike>
  extends AbstractRootWithBuilderService<T>
  implements ICrudService<T> {
  constructor(protected http: HttpClient, protected builder: IBuilderLike<T>, protected url: string) {
    super(http, builder, url);
  }
  getWithQuery(param: any) {
    throw new Error('Method not implemented.');
  }
  update(entity: T): Observable<T> {
    throw new Error('Method not implemented.');
  }

  delete(id: number | string | T): Observable<number | string> {
    throw new Error('Method not implemented.');
  }
  add(entity: T): Observable<T> {
    throw new Error('Method not implemented.');
  }

  get(id: number): Observable<T> {
    throw new Error('Method not implemented.');
  }
  getAll(): Observable<T[]> {
    throw new Error('Method not implemented.');
  }
}

export abstract class AbstractRtlqGetterService<T extends ICrudModelLike>
  extends AbstractRtlqNotImplementedService<T>
  implements ICrudService<T> {
  constructor(protected http: HttpClient, protected builder: IBuilderLike<T>, protected url: string) {
    super(http, builder, url);
  }
  getAll(): Observable<T[]> {
    const entities = this.getHttp()
      .get(`${this.getFullUrl()}`)
      .pipe(map((response) => this.mapEntities(response)));

    return entities;
  }

  getWithQuery(queryParams?: {} | string, format = true) {
    const qParams = typeof queryParams === 'string' ? { fromString: queryParams } : { fromObject: queryParams };
    const params = new HttpParams(qParams);
    const resquest = this.getHttp().get(`${this.getFullUrl()}`, { params: params });
    if (format) {
      return resquest.pipe(map((response) => this.mapEntities(response)));
    } else {
      return resquest;
    }
  }
}

export abstract class AbstractRtlqService<T extends ICrudModelLike>
  extends AbstractRtlqGetterService<T>
  implements ICrudService<T> {
  constructor(protected http: HttpClient, protected builder: IBuilderLike<T>, protected url: string) {
    super(http, builder, url);
  }

  delete(id: number | string | T): Observable<number | string> {
    let idDelete;
    if (typeof id === 'object') {
      idDelete = id.id;
    } else {
      idDelete = id;
    }
    return this.getHttp()
      .delete(`${this.getFullUrl()}/${idDelete}`)
      .pipe(map((response) => idDelete));
  }

  update(entity: T): Observable<T> {
    return this.getHttp()
      .put(`${this.getFullUrl()}/${entity.id}`, entity)
      .pipe(map((response) => this.mapEntity(response)));
  }

  add(entity: T, options = {}): Observable<T> {
    // const entityJson = this.builder.convertResponseToModele(entity); // should be deleted
    // entity.consolidation(); // TODO needed ??

    return this.getHttp()
      .post(`${this.getFullUrl()}`, entity, options)
      .pipe(map((response) => this.mapEntity(response)));
  }

  get(id: number | string): Observable<T> {
    const entity = this.getHttp()
      .get(`${this.getFullUrl()}/${id}`)
      .pipe(map((data) => this.mapEntity(data)));

    return entity;
  }
}
