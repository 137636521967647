import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';

import { RtlqDataService } from '@shared/api/ngrx/rtlq.data-service';

import { IKungFuStyleModelLike } from '@kungfu/models/kungfu-style.model';
import { KungFuStyleConfig } from '@kungfu/resource/kungfu-style.config';
import { KungFuStyleService } from './kungfu-style.service';

@Injectable({ providedIn: 'root' })
export class KungFuStyleDataService extends RtlqDataService<IKungFuStyleModelLike> {
  constructor(
    protected apiService: KungFuStyleService,
    protected http: HttpClient,
    protected httpUrlGenerator: HttpUrlGenerator
  ) {
    super(KungFuStyleConfig.STORE, apiService, http, httpUrlGenerator);
  }
}
