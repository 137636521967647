import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ICrudService } from '@shared/api/crud/i-crud.service';
import { AbstractRtlqGetterService } from '@shared/api/crud/abstract-main.service';

import { ITaoHistoModelLike } from '@tao/models/tao-histo.model';
import { TaoHistoBuilder } from '@tao/builder/tao-histo.builder';
import { TaoHistoConfig } from '@tao/resource/tao-histo.config';

export interface ITaoHistoServiceLike extends ICrudService<ITaoHistoModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class TaoHistoService extends AbstractRtlqGetterService<ITaoHistoModelLike> implements ITaoHistoServiceLike {
  constructor(protected http: HttpClient, protected builder: TaoHistoBuilder) {
    super(http, builder, TaoHistoConfig.REST_URL);
  }
}
