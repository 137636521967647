import { ISessionUserModelLike } from '@authenticate/models/session-user.model';
import { ActionReducer } from '@ngrx/store';

import * as fromAuthActions from './auth.actions';
import { AuthActions } from './auth.actions';

export const authFeatureKey = 'auth';

export interface AuthState {
  auth: ISessionUserModelLike;
  authError: null;
  loading: boolean;
  redirect: boolean;
}

const initState: AuthState = {
  auth: null,
  authError: null,
  loading: false,
  redirect: true,
};

export function authReducer(state = initState, action: AuthActions) {
  if (action && action.doAction && action.type.startsWith(fromAuthActions.FEATURE)) {
    return action.doAction(state);
  } else {
    return state;
  }
}
