import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { NotificationService } from '@shared/api/notif/notifier.service';
import { RessourceService } from '@shared/api/config/ressource.service';
import { RtlqComponent } from '@shared/components/rtlq.component';
import { version } from '@env/../../package.json';
import { AuthenticateFacade } from '@authenticate/authenticate.facade';

import * as fromApp from '@app_store/app.reducer';
import * as AuthActions from '@authenticate/store/auth.actions';
import { PageConfigModel } from '@shared/modeles/config/page-config.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends RtlqComponent implements OnInit {
  loadingAutoConnect = false;
  version = version;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected authenticationFacade: AuthenticateFacade,
    protected modalService: NgbModal,
    protected notificationService: NotificationService,
    private store: Store<fromApp.AppState>
  ) {
    super(modalService, notificationService);
    this.loadingAutoConnect = false;
    this.isLoading = false;
  }

  public ngOnInit() {
    this.store.select('auth').subscribe((userAuth) => {
      console.log(userAuth);
      if (userAuth.auth) {
        if (userAuth.loading) {
          this.showInfoMessage('Authenticiation en cours ...', true);
        } else {
          this.router.navigate(['/']);
        }
        if (userAuth.authError) {
          this.showErrorMessage(userAuth.authError);
        } else {
          this.resetErrorMessage();
        }
      }
    });
  }

  public login(form: NgForm) {
    this.resetErrorMessage();
    this.store.dispatch(
      new AuthActions.LoginStartAction({
        username: form.value['username'],
        password: form.value['password'],
      })
    );
  }

  public getMotDePasseRoute() {
    return PageConfigModel.getUri(RessourceService.MENU.URL_RESET_PASSWORD);
  }
}
