export const app_config = {
  STORE: {
    AssociationConfig: 'associations',
    BenevolatConfig: 'benevolats',
    BureauConfig: 'bureaux',
    CategorieVoteeConfig: 'categories_votees',
    CoursConfig: 'cours',
    CurrentUserConfig: 'current-user',
    DriveConfig: 'drives',
    EventConfig: 'events',
    NewsConfig: 'news',
    GroupeConfig: 'groupes',
    MaterielConfig: 'materiels',
    PhotoConfig: 'photos',
    SaisonConfig: 'saisons',
    TaoCurrentUserConfig: 'taos-current-user',
    TaoUsersConfig: 'tao-users',
    TaoHistoConfig: 'tao-histo',
    TaoConfig: 'taos',
    TresorerieConfig: 'tresoreries',
    CurrentUserTresorerieConfig: 'tresorerie-current-user',
    TresorerieCategorieConfig: 'tresorerie-categories',
    TresorerieEtatConfig: 'tresorerie-etat',
    TrombinoscopeConfig: 'trombinoscopes',
    PhotoDirectoryConfig: 'photo-directories',
    CotisationConfig: 'cotisations',
    KungFuNiveauConfig: 'kungfu_niveaux',
    KungFuStyleConfig: 'kungfu_styles',
    UserConfig: 'users',
  },
};
