import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { SharedModule } from "@shared/shared.module";

import { DashboardUserComponent } from "./components/dashboard-user/dashboard-user.component";
import { KpiComponent } from "./components/kpi/kpi.component";
import { DashboardAssociationComponent } from "./components/dashboard-association/dashboard-association.component";
import { CardComponent } from "./components/card/card.component";
import { DashboardRoutingModule } from "./dashboard.routes";
import { AuthenticateModule } from "@authenticate/authenticate.module";
import { DiscordComponent } from "./components/dashboard-user/discord/discord.component";
import { TaoComponent } from "./components/dashboard-user/tao/tao.component";
import { ProfilComponent } from "./components/dashboard-user/profil/profil.component";
import { TresorerieComponent } from "./components/dashboard-user/tresorerie/tresorerie.component";

@NgModule({
  imports: [DashboardRoutingModule, SharedModule, AuthenticateModule],
  declarations: [
    CardComponent,
    DashboardAssociationComponent,
    KpiComponent,
    DashboardUserComponent,
    DiscordComponent,
    TaoComponent,
    TresorerieComponent,
    ProfilComponent,
  ],
  exports: [DashboardAssociationComponent, DashboardUserComponent, KpiComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class DashboardModule {}
