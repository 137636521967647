import { Injectable } from '@angular/core';

import { RessourceService } from '@shared/api/config/ressource.service';
import { ICrudConfigModel } from '@shared/modeles/crud/crud-config.model';
import { IRouteItemModelLike } from '@shared/modeles/menu/route-item.model';
import { AbstractRtlqConfig } from '@shared/resource/rtlq.config';
import { app_config } from 'app/app.config';

@Injectable({
  providedIn: 'root',
})
export class TaoHistoConfig extends AbstractRtlqConfig {
  static MENU: IRouteItemModelLike = RessourceService.MENUS.URL_TAOS_HISTO;
  static MODULE = TaoHistoConfig.MENU.module;
  static DOMAINE = TaoHistoConfig.MENU.uri;
  static REST_URL = 'taoadherents/me/historique';
  static URI_TAOS = 'taos';
  static STORE = app_config.STORE.TaoHistoConfig;

  get module(): string {
    return TaoHistoConfig.MODULE;
  }

  get domaine(): string {
    return TaoHistoConfig.DOMAINE;
  }

  get id(): string {
    return TaoHistoConfig.MENU.id;
  }

  getCols(): Array<ICrudConfigModel> {
    const config: Array<ICrudConfigModel> = [];

    config.push(this.createCrudConfigModel('tao_name'));
    config.push(this.createCrudConfigModel('action'));
    config.push(this.createCrudConfigModel('date_update').withType(RessourceService.CONST.TYPE_DATE));

    return config;
  }
}
