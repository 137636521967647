import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { environment } from '@env';
import { PageNotFoundComponent } from '@core/components/pagenotfound/page-not-found.component';
import { RessourceService } from '@shared/api/config/ressource.service';
import { RouteGuardService } from '@authenticate/api/security/route-guard.service';

const routes: Routes = [
  {
    path: RessourceService.MODULES.AUTHENTICATION,
    loadChildren: () => import('@authenticate/authenticate.module').then((m) => m.AuthenticateModule),
  },
  {
    path: RessourceService.MODULES.HOME,
    loadChildren: () => import('@home/home.module').then((m) => m.HomeModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.HOME) },
  },
  {
    path: RessourceService.MODULES.ASSOCIATION,
    loadChildren: () => import('@association/association.module').then((m) => m.AssociationModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.ASSOCIATION) },
  },
  {
    path: RessourceService.MODULES.BENEVOLAT,
    loadChildren: () => import('@benevolat/benevolat.module').then((m) => m.BenevolatModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.BENEVOLAT) },
  },
  {
    path: RessourceService.MODULES.TRESORERIE,
    loadChildren: () => import('@tresorerie/tresorerie.module').then((m) => m.TresorerieModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.TRESORERIE) },
  },
  {
    path: RessourceService.MODULES.COTISATION,
    loadChildren: () => import('@cotisation/cotisation.module').then((m) => m.CotisationModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.COTISATION) },
  },
  {
    path: RessourceService.MODULES.COURS,
    loadChildren: () => import('@cours/cours.module').then((m) => m.CoursModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.COURS) },
  },
  {
    path: RessourceService.MODULES.DRIVE,
    loadChildren: () => import('@drive/drive.module').then((m) => m.DriveModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.DRIVE) },
  },
  {
    path: RessourceService.MODULES.EVENT,
    loadChildren: () => import('@event/event.module').then((m) => m.EventModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.EVENT) },
  },
  {
    path: RessourceService.MODULES.BUREAU,
    loadChildren: () => import('@bureau/bureau.module').then((m) => m.BureauModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.BUREAU) },
  },
  {
    path: RessourceService.MODULES.GROUPE,
    loadChildren: () => import('@groupe/groupe.module').then((m) => m.GroupeModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.GROUPE) },
  },
  {
    path: RessourceService.MODULES.NEWS,
    loadChildren: () => import('@news/news.module').then((m) => m.NewsModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.NEWS) },
  },
  {
    path: RessourceService.MODULES.MATERIEL,
    loadChildren: () => import('@materiel/materiel.module').then((m) => m.MaterielModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.MATERIEL) },
  },
  {
    path: RessourceService.MODULES.PHOTO,
    loadChildren: () => import('@photo/photo.module').then((m) => m.PhotoModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.PHOTO) },
  },
  {
    path: RessourceService.MODULES.SAISON,
    loadChildren: () => import('@saison/saison.module').then((m) => m.SaisonModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.SAISON) },
  },
  {
    path: RessourceService.MODULES.TAO,
    loadChildren: () => import('@tao/tao.module').then((m) => m.TaoModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.TAO) },
  },
  {
    path: RessourceService.MODULES.USER,
    loadChildren: () => import('@user/user.module').then((m) => m.UserModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.USER) },
  },
  {
    path: RessourceService.MODULES.KUNGFU,
    loadChildren: () => import('@kungfu/kungfu.module').then((m) => m.KungfuModule),
    canLoad: [RouteGuardService],
    data: { roles: RessourceService.getRolesOfModule(RessourceService.MODULES.KUNGFU) },
  },

  { path: PageNotFoundComponent.URI, component: PageNotFoundComponent },
  {
    path: '',
    redirectTo: '/' + RessourceService.MENU.URL_LOGIN.module + '/' + RessourceService.MENU.URL_LOGIN.uri,
    pathMatch: 'full',
  },
  { path: '**', redirectTo: '/' + PageNotFoundComponent.URI },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, enableTracing: environment.debug })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
