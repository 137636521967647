<div *ngIf="dropDownStream$ | async as dropdowns">
  <mat-form-field [formGroup]="parentForm" class="mb-3">
    <input
      matInput
      [matAutocomplete]="auto"
      [id]="field"
      [formControlName]="field"
      class="form-control"
      [placeholder]="label"
      [required]="required"
      (keyup)="applyFilter($event, dropdowns)"
    />

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let value of suggestions"
        (onSelectionChange)="onSelectionChange($event, value.id, dropdowns)"
        [value]="value.value"
      >
        <span>{{ value.value }}</span>
      </mat-option>
    </mat-autocomplete>
    <button *ngIf="!readonly" class="btn btn-outline-danger resetbtn" matSuffix (click)="onClearChange($event)">
      <i class="fas fa-times"></i>
    </button>
  </mat-form-field>
</div>
