import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICrudService } from '@shared/api/crud/i-crud.service';
import { IAssociationModelLike } from '@association/models/association.model';
import { AbstractRtlqService } from '@shared/api/crud/abstract-main.service';
import { AssociationBuilder } from '@association/builder/association.builder';
import { AssociationConfig } from '@association/ressource/association.config';

export interface IAssociationServiceLike extends ICrudService<IAssociationModelLike> {}

@Injectable({
  providedIn: 'root',
})
export class AssociationService
  extends AbstractRtlqService<IAssociationModelLike>
  implements IAssociationServiceLike {
  constructor(protected http: HttpClient, protected builder: AssociationBuilder) {
    super(http, builder, AssociationConfig.REST_URL);
  }
}
