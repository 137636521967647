<!-- Footer -->
<footer class="page-footer font-small special-color-dark pt-4">
  <!-- Footer Links -->
  <div class="container-fluid text-center text-md-left">
    <!-- Grid row -->
    <div class="row">
      <!-- Grid column -->
      <div class="col-md-6 mt-md-0 mt-3"></div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3" />

      <!-- Grid column -->
      <div class="col-md-3 mb-md-0 mb-3"></div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-3 mb-md-0 mb-3">
        <!-- Links -->
        <h5 class="text-uppercase">
          Version
          <span class="badge bg-info text-dark">{{ version }}</span>
        </h5>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">
    © 2021 Copyright
    <span class="badge bg-light text-dark"><a href="{{ intranet }}" class="" >Intranet</a></span>
    <span class="badge bg-light text-dark"><a href="{{ extranet }}">Extranet</a></span>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
