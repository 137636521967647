import { Injectable } from '@angular/core';
import { DriveModel, IDriveModelLike } from '@drive/models/drive.model';
import { AbstractCrudBuilder, IBuilderLike } from '@shared/builder/abstract-rtlq.build';
import { FileUploadModel } from '@shared/components/crud/uploader/uploader.component';

export interface IDriveBuilderLike extends IBuilderLike<IDriveModelLike> {
  createEntityWithFormData(param: string, file: FileUploadModel);
}
@Injectable({
  providedIn: 'root',
})
export class DriveBuilder extends AbstractCrudBuilder<IDriveModelLike> implements IDriveBuilderLike {
  getEntity() {
    return DriveModel;
  }

  protected customConvertResponseToModele(json: {}, entity: IDriveModelLike): IDriveModelLike {
    entity.showActions = !entity.converting;

    return entity;
  }

  public createEntityWithFormData(param: string, file: FileUploadModel) {
    const entity = this.getNewInstance();

    const fd = new FormData();
    fd.append(param, file.data);
    fd.append('filename', file.custom_name);

    entity.formData = fd;
    entity.formDataName = param;
    return entity;
  }
}
