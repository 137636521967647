import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { IKungFuNiveauModelLike } from '@kungfu/models/kungfu-niveau.model';

import { KungFuNiveauConfig } from '@kungfu/resource/kungfu-niveau.config';

@Injectable({ providedIn: 'root' })
export class KungFuNiveauEntityService extends EntityCollectionServiceBase<IKungFuNiveauModelLike> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(KungFuNiveauConfig.STORE, serviceElementsFactory);
  }
}
