<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable modal-full" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="Title">{{ title }}</h5>
      <button type="button" class="btn-close" (click)="close()" aria-label="Fermer">
      </button>
    </div>

    <div class="modal-body">
      <app-generic-field [parentForm]="parentForm" [col]="col" style="width: 100%"> </app-generic-field>
    </div>

    <div class="modal-footer">
      <button type="button" (click)="close()" class="btn btn-secondary">
        <i class="far fa-times-circle">Fermer</i>
      </button>
    </div>
  </div>
</div>
