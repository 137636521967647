import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { ISaisonModelLike } from '@saison/models/saison.model';

import { SaisonConfig } from '@saison/resource/saison.config';
import { Observable } from 'rxjs';
import { SaisonService } from './saison.service';

@Injectable({ providedIn: 'root' })
export class SaisonEntityService extends EntityCollectionServiceBase<ISaisonModelLike> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, protected saisonService: SaisonService) {
    super(SaisonConfig.STORE, serviceElementsFactory);
  }

  doDuplicateAndCreate(id: number): Observable<ISaisonModelLike> {
    const observable$ = this.saisonService.doDuplicateAndCreate(id);
    observable$.subscribe(
      (data) => {
        this.addOneToCache(data);
      },
      (error) => {}
    );
    return observable$;
  }
  
}
