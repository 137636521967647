import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {HttpUrlGenerator } from '@ngrx/data';

import { RtlqDataService } from '@shared/api/ngrx/rtlq.data-service';

import { ICotisationModelLike } from '@cotisation/models/cotisation.model';
import { CotisationConfig } from '@cotisation/resource/cotisation.config';
import { CotisationService } from './cotisation.service';

@Injectable({ providedIn: 'root' })
export class CotisationDataService extends RtlqDataService<ICotisationModelLike> {

  constructor(
    protected apiService: CotisationService,
    protected http: HttpClient,
    protected httpUrlGenerator: HttpUrlGenerator
  ) {
    super(CotisationConfig.STORE, apiService, http, httpUrlGenerator);
  }
}
