import { RessourceService } from '@shared/api/config/ressource.service';
import { IRtlqModelLike } from '@shared/modeles/crud/i-crud.model';

export interface ISessionUserModelLike {
  username: string;
  id: number;
  prenom: string;
  nom: string;
  roles: string[];
  authenticate: boolean;
  token: string;
  has(): boolean;
  isAuthenticate(): boolean;
  setAuthenticate(): void;
  setNotAuthenticate(): void;
  equals(user: SessionUserModel): boolean;
  isAdmin(): boolean;
  getPrenomNom(): string;
}
export class SessionUserModel implements ISessionUserModelLike {
  public token: string = null;
  public username: string = null;
  public id: number = null;
  public prenom: string = null;
  public nom: string = null;
  public roles: string[] = [];
  public authenticate = false;

  public has(): boolean {
    return this.token != null && this.username != null;
  }

  public getPrenomNom(): string {
    if (this.nom && this.prenom) {
      return this.prenom + ' ' + this.nom;
    } else if (!this.nom && this.prenom) {
      return this.prenom;
    } else {
      return this.nom;
    }
  }

  public remove() {
    this.token = null;
    this.username = null;
    this.prenom = null;
    this.roles = [];
    this.authenticate = false;
  }

  public isAuthenticate() {
    return this.authenticate;
  }
  public setAuthenticate() {
    this.authenticate = true;
  }
  public setNotAuthenticate() {
    this.authenticate = false;
  }

  public equals(user: SessionUserModel): boolean {
    return this.token === user.token && JSON.stringify(this.roles) === JSON.stringify(user.roles);
  }

  public isAdmin(): boolean {
    return this.roles.find((a) => a === RessourceService.ROLES.ADMIN) ? true : false;
  }
}
