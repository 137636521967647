import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { RessourceService } from '@shared/api/config/ressource.service';
import { AbstractRootWithBuilderService } from '@shared/api/crud/abstract-main.service';
import { ICrudService } from '@shared/api/crud/i-crud.service';

import { AppState } from '@app_store/app.reducer';
import { IStatsModelLike, StatsModel } from '@dashboard/models/stats.model';

import { IUserModelLike } from '@user/models/user.model';
import { UserBuilder } from '@user/builder/user.builder';
import { UserConfig } from '@user/resource/user.config';
import { CurrentUserActions } from '@user/store/current-user/current-user.types';
import { StatsBuilder } from '@dashboard/builder/stats.builder';

export interface ICurrentUserServiceLike extends ICrudService<IUserModelLike> {
  get(): Observable<IUserModelLike>;
  update(entity: IUserModelLike): Observable<IUserModelLike>;
  getStats(): Observable<IStatsModelLike>;
}

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService
  extends AbstractRootWithBuilderService<IUserModelLike>
  implements ICurrentUserServiceLike {
  constructor(
    protected store: Store<AppState>,
    protected http: HttpClient,
    protected builder: UserBuilder,
    protected statsBuilder: StatsBuilder,
  ) {
    super(http, builder, UserConfig.REST_URL);
  }

  getAll(): Observable<IUserModelLike[]> {
    throw new Error('Method not implemented.');
  }
  delete(id: number): Observable<number> {
    throw new Error('Method not implemented.');
  }
  add(entity: IUserModelLike): Observable<IUserModelLike> {
    throw new Error('Method not implemented.');
  }
  getWithQuery(param: any): Observable<IUserModelLike[]> {
    throw new Error('Method not implemented.');
  }
  get(): Observable<IUserModelLike> {
    const entity = this.http
      .get(`${this.getFullUrl()}/${RessourceService.CONFIG.URI_USER_BY_TOKEN}`)
      .pipe(map((response) => this.mapEntity(response)));

    return entity;
  }

  update(entity: IUserModelLike): Observable<IUserModelLike> {
    return this.http
      .put(`${this.getFullUrl()}/${RessourceService.CONFIG.URI_USER_BY_TOKEN}`, JSON.stringify(entity))
      .pipe(
        map((response) => {
          const user: IUserModelLike = this.mapEntity(response);
          this.store.dispatch(CurrentUserActions.fetchedCurrentUserAction({ user: user }));
          return user;
        })
      );
  }

  getStats(): Observable<IStatsModelLike> {
    const entity = this.http
      .get(
        `${this.getFullUrl()}/${RessourceService.CONFIG.URI_USER_BY_TOKEN}/${RessourceService.CONFIG.URI_USER_MY_STATS}`
      )
      .pipe(map((response) => this.statsBuilder.convertJsonToModele(response)));

    return entity;
  }
}
