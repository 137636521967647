import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';
import { DateService } from '@shared/api/date.service';
import { IDropdownModelLike } from '@shared/modeles/dropdown/dropdown.model';
import { IActifLike } from '@shared/modeles/crud/i-actif.like';
import { AbstractCrudActifWithDateModelLike } from '@shared/modeles/crud/abstract-crud-actif-with-date.model';

export interface ISaisonModelLike extends IDropdownModelLike, ICrudModelLike, IActifLike {
  date_debut: moment.Moment;
  date_fin: moment.Moment;
  nb_adherents: number;
}

export class SaisonModel extends AbstractCrudActifWithDateModelLike implements ISaisonModelLike {

  constructor(
    public id = null,
    public nom = null,
    public date_debut = null,
    public date_fin = null,
    public nb_adherents = 0
  ) {
    super(id);
  }

  consolidation() {
    super.consolidation();

    this.date_debut = DateService.string2Moment(this.date_debut);
    this.date_fin = DateService.string2Moment(this.date_fin);
  }
}
