import { RessourceService } from '@shared/api/config/ressource.service';
import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';
import { AbstractCrudModel } from '@shared/modeles/crud/absract-crud.model';

import { PhotoConfig } from '@photo/resource/photo.config';

export interface IPhotoModelLike extends ICrudModelLike {
  source?: string;
  title?: string;
  description?: string;
  repertoire_id?: number;
  repertoire_name?: string;
  preview?: string;
}

export class PhotoModel extends AbstractCrudModel implements IPhotoModelLike {
  public repertoire = {};

  constructor(
    public id = null,
    public source = null,
    public title = null,
    public description = null,
    public repertoire_id = null,
    public repertoire_name = null,
    public preview = null
  ) {
    super(id);

    this.setUrlPreviewPhoto();
  }

  setUrlPreviewPhoto() {
    if (this.id != null) {
      this.preview = RessourceService.CONFIG.URL_BACK_OFFICE + '/' + PhotoConfig.REST_URL + '/' + this.id;
    } else {
      this.preview = null;
    }
  }

  consolidation() {
    super.consolidation();

    this.initDropDown('repertoire');
    this.setUrlPreviewPhoto();
  }
}
