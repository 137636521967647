import { IStatsModelLike } from '@dashboard/models/stats.model';
import { createReducer, on, State, Action } from '@ngrx/store';
import { ITresorerieModelLike } from '@tresorerie/models/tresorerie.model';
import { IUserModelLike } from '@user/models/user.model';
import { CurrentUserActions } from './current-user.types';

export interface CurrentUserState {
  user: IUserModelLike;
  userToUpdate: IUserModelLike;
  userLoaded: boolean;
  userLoading: boolean;
  stats: IStatsModelLike;
  statsLoaded: boolean;
  tresoreries: ITresorerieModelLike[];
  tresoreriesLoaded: boolean;
}
export const currentUserFeatureKey = 'currentUser';
export const initialCurrentUserState = {
  user: null,
  userToUpdate: null,
  userLoading: false,
  userLoaded: false,
  stats: null,
  statsLoaded: false,
  tresoreries: [],
  tresoreriesLoaded: false,
};

export const currentUserReducer = createReducer(
  initialCurrentUserState,
  on(CurrentUserActions.fetchingCurrentUserAction, (state, action) => {
    return { ...state, user: [], userLoaded: false, userLoading: true, };
  }),
  on(CurrentUserActions.fetchedCurrentUserAction, (state, action) => {
    return { ...state, user: action.user, userLoaded: true, userLoading: false, userToUpdate: null };
  }),
  on(CurrentUserActions.fetchingStatsAction, (state, action) => {
    return { ...state, stats: null, statsLoaded: false };
  }),
  on(CurrentUserActions.fetchedStatsAction, (state, action) => {
    return { ...state, stats: action.stats, statsLoaded: true };
  }),
  on(CurrentUserActions.savingCurrentUserAction, (state, action) => {
    return { ...state, userToUpdate: action.userToUpdate, userLoaded: false };
  })
);

export function reducer(state: CurrentUserState | undefined, action: Action) {
  return currentUserReducer(state, action);
}
