import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCurrentUser from './current-user.reducer';

export const selectCurrentUserState = createFeatureSelector<fromCurrentUser.CurrentUserState>(
  fromCurrentUser.currentUserFeatureKey
);

export const currentUserIsLoadedOrLoading = createSelector(selectCurrentUserState, (state) => {
  return state.userLoaded || state.userLoading;
});
export const currentUserIsLoaded = createSelector(selectCurrentUserState, (state) => {
  return state.userLoaded && !state.userLoading;
});
export const currentUserIsNotLoaded = createSelector(selectCurrentUserState, (state) => !state.userLoaded);
