import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { AuthenticateRoutingModule } from './authenticate.route';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { LoginComponent } from './components/login/login.component';

@NgModule({
  imports: [AuthenticateRoutingModule, SharedModule],
  declarations: [ChangePasswordComponent, ResetPasswordComponent, LoginComponent],
  exports: [ChangePasswordComponent, ResetPasswordComponent, LoginComponent],
})
export class AuthenticateModule {}
