import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';
import { IDropdownModelLike } from '@shared/modeles/dropdown/dropdown.model';
import { IActifLike } from '@shared/modeles/crud/i-actif.like';
import { AbstractCrudActifWithDropDownModelLike } from '@shared/modeles/crud/abstract-crud-actif-with-date.model';
import { ISaisonModelLike } from '@saison/models/saison.model';

export interface ICotisationModelLike extends IDropdownModelLike, ICrudModelLike, IActifLike {
  description?: string;
  cotisation?: string;
  repartition_cheque?: string;
  nb_cheque?: number;
  saison_name?: string;
  saison_id?: number;
  categorie_name?: string;
  categorie_id?: number;
  setSaisonWithSaisonCourante(saison_active: ISaisonModelLike);
}

export class CotisationModel extends AbstractCrudActifWithDropDownModelLike implements ICotisationModelLike {

  constructor(
    public id = null,
    public nom = null,
    public description = null,
    public cotisation = null,
    public repartition_cheque = null,
    public nb_cheque = null,
    public saison_name = null,
    public saison_id = null,
    public categorie_name = null,
    public categorie_id = null
  ) {
    super(id);
  }

  consolidation() {
    super.consolidation();

    this.initDropDown('saison');
    this.initDropDown('categorie');
  
  }

  setSaisonWithSaisonCourante(saison_active: ISaisonModelLike) {
    if (saison_active) {
      this.saison_id = saison_active.id;
      this.saison_name = saison_active.nom;
    } else {
      this.saison_id = null;
      this.saison_name = null;
    }
  }
}
