<nav class="navbar navbar-expand-md navbar-dark bg-dark">
  <a class="navbar-brand" [routerLink]="routerHome" routerLinkActive="active">
    <div class="logoEcole"></div>
  </a>

  <button
    *ngIf="enableMenuBar"
    class="navbar-toggler collapsed"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
    #navbarToggler
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div *ngIf="enableMenuBar" class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item dropdown" *ngFor="let item of items">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ item.label }}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a
            *ngFor="let subitem of item.items"
            class="dropdown-item"
            [routerLink]="subitem.routerLink"
            routerLinkActive="active"
            routerLinkActiveOption="{exact: true}"
            (click)="collapseNav()"
          >
            {{ subitem.label }}</a
          >
        </div>
      </li>
    </ul>

    <hr class="d-md-none text-white-50" />

    <div *ngIf="userStream$ | async as user" class="flex-wrap ms-md-auto">
      <a [routerLink]="routeUserProfile">
        <div class="avatar text-center">
          <img class="avatar-45" src="{{ user.avatar_url }}" />
        </div>
      </a>
    </div>

    <div class="logout">
      <button (click)="logout()" class="btn btn-danger btn-logout">
        <span><i class="fas fa-sign-out-alt"></i> Logout</span>
      </button>
    </div>
  </div>
</nav>
