import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';

// import { CounterModule } from 'angular-circle-counter';
import { CircleProgressComponent } from '@shared/components/circle-progress/circle-progress.component';

import { RouterModule } from '@angular/router';
import { NgbModal, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { ChartModule } from 'primeng/chart';

import { AgePipe } from './pipes/age.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

import { DialogInformationComponent } from './components/crud/dialog-information/dialog-information.component';
import { GenericFieldComponent } from './components/crud/generic-field/generic-field.component';
import { UploaderComponent } from './components/crud/uploader/uploader.component';
import { ViewerComponent } from './components/crud/viewer/viewer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PhotoFieldComponent } from './components/crud/photo-field/photo-field.component';
import { ButtonComponent } from './components/button/button.component';
import { EditModalEditorComponent } from './components/crud/modal/edit-modal-editor.component';
import { BulkEditModalEditorComponent } from './components/crud/modal/bulk-edit-modal-editor.component';
import { WithLoadingPipe } from './pipes/with-loading.pipe';
import { GenericFieldErrorComponent } from './components/crud/generic-field-error/generic-field-error.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { SuggestionComponent } from './components/suggestion/suggestion.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModalModule,
    // BrowserModule,
    // BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgbModule,

    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatListModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatCardModule,
    MatBadgeModule,
    MatButtonModule,

    // PRIMENG //
    ChartModule,

    // IMAGE/VIDEO //
    ImageCropperModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,

    // COUNTER //
    // CounterModule,
  ],
  declarations: [
    AgePipe,
    WithLoadingPipe,
    SafeHtmlPipe,
    LoadingComponent,
    DialogInformationComponent,
    EditModalEditorComponent,
    BulkEditModalEditorComponent,
    ViewerComponent,
    UploaderComponent,
    GenericFieldComponent,
    GenericFieldErrorComponent,
    PhotoFieldComponent,
    LoadingComponent,
    ButtonComponent,
    CircleProgressComponent,
    DropdownComponent,
    SuggestionComponent
  ],
  exports: [
    CommonModule,
    NgbModule,
    NgbModalModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    AgePipe,
    WithLoadingPipe,
    SafeHtmlPipe,
    LoadingComponent,
    EditModalEditorComponent,
    BulkEditModalEditorComponent,
    ViewerComponent,
    UploaderComponent,
    GenericFieldComponent,
    GenericFieldErrorComponent,
    PhotoFieldComponent,
    LoadingComponent,
    ButtonComponent,
    CircleProgressComponent,
    DropdownComponent,
    SuggestionComponent,

    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatListModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatCardModule,
    MatBadgeModule,
    MatButtonModule,

    // PRIMENG //
    ChartModule,

    // CounterModule,
  ],
  entryComponents: [
    EditModalEditorComponent,
    BulkEditModalEditorComponent,
    UploaderComponent,
    ViewerComponent,
    // DialogInformationComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'DD/MM/YYYY',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    NgbModal,
    MatDatepickerModule,
  ],
})
export class SharedModule {}
