import { Injectable } from '@angular/core';

import { RessourceService } from '@shared/api/config/ressource.service';
import { ICrudConfigModel} from '@shared/modeles/crud/crud-config.model';
import { IRouteItemModelLike } from '@shared/modeles/menu/route-item.model';
import { AbstractRtlqConfig } from '@shared/resource/rtlq.config';
import { app_config } from 'app/app.config';


@Injectable({
  providedIn: 'root',
})
export class AssociationConfig extends AbstractRtlqConfig {
  static MENU: IRouteItemModelLike = RessourceService.MENUS.URL_ASSOCIATION;
  static REST_URL = 'associations';
  static MODULE = AssociationConfig.MENU.module;
  static DOMAINE = AssociationConfig.MENU.uri;
  static STORE = app_config.STORE.AssociationConfig;

  get module(): string {
    return AssociationConfig.MODULE;
  }

  get domaine(): string {
    return AssociationConfig.DOMAINE;
  }

  get id(): string {
    return AssociationConfig.MENU.id;
  }
  getCols(): Array<ICrudConfigModel> {
    const config: Array<ICrudConfigModel> = [];

    config.push(this.createCrudConfigModel('short_name').isFormRequired());
    config.push(this.createCrudConfigModel('long_name').isFormRequired().withOnlyForm());
    config.push(this.createCrudConfigModel('date_creation_text').withOnlyTable());
    config.push(
      this.createCrudConfigModel('date_creation')
        .isFormRequired()
        .withOnlyForm()
        .withType(RessourceService.CONST.TYPE_DATE)
    );
    config.push(this.createCrudConfigModel('siege_social_address').withOnlyForm().isFormRequired());
    config.push(this.createCrudConfigModel('siege_social_postal_code').withOnlyForm().isFormRequired());
    config.push(this.createCrudConfigModel('siege_social_city').withOnlyForm().isFormRequired());

    config.push(this.createCrudConfigModel('numero_siren').isFormRequired());
    config.push(this.createCrudConfigModel('numero_compte_bancaire').isFormRequired());
    config.push(this.createCrudConfigModel('url_intranet').withOnlyForm());
    config.push(this.createCrudConfigModel('url_extranet').withOnlyForm());
    config.push(this.createCrudConfigModel('president_nom_prenom').withFormReadonly());
    config.push(this.createCrudConfigModel('tresorier_nom_prenom').withFormReadonly());
    config.push(this.createCrudConfigModel('secretaire_nom_prenom').withFormReadonly());
    config.push(this.createCrudConfigModel('message').withType(RessourceService.CONST.TYPE_TEXTAREA));
    config.push(this.createCrudConfigModel('closed').withType(RessourceService.CONST.TYPE_BOOLEAN));
    config.push(this.createCrudConfigModel('actif').withType(RessourceService.CONST.TYPE_BOOLEAN));

    config.push(this.createCrudConfigModel('logo_url').isFormRequired().withOnlyForm());
    config.push(this.createCrudConfigModel('cours_short_description').isFormRequired().withOnlyForm());
    config.push(this.createCrudConfigModel('contact_phone_number').isFormRequired().withOnlyForm());
    config.push(this.createCrudConfigModel('contact_email').isFormRequired().withOnlyForm());
    config.push(this.createCrudConfigModel('contact_name').isFormRequired().withOnlyForm());
    config.push(this.createCrudConfigModel('url_agenda').isFormRequired().withOnlyForm());

    return config;
  }
}
