<section class="container" id="login">
  <div class="authenticateWindow">
    <span> Bienvenue</span>

    <div class="logoEcole"></div>
    <form (ngSubmit)="f.valid && login(f)" #f="ngForm">
      <!------------------------>
      <div span class="mb-3" [ngClass]="{ 'has-error': username.touched && !username.valid }">
        <label for="username">Username</label>
        <input type="text" class="form-control" name="username" ngModel #username="ngModel" required />
        <div *ngIf="username.touched && !username.valid" class="help-block">Username is required</div>
      </div>
      <!------------------------>
      <div class="mb-3" [ngClass]="{ 'has-error': password.touched && !password.valid }">
        <label for="password">Password</label>
        <input type="password" class="form-control" name="password" ngModel #password="ngModel" required />
        <div *ngIf="password.touched && !password.valid" class="help-block">Password is required</div>
      </div>
      <!------------------------>
      <div class="mb-3 text-center">
        <app-button
          type="submit"
          [(disabled)]="!f.valid"
          [action_status]="isLoading"
          action_name="Se connecter"
          action_name_inprogress="Connection"
          action_icone="fa-sign-in-alt"
        ></app-button>
      </div>
      <!------------------------>
      <!------------------------>
      <div class="mb-3 text-right">
        <a [routerLink]="getMotDePasseRoute()">Mot de passe oublié ?</a>
      </div>
    </form>
    <div *ngIf="errorMessage" class="alert alert-danger text-center">{{ errorMessage }}</div>

    <p class="version">
      <span class="badge bg-info text-dark">{{ version }}</span>
    </p>
  </div>
</section>
