import { Injectable } from '@angular/core';
import { PageConfigModel } from '@shared/modeles/config/page-config.model';
import { RouteItemModel } from '@shared/modeles/menu/route-item.model';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RessourceService {
  static CONFIG = {
    URL_BACK_OFFICE: environment.URL_BACK_OFFICE,
    URI_USER_LISTE: 'liste',
    URI_USER_PASSWORD_RESET: 'password-reset',
    URI_USER_BY_TOKEN: 'by-token',
    URI_USER_BY_ID: 'by-iduser-',
    URI_USER_BY_REFERENT: 'by-referent',
    URI_USER_PASSWORD_CHANGE: 'password-change',
    URI_USER_FINALIZE_INSCRIPTION: 'finalise-inscription',
    URI_USER_SEND_EMAIL: 'send-email',
    URI_USER_SEND_EMAIL_WELCOME: 'welcome',
    URI_USER_TAO: 'taos',
    URI_USER_MY_STATS: 'myStats',

    URL_DASHBOARD: 'association/dashboard',
    URI_DASHBOARD_TRESO_BY_CATE: 'tresorerie-by-categorie',
    URI_DASHBOARD_TRESO_BY_SAISON: 'tresorerie-by-saison',

    URL_AUTHENTICATION_LOGIN: 'security/tokens',
    URL_AUTHENTICATION_LOGOUT: 'security/tokens/logout',
    URL_AUTHENTICATION_CHECKUSER: 'security/tokens/check-user',
    URI_MATERIEL_VENTE: 'vente',
    URI_MATERIEL_ACHAT: 'achat',
  };

  static CONST = {
    TYPE_NUMBER: 'number',
    TYPE_STRING: 'string',
    TYPE_BOOLEAN: 'boolean',
    TYPE_DATE: 'date',
    TYPE_TEXTAREA: 'textArea',
    TYPE_DROPDOWN: 'dropdown',
    TYPE_LISTSTRING: 'listString',
    TYPE_PHOTO: 'photo',
    TYPE_URL_IMAGE: 'urlImage',
    TYPE_LIST_SUGGESTION: 'autoComplete',
    TYPE_DROPDOWN_MULTIPLE: 'multiSelect',
    TYPE_VIDEO: 'video',
    TYPE_TOOLTIP: 'tooltip',
    X_AUTH_TOKEN: 'x-auth-token',
  };

  static ROLES = {
    ADMIN: 'ROLE_ADMIN',
    USER: 'ROLE_USER',
    COMMUNICATION: 'ROLE_COM',
    EVENT: 'ROLE_EVT',
    BENEVOLAT: 'ROLE_BEN',
    COMPTABILITE: 'ROLE_TRE',
    PROFESSEUR: 'ROLE_PRO',
    KPI: 'ROLE_KPI',
    ANONYMOUS: 'ANONYMOUS',
  };

  static CATEGORIES = {
    ADMINISTRATION: 'Administration',
    ASSOCIATION: 'Association',
    KUNGFU: 'Kung-Fu',
    USER: 'Mon Espace',
    CONFIGURATION: 'Configuration',
  };

  static MODULES = {
    ASSOCIATION: 'associations',
    BENEVOLAT: 'benevolats',
    BUREAU: 'bureaux',
    COTISATION: 'cotisations',
    COURS: 'cours',
    DRIVE: 'drives',
    EVENT: 'events',
    GROUPE: 'groupes',
    HOME: 'home',
    AUTHENTICATION: 'auth',
    MATERIEL: 'materiels',
    NEWS: 'news',
    PHOTO: 'photos',
    SAISON: 'saisons',
    TAO: 'taos',
    TRESORERIE: 'tresorerie',
    USER: 'user',
    KUNGFU: 'kungfu',
  };

  // tslint:disable-next-line:member-ordering
  static MENUS = {
    URL_ASSOCIATION: RouteItemModel.create(
      RessourceService.MODULES.ASSOCIATION,
      RessourceService.CATEGORIES.CONFIGURATION,
      [RessourceService.ROLES.ADMIN]
    ),

    URL_BENEVOLAT: RouteItemModel.create(RessourceService.MODULES.BENEVOLAT, RessourceService.CATEGORIES.ASSOCIATION, [
      RessourceService.ROLES.BENEVOLAT,
    ]),

    URL_TRESORERIE_ETAT: RouteItemModel.create(
      RessourceService.MODULES.TRESORERIE,
      RessourceService.CATEGORIES.CONFIGURATION,
      [RessourceService.ROLES.ADMIN],
      'etats'
    ),
    URL_TRESORERIE_CATEGORIE: RouteItemModel.create(
      RessourceService.MODULES.TRESORERIE,
      RessourceService.CATEGORIES.CONFIGURATION,
      [RessourceService.ROLES.ADMIN],
      'categories'
    ),
    URL_TRESORERIE: RouteItemModel.create(
      RessourceService.MODULES.TRESORERIE,
      RessourceService.CATEGORIES.ADMINISTRATION,
      [RessourceService.ROLES.COMPTABILITE]
    ),
    URL_COTISATION: RouteItemModel.create(
      RessourceService.MODULES.COTISATION,
      RessourceService.CATEGORIES.CONFIGURATION,
      [RessourceService.ROLES.ADMIN]
    ),
    URL_COURS: RouteItemModel.create(RessourceService.MODULES.COURS, RessourceService.CATEGORIES.KUNGFU, [
      RessourceService.ROLES.ADMIN,
    ]),
    URL_EVENT: RouteItemModel.create(RessourceService.MODULES.EVENT, RessourceService.CATEGORIES.ASSOCIATION, [
      RessourceService.ROLES.EVENT,
    ]),
    URL_DRIVE: RouteItemModel.create(
      RessourceService.MODULES.DRIVE,
      RessourceService.CATEGORIES.USER,
      [RessourceService.ROLES.USER],
      'myspace_drive'
    ),
    URL_BUREAU: RouteItemModel.create(RessourceService.MODULES.BUREAU, RessourceService.CATEGORIES.ASSOCIATION, [
      RessourceService.ROLES.ADMIN,
    ]),

    URL_GROUPE: RouteItemModel.create(RessourceService.MODULES.GROUPE, RessourceService.CATEGORIES.CONFIGURATION, [
      RessourceService.ROLES.ADMIN,
    ]),

    URL_NEWS: RouteItemModel.create(RessourceService.MODULES.NEWS, RessourceService.CATEGORIES.ASSOCIATION, [
      RessourceService.ROLES.COMMUNICATION,
    ]),

    URL_MATERIEL: RouteItemModel.create(RessourceService.MODULES.MATERIEL, RessourceService.CATEGORIES.ASSOCIATION, [
      RessourceService.ROLES.COMPTABILITE,
    ]),
    URL_PHOTO_DIRECTORY: RouteItemModel.create(
      RessourceService.MODULES.PHOTO,
      RessourceService.CATEGORIES.ASSOCIATION,
      [RessourceService.ROLES.COMMUNICATION],
      'galleries'
    ),
    URL_PHOTO: RouteItemModel.create(
      RessourceService.MODULES.PHOTO,
      RessourceService.CATEGORIES.ASSOCIATION,
      [RessourceService.ROLES.COMMUNICATION],
      'photos'
    ),
    URL_SAISON: RouteItemModel.create(RessourceService.MODULES.SAISON, RessourceService.CATEGORIES.CONFIGURATION, [
      RessourceService.ROLES.ADMIN,
    ]),

    URL_CATEGORIE_VOTEE: RouteItemModel.create(
      RessourceService.MODULES.SAISON,
      RessourceService.CATEGORIES.CONFIGURATION,
      [RessourceService.ROLES.ADMIN],
      'categorie-votee'
    ),
    URL_USERS: RouteItemModel.create(RessourceService.MODULES.USER, RessourceService.CATEGORIES.ADMINISTRATION, [
      RessourceService.ROLES.COMPTABILITE,
    ]),
    URL_TROMBINOSCOPE: RouteItemModel.create(
      RessourceService.MODULES.USER,
      RessourceService.CATEGORIES.USER,
      [RessourceService.ROLES.USER],
      'trombinoscope'
    ),

    URL_KUNGFU_STYLE: RouteItemModel.create(
      RessourceService.MODULES.KUNGFU,
      RessourceService.CATEGORIES.KUNGFU,
      [RessourceService.ROLES.ADMIN],
      'styles'
    ),
    URL_KUNGFU_NIVEAU: RouteItemModel.create(
      RessourceService.MODULES.KUNGFU,
      RessourceService.CATEGORIES.KUNGFU,
      [RessourceService.ROLES.ADMIN],
      'niveaux'
    ),
    URL_KUNGFU_TAO: RouteItemModel.create(RessourceService.MODULES.TAO, RessourceService.CATEGORIES.CONFIGURATION, [
      RessourceService.ROLES.ADMIN,
    ]),

    URL_USER_PROFIL: RouteItemModel.create(
      RessourceService.MODULES.USER,
      RessourceService.CATEGORIES.USER,
      [RessourceService.ROLES.USER],
      'me'
    ),
    URL_USER_TRESORERIES: RouteItemModel.create(
      RessourceService.MODULES.TRESORERIE,
      RessourceService.CATEGORIES.USER,
      [RessourceService.ROLES.USER],
      'me'
    ),

    URL_USER_TAOS_USER: RouteItemModel.create(
      RessourceService.MODULES.TAO,
      RessourceService.CATEGORIES.USER,
      [RessourceService.ROLES.USER],
      'me'
    ),

    URL_KUNGFU_TAOS_USERS: RouteItemModel.create(
      RessourceService.MODULES.TAO,
      RessourceService.CATEGORIES.KUNGFU,
      [RessourceService.ROLES.ADMIN],
      'students'
    ),

    URL_TAOS_HISTO: RouteItemModel.create(
      RessourceService.MODULES.TAO,
      RessourceService.CATEGORIES.KUNGFU,
      [RessourceService.ROLES.USER],
      'histo'
    ),
  };

  static MENU = {
    URL_HOME: RouteItemModel.create(RessourceService.MODULES.HOME, null, [RessourceService.ROLES.USER], ''),
    URL_RESET_PASSWORD: RouteItemModel.create(RessourceService.MODULES.AUTHENTICATION, null, [], 'reset-password'),
    URL_CHANGE_PASSWORD: RouteItemModel.create(RessourceService.MODULES.AUTHENTICATION, null, [], 'change-password'),
    URL_LOGIN: RouteItemModel.create(RessourceService.MODULES.AUTHENTICATION, null, [], 'login'),
  };

  static arrayOfMenus = Object.keys(RessourceService.MENU).map((key) => RessourceService.MENU[key]);
  static getRolesOfModule(name): string[] {
    let roles = [];

    const menus = RessourceService.arrayOfMenus.filter((item) => item.module === name && item.roles);
    for (const menu of menus) {
      roles = roles.concat(menu.roles.filter((item) => roles.indexOf(item) < 0));
    }

    //search inside the news way
    // const routes = AppState.getRoutes().filter((item) => item.module === name && item.roles);
    // for (const menu of routes) {
    //   roles = roles.concat(menu.roles.filter((item) => roles.indexOf(item) < 0));
    // }
    return roles;
  }
}
