import { IRtlqActionLike } from '@association/_store/rtlq.actions';
import { SessionUserBuilder } from '@authenticate/builder/session-user.builder';
import { ISessionUserModelLike } from '@authenticate/models/session-user.model';
import { AuthState } from './auth.reducer';

export const FEATURE = '[Auth] ';

export const AUTO_LOGIN = FEATURE + 'AUTO LOGIN';
export const AUTO_LOGIN_CHECK = FEATURE + 'AUTO LOGIN CHECK';
export const AUTO_LOGIN_SUCCESSED = FEATURE + 'AUTO LOGIN SUCCESSED';
export const AUTO_LOGIN_FAILED = FEATURE + 'AUTO LOGIN FAILED';

export const LOGIN_START = FEATURE + 'LOGIN START';
export const LOGIN_SUCCESSED = FEATURE + 'LOGIN SUCCESS';
export const LOGIN_FAILED = FEATURE + 'LOGIN FAILED';

export const LOGOUT = FEATURE + 'LOGOUT';

export interface Credentiel {
  username: string;
  password: string;
}

export class AutoLoginAction implements IRtlqActionLike<void> {
  readonly type = AUTO_LOGIN;
  doAction(state: AuthState) {
    return { ...state, auth: null, loading: true, authError: null};
  }
}

export class AutoLoginCheckAction implements IRtlqActionLike<ISessionUserModelLike> {
  static builder = new SessionUserBuilder();
  readonly type = AUTO_LOGIN_CHECK;
  constructor(public payload: ISessionUserModelLike) {}
  doAction(state) {
    const user = { ...this.payload };
    user.authenticate = false;

    return { ...state, auth: user, redirect: false };
  }
}

export class AutoLoginFailedAction implements IRtlqActionLike<void> {
  readonly type = AUTO_LOGIN_FAILED;
  doAction(state: any) {
    return { ...state, auth: null, loading: false, authError: null, redirect: true };
  }
}

export class AutoLoginSuccessedAction implements IRtlqActionLike<ISessionUserModelLike> {
  readonly type = AUTO_LOGIN_SUCCESSED;
  constructor(public payload: ISessionUserModelLike) {}
  doAction(state) {
    const user = { ...this.payload };
    user.authenticate = true;

    return { ...state, auth: user, loading: false, redirect: false };
  }
}

export class LoginStartAction implements IRtlqActionLike<Credentiel> {
  readonly type = LOGIN_START;
  constructor(public payload: Credentiel) {}
  doAction(state: any) {
    return { ...state, loading: true, authError: null, redirect: true };
  }
}

export class LoginSuccessedAction implements IRtlqActionLike<ISessionUserModelLike> {
  readonly type = LOGIN_SUCCESSED;
  constructor(public payload: ISessionUserModelLike) {}
  doAction(state) {
    const user = { ...this.payload };
    user.authenticate = true;

    return { ...state, auth: user };
  }
}

export class LoginFailedAction implements IRtlqActionLike<string> {
  readonly type = LOGIN_FAILED;
  constructor(public payload: string) {}
  doAction(state: any) {
    return { ...state, auth: null, loading: false, authError: this.payload };
  }
}

export class LogoutAction implements IRtlqActionLike<string> {
  readonly type = LOGOUT;
  constructor(public payload: string = null) {}
  doAction(state) {
    return { ...state, auth: null, authError: this.payload };
  }
}

export type AuthActions =
  | LoginStartAction
  | LoginSuccessedAction
  | LoginFailedAction
  | LogoutAction
  | AutoLoginAction
  | AutoLoginFailedAction
  | AutoLoginSuccessedAction
  | AutoLoginSuccessedAction;
