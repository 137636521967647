import { Injectable } from '@angular/core';
import { RessourceService } from '@shared/api/config/ressource.service';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  static LANG = TranslateService.initLang();
  static TRANSLATE = null;

  static initLang() {
    const config = {
      app: {
        page_title: 'Association KFR',
      },
      technique: {
        error_msg: "Yikes! There was was a problem with our hyperdrive device and we couldn't retrieve your data!",
      },
      default: {
        // niveau: 'Choisir un niveau',
        // style: 'Choisir un style',
        // tresoreriecategorie: 'Choisir une catégorie',
        // tresorerieetat: 'Choisir un état',
        // dropdown_photodir: 'Choisir une galerie',
        // dropdown_categorie: 'Choisir la catégorie',
        // dropdown_etat: "Choisir l'état",
        // UsersFacade: 'Choisir un adhérent',
        // dropdown_president: 'Choisir le/la président(e)',
        // dropdown_tresorier: 'Choisir le/la trésorier(e)',
        // dropdown_secretaire: 'Choisir le/la secrétaire',

        actif: 'Actif',
        adherent_id: 'Adhérent',
        adherent_name: 'Adhérent',
        adresse: 'Adresse',
        avatar: 'Photo',
        association: 'Association',
        categorie_id: 'Catégorie',
        categorie_name: 'Catégorie',
        commentaire: 'Commentaire',
        cotisation: 'Cotisation',
        cotisation_id: 'Cotisation',
        cotisation_name: 'Cotisation',
        code_postal: 'Code Postal',
        date: 'Date',
        date_text: 'Date',
        date_creation: 'Date création',
        date_creation_text: 'Date de création',
        date_naissance: 'Date de naissance',
        date_last_auth: 'Dernière connexion',
        description: 'Description',
        etat_id: 'Etat',
        etat_name: 'Etat',
        email: 'Email',
        id: 'ID',
        licence_number: 'Num de Licence',
        licence_etat: 'Etat licence',
        nom: 'Nom',
        niveau_id: 'Niveau',
        niveau_name: 'Niveau',
        prenom: 'Prénom',
        pwd: 'Mot de passe',
        publique: 'Public',
        saison_id: 'Saison',
        saison_name: 'Saison',
        saison_courante: 'Saison Courante',
        style_id: 'Style',
        style_name: 'Style',
        telephone: 'Téléphone',
        title: 'Titre',
        username: 'Username',
        video: 'Vidéo',
        ville: 'Ville',
      },
      kpi: {
        KPI_USER_ACTIF: 'Adhérents Actifs',
        KPI_TRESORERIE_EN_RETARD: 'Trés. Retard',
        KPI_TRESORERIE_SAISON_COURANTE_PREVISIONNELLE: 'Trés. Saison Prév.',
        KPI_TRESORERIE_SAISON_COURANTE_A_DATE: 'Trés. Saison à date',
        KPI_TRESORERIE_TOTALE: 'Trés. Totale',
        KPI_TRESORERIE_TOTALE_POINTEE: 'Trés. pointee',
        KPI_TRESORERIE_TOTALE_NON_POINTEE: 'Trés. non pointe',
        KPI_COURS_EFECTUES: 'Cours',
      },
    };

    config[RessourceService.MENUS.URL_ASSOCIATION.id] = {
      menu: 'Associations',
      page_title: "Les adhérents de l'association",
      siege_social: 'Siège Social',
      email: 'Email',
      numero_siren: 'SIRET/SIREN',
      url_intranet: 'Intranet',
      url_extranet: 'WebSite',
      numero_compte_bancaire: 'Compte Bancaire',
      president_nom_prenom: 'Président',
      tresorier_nom_prenom: 'Trésorier',
      secretaire_nom_prenom: 'Secrétaire',
      message: 'Message',
      closed: 'Fermée',
    };

    config[RessourceService.MENUS.URL_BENEVOLAT.id] = {
      menu: 'Bénévolat',
      page_title: "Bénévolat de l'association",
      heure: 'Heure',
      minute: 'Minute',
      // UsersFacade: 'Choisir un bénévole',
      adherent_id: 'Vénérable bénévole',
      adherent_name: 'Vénérable bénévole',
    };

    config[RessourceService.MENUS.URL_TRESORERIE_ETAT.id] = {
      menu: 'Etats',
      page_title: "Les états de la trésorerie de l'association",
      nom: 'Valeur',
      next_etat_name: 'Next Etat',
      next_etat_id: 'Next Etat',
    };

    config[RessourceService.MENUS.URL_TRESORERIE_CATEGORIE.id] = {
      menu: 'Catégories',
      page_title: "Les categories de la trésorerie de l'association",
      nom: 'Valeur',
    };

    config[RessourceService.MENUS.URL_TRESORERIE.id] = {
      menu: 'Trésorerie',
      page_title: "La trésorerie de l'association",
      montant: 'Montant (€)',
      adherent_name: 'Adh.',
      adherent_id: 'Id Adh.',
      responsable: 'Resp.',
      cheque: 'Chèque',
      numero_cheque: 'Num. chèque',
      numero_remise_cheque: 'Remise chèque',
      pointe: 'Pointé',
      nb_cheque: 'Nb. chèque',
      saison_courante: 'Saison',
    };

    config[RessourceService.MENUS.URL_COTISATION.id] = {
      menu: 'Cotisations',
      page_title: "Cotisation de l'association",
      repartition_cheque: 'Repartition chèques.',
      type: 'Type',
      nb_cheque: 'Nb Cheque',
    };
    config[RessourceService.MENUS.URL_COURS.id] = {
      menu: 'Les cours',
      page_title: 'Les cours',
      thematique_tao: 'Tao',
      thematique_application: 'Application',
      thematique_combat: 'Combat',
      nb_cours_essais: 'Nb. cours essais',
    };

    config[RessourceService.MENUS.URL_DRIVE.id] = {
      menu: 'Drive',
      page_title: 'Votre espace',
      name: 'Nom du fichier',
      size: 'taille (ko)',
    };
    config[RessourceService.MENUS.URL_EVENT.id] = {
      menu: 'Evènements',
      page_title: 'Evènements Associatif',
      adresse: 'Adresse',
      adherents: 'Adhérents',
      nb_accompagnants: 'Nb accompagnants',
      nb_people: 'Nb participants',
    };
    config[RessourceService.MENUS.URL_BUREAU.id] = {
      menu: 'Bureau',
      page_title: 'Le bureau',
      date_fin_text: 'Fin de mandat',
      date_fin: 'Fin de mandat',
      date_creation_text: 'Début de mandat',
      date_creation: 'Début de mandat',
      president_id: 'Président',
      president_name: 'Président',
      secretaire_id: 'Secrétaire',
      secretaire_name: 'Secrétaire',
      tresorier_id: 'Trésorier',
      tresorier_name: 'Trésorier',
      nb_saisons: 'Nb Saison',
      saisons: 'Saisons',
    };
    config[RessourceService.MENUS.URL_GROUPE.id] = {
      menu: 'Groupes',
      page_title: "Groupes de l'association",
      nom: 'Nom',
      role: 'Rôle',
      adherents: 'Adhérents',
      nb_adherents: 'Nb. Adh.',
    };

    config[RessourceService.MENUS.URL_NEWS.id] = {
      menu: 'News',
      page_title: "l'actualité",
      title: 'Titre',
      link: 'Lien',
    };

    config[RessourceService.MENUS.URL_MATERIEL.id] = {
      menu: 'Matériel',
      page_title: 'Le matériel',
      prix_achat: 'Achat €',
      prix_vente: 'Vente €',
      stock: 'Restant',
      date_achat: 'Date achat',
    };
    config[RessourceService.MENUS.URL_PHOTO_DIRECTORY.id] = {
      menu: 'Galerie (Répertoire)',
      page_title: 'Les galeries',
      code: 'Code Interne',
      nb_photos: 'Nb de photos',
      preview_url: 'Photo répertoire',
    };

    config[RessourceService.MENUS.URL_PHOTO.id] = {
      menu: 'Galerie',
      page_title: 'Les photos des galeries',
      source: 'Photo',
      repertoire_name: 'Galerie',
      repertoire_id: 'Galerie (ID)',
      title: 'Titre',
      description: 'Description',
      preview: 'Preview',
    };

    config[RessourceService.MENUS.URL_SAISON.id] = {
      menu: 'Saison',
      page_title: "Saisons de l'association",
      date_debut: 'Début Saison',
      date_fin: 'Fin Saison',
      nb_adherents: 'Nb Adherents',
    };

    config[RessourceService.MENUS.URL_CATEGORIE_VOTEE.id] = {
      menu: 'Bilan AG',
      page_title: 'Bilan AG sur les objectifs financiers',
      montant: 'objectif (€)',
    };

    config[RessourceService.MENUS.URL_USERS.id] = {
      menu: 'Adhérents',
      page_title: "Les adhérents de l'association",
    };

    config[RessourceService.MENUS.URL_KUNGFU_STYLE.id] = {
      menu: 'Styles de Kung-Fu',
      page_title: "Les styles de KungFu connus dans l'association",
      nom: 'Valeur',
    };

    config[RessourceService.MENUS.URL_KUNGFU_NIVEAU.id] = {
      menu: 'Niveaux des Taos',
      page_title: 'Les niveaux des taos',
      nom: 'Valeur',
    };

    config[RessourceService.MENUS.URL_KUNGFU_TAO.id] = {
      menu: 'Taos',
      page_title: "La liste des taos de l'association",
      pronociation: 'Français',
      nom: 'Nom',
      pinyin: 'Pinyin',
      nom_chinois: 'Chinois',
      traduction: 'Traduction',
      arme: 'Arme',
      video_url: 'Video du tao',
      origine: 'Origine',
      combine: 'Combiné',
      nb_revision: 'Révision ',
      niveau: 'Niveau',
      label_niveau: 'Niveau',
      favoris: 'Favoris',
      search: 'search',
      linked: 'linked',
      annee_apprentissage: "annee d'apprentissage",
      drive_id: 'Id drive',
      date_update: 'Date révision',
      url_video: 'Url',
      nb_moves: 'Nb mouvements',
      nb_taos_learnt: 'Nb élèves',
      tooltip_adherents: 'Liste des élèves ayant connaissante ce tao',
      referents: 'Réferents',
      nb_taos_referent: 'Nb référents',
      tooltip_referents: 'Liste des référents de ce tao',
    };

    config[RessourceService.MENUS.URL_TROMBINOSCOPE.id] = {
      menu: 'Le trombinoscope',
      page_title: "l'école",
    };

    config[RessourceService.MENUS.URL_USER_TRESORERIES.id] = {
      menu: 'Ma trésorerie',
      page_title: 'Ma trésorerie',
      montant: 'Montant (€)',
      adherent_name: 'Adh.',
      adherent_id: 'Id Adh.',
      responsable: 'Resp.',
      cheque: 'Chèque',
      numero_cheque: 'Num. chèque',
      numero_remise_cheque: 'Remise chèque',
      pointe: 'Pointé',
      nb_cheque: 'Nb. chèque',
      saison_courante: 'Saison',
    };

    config[RessourceService.MENUS.URL_USER_PROFIL.id] = {
      menu: 'Mon profil',
      page_title: 'Votre profil',
    };

    config[RessourceService.MENUS.URL_USER_TAOS_USER.id] = {
      menu: 'Mes Taos',
      page_title: 'Configuration de mes Taos',
    };

    config[RessourceService.MENUS.URL_KUNGFU_TAOS_USERS.id] = {
      menu: 'Taos par utilisateurs',
      page_title: 'Répartition des Taos',
    };

    config[RessourceService.MENUS.URL_TAOS_HISTO.id] = {
      menu: 'Historique',
      page_title: 'Historique de travail de vos Taos',
      tao_adherent_name: 'Nom du Tao',
      action: "Type d'action",
      date_update: "Date d'action",
    };

    return config;
  }

  static getTranslate(domaine, key): string {
    if (!TranslateService.TRANSLATE) {
      TranslateService.TRANSLATE = TranslateService.initLang();
    }

    const valueDefault = '[' + domaine + '] ' + key;
    let value = null;
    if (TranslateService.TRANSLATE[domaine]) {
      value = TranslateService.TRANSLATE[domaine][key];
    }
    if (!value) {
      value = TranslateService.TRANSLATE['default'][key];
    }

    return value ? value : valueDefault;
  }
}
