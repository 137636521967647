import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() show: any;
  @Input() level = 'info';
  @Input() message = 'Chargment en cours...';
  @Input() withoutProgressBar = false;
  @Input() errors$: Observable<any>;
  
  construct() {

  }
  getClass() {
    let clazz = 'alert alert-' + this.level;
    if (! this.withoutProgressBar) {
      clazz += ' mx-auto';
    }
    return clazz;
  }
}
