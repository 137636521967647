import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';

import { RtlqDataService } from '@shared/api/ngrx/rtlq.data-service';
import { ITresorerieCategorieModelLike } from '@tresorerie/models/tresorerie-categorie.model';
import { TresorerieCategorieConfig } from '@tresorerie/resource/tresorerie-categorie.config';
import { TresorerieCategorieService } from './tresorerie-categorie.service';

@Injectable({ providedIn: 'root' })
export class TresorerieCategorieDataService extends RtlqDataService<ITresorerieCategorieModelLike> {

  constructor(
    protected apiService: TresorerieCategorieService,
    protected http: HttpClient,
    protected httpUrlGenerator: HttpUrlGenerator
  ) {
    super(TresorerieCategorieConfig.STORE, apiService, http, httpUrlGenerator);
  }
}
