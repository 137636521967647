import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
} from '@ngrx/data';

import { ICotisationModelLike } from '@cotisation/models/cotisation.model';

import { CotisationConfig } from '@cotisation/resource/cotisation.config';

@Injectable({ providedIn: 'root' })
export class CotisationEntityService extends EntityCollectionServiceBase<ICotisationModelLike> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(CotisationConfig.STORE, serviceElementsFactory);
  }
}
