import { Injectable } from '@angular/core';
import { UserModel, IUserModelLike } from '@user/models/user.model';
import { AbstractCrudBuilder, IBuilderLike } from '@shared/builder/abstract-rtlq.build';
import { UserService } from '@user/api/user/user.service';

export interface IUserBuilderLike extends IBuilderLike<IUserModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class UserBuilder extends AbstractCrudBuilder<IUserModelLike> implements IUserBuilderLike {
  getEntity() {
    return UserModel;
  }
}
