import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { HttpErrorResponseModel } from '@core/models/http-error-response.model';
import { AuthenticationService } from '@authenticate/api/security/authentication.service';
import { Store } from '@ngrx/store';
import * as fromApp from '@app_store/app.reducer';
import { LogoutAction } from '@authenticate/store/auth.actions';

export const genericRetryStrategy = ({
  maxRetryAttempts = 2,
  scalingDuration = 1000,
  excludedStatusCodes = [],
}: {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  excludedStatusCodes?: number[];
} = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      // if maximum number of retries have been met
      // or response is a status code we don't wish to retry, throw error
      if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find((e) => e === error.status)) {
        return throwError(error);
      }
      console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`);
      // retry after 1s, 2s, etc...
      return timer(retryAttempt * scalingDuration);
    })
    // finalize(() => console.log('We are done!'))
  );
};

@Injectable()
// https://www.learnrxjs.io/operators/error_handling/retrywhen.html
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store<fromApp.AppState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen(
        genericRetryStrategy({
          scalingDuration: 500,
          excludedStatusCodes: [401, 403, 404],
        })
      ),
      catchError((error: HttpErrorResponse) => {
        if (error.url !== AuthenticationService.logoutUrl && error.url !== AuthenticationService.loginUrl) {
          if (error.status === 403 || error.status === 401) {
            // auto logout if 403/401 response returned from api
            this.store.dispatch(new LogoutAction('Pour des raisons de sécurité vous avez été déconnecté.'));
          }
        }

        const myError: HttpErrorResponseModel = new HttpErrorResponseModel(error, request);

        return throwError(myError);
      })
    );
  }
}
