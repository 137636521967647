import { Injectable } from '@angular/core';
import { IStatsModelLike, StatsModel } from '@dashboard/models/stats.model';
import { DateService } from '@shared/api/date.service';
import { AbstractRtlqBuilder, IBuilderLike } from '@shared/builder/abstract-rtlq.build';

export interface IStatsBuilderLike extends IBuilderLike<IStatsModelLike> {}

@Injectable({ providedIn: 'root' })
export class StatsBuilder extends AbstractRtlqBuilder<IStatsModelLike> implements IStatsBuilderLike {
  getEntity() {
    return StatsModel;
  }
  protected customConvertResponseToModele(json: {}, object: IStatsModelLike): IStatsModelLike {
    this.updatePourcentageAvancement(object);
    this.updateNbJoursProchaineEcheance(object);
    this.updateNbJoursProchaineEcheance(object);
    object.tao_last_studied_name = json['tao_last_studied_name']?.split(/-/)[0];
    return object;
  }

  private updatePourcentageAvancement(entity: IStatsModelLike) {
    if (entity.nb_tao !== 0) {
      entity.pourcentage_avancement = Math.round((entity.nb_tao_user / entity.nb_tao) * 100);
    } else {
      entity.pourcentage_avancement = 0;
    }
  }

  private updateNbJoursProchaineEcheance(entity: IStatsModelLike) {
    if (entity.prochaine_echeance) {
     
      const diffDays =  DateService.string2Moment(entity.prochaine_echeance).diff(DateService.nowAsMoment(), 'days');
      entity.nb_jours_prochaine_echeance = diffDays;
    }
  }
}
