import { PageConfigModel } from '../config/page-config.model';

export interface IRouteItemModelLike {
  id: string;
  uri: string;
  module: string;
  category: string;
  roles: string[];
}

export class RouteItemModel implements IRouteItemModelLike {
  static create(moduleName: string, category: string, roles: string[], uri: string = ''): IRouteItemModelLike {
    return { id: moduleName + '_' + uri, uri: uri, module: moduleName, category: category, roles: roles };
  }
  constructor(
    public id: string,
    public uri: string,
    public module: string,
    public category: string,
    public roles: string[] = []
  ) {}
}
