import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';

import { RtlqDataService } from '@shared/api/ngrx/rtlq.data-service';

import { IKungFuNiveauModelLike } from '@kungfu/models/kungfu-niveau.model';
import { KungFuNiveauConfig } from '@kungfu/resource/kungfu-niveau.config';
import { KungFuNiveauService } from './kungfu-niveau.service';

@Injectable({ providedIn: 'root' })
export class KungFuNiveauDataService extends RtlqDataService<IKungFuNiveauModelLike> {
  constructor(
    protected apiService: KungFuNiveauService,
    protected http: HttpClient,
    protected httpUrlGenerator: HttpUrlGenerator
  ) {
    super(KungFuNiveauConfig.STORE, apiService, http, httpUrlGenerator);
  }
}
