import { IActifLike} from './i-actif.like';
import { AbstractCrudDropDownModel } from './absract-crud.model';
import { AbstractCrudWithDateModel } from './abstract-crud-with-date.model';
import { ICrudModelLike } from './i-crud.model';


export abstract class AbstractCrudActifWithDateModelLike extends AbstractCrudWithDateModel implements ICrudModelLike, IActifLike {
  public actif: boolean = null;

  switchActif() {
    this.actif = !this.actif;
  }

  getActif() {
    return this.actif;
  }
  setActif(value: boolean) {
    this.actif = value;
  }

  initBeforeCreating() {
    super.initBeforeCreating();
    this.actif = false;
  }
}

export abstract class AbstractCrudActifWithDropDownModelLike extends AbstractCrudDropDownModel implements ICrudModelLike, IActifLike {
  public actif: boolean;

  switchActif() {
    this.actif = !this.actif;
  }

  getActif() {
    return this.actif;
  }
  setActif(value: boolean) {
    this.actif = value;
  }

  initBeforeCreating() {
    super.initBeforeCreating();
    this.actif = false;
  }
}
