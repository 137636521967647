export class PageConfigModel {
  public static getUri(menu): string[] {
    const target = [];
    if (menu.module) {
      target.push('/' + menu.module);
    }
    if (menu.uri) {
      target.push(target.length === 0 ? '/' + menu.uri : menu.uri);
    }
    return target;
  }
  public static getStringUri(menu): string {
    return PageConfigModel.getUri(menu).join('/');
  }
  public static getDomaine(menu) {
    return menu.uri ? menu.uri : 'default';
  }
}
