<div class="layout-wrapper">
  <app-header class="ng-tns-c0-0"></app-header>

  <div id="layout-container">
    <div class="loader" *ngIf="loading">
      <div class="loader-content text-justify">
        <div>
          <strong> Chargement en cours ... </strong>
        </div>
        <div class="text-center">
          <div class="spinner-border" role="status"></div>
        </div>
      </div>
    </div>
    <div *ngIf="!loading">
      <router-outlet></router-outlet>
    </div>

    <app-footer class="ng-tns-c0-0" id="layout-footer"></app-footer>
  </div>
</div>
