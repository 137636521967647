<span *ngIf="show">
  <div id="infobar" [class]="getClass()" role="alert">
    {{ message }}
    <div *ngIf="!withoutProgressBar">
      <mat-progress-bar mode="indeterminate" [style]="{ height: '3px' }"></mat-progress-bar>
    </div>
  </div>
</span>

<div *ngIf="errors$ | async as errors">
  {{ errors | json }}
  {{ errors.message }}
</div>
