import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { IDriveModelLike } from '@drive/models/drive.model';

import { DriveConfig } from '@drive/resource/drive.config';

@Injectable({ providedIn: 'root' })
export class DriveEntityService extends EntityCollectionServiceBase<IDriveModelLike> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(DriveConfig.STORE, serviceElementsFactory);
  }
}
