import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';

import { RtlqDataService } from '@shared/api/ngrx/rtlq.data-service';

import { IPhotoDirectoryModelLike } from '@photo/models/photo-directory.model';
import { PhotoDirectoryConfig } from '@photo/resource/photo-directory.config';
import { PhotoDirectoryService } from './photo-directory.service';

@Injectable({ providedIn: 'root' })
export class PhotoDirectoryDataService extends RtlqDataService<IPhotoDirectoryModelLike> {
  constructor(
    protected apiService: PhotoDirectoryService,
    protected http: HttpClient,
    protected httpUrlGenerator: HttpUrlGenerator
  ) {
    super(PhotoDirectoryConfig.STORE, apiService, http, httpUrlGenerator);
  }
}

