import { Injectable } from '@angular/core';

import { RessourceService } from '@shared/api/config/ressource.service';
import { DropDownService } from '@shared/api/dropdown/dropdown.service';
import { ICrudConfigModel } from '@shared/modeles/crud/crud-config.model';
import { IRouteItemModelLike } from '@shared/modeles/menu/route-item.model';
import { AbstractRtlqConfig } from '@shared/resource/rtlq.config';
import { app_config } from 'app/app.config';

@Injectable({
  providedIn: 'root',
})
export class PhotoConfig extends AbstractRtlqConfig {
  static MENU: IRouteItemModelLike = RessourceService.MENUS.URL_PHOTO;
  static REST_URL = 'association/photos';
  static MODULE = PhotoConfig.MENU.module;
  static DOMAINE = PhotoConfig.MENU.uri;
  static STORE = app_config.STORE.PhotoConfig;

  get module(): string {
    return PhotoConfig.MODULE;
  }

  get domaine(): string {
    return PhotoConfig.DOMAINE;
  }

  get id(): string {
    return PhotoConfig.MENU.id;
  }
  getCols(): Array<ICrudConfigModel> {
    const config: Array<ICrudConfigModel> = [];

    config.push(this.createCrudConfigModel('id').withType(RessourceService.CONST.TYPE_NUMBER).withoutFormOrTable());
    config.push(
      this.createCrudConfigModel('repertoire')
        .withOnlyForm()
        .isFormRequired()
        .withDropDown(DropDownService.DROPDOWNS.PHOTO_DIRECTORY)
    );
    config.push(this.createCrudConfigModel('repertoire_name').withOnlyTable());
    config.push(this.createCrudConfigModel('preview').withType(RessourceService.CONST.TYPE_URL_IMAGE).withOnlyTable());
    config.push(this.createCrudConfigModel('source').isImage('preview').withOnlyForm());
    config.push(this.createCrudConfigModel('title').isFormRequired());
    config.push(this.createCrudConfigModel('description').isFormRequired());

    return config;
  }
}
