import { Moment } from "moment";

export interface IStatsModelLike {
  nb_tao_user: number;
  nb_tao: number;
  tresorerie_retard: number;
  prochaine_echeance: Moment;
  pourcentage_avancement: number;
  nb_jours_prochaine_echeance: number;
  tao_last_studied_name: string;
  tao_last_studied_date: Moment;
}

export class StatsModel implements IStatsModelLike {
  pourcentage_avancement = 0;
  nb_jours_prochaine_echeance = 0;

  constructor(
    public nb_tao_user = 0,
    public nb_tao = 0,
    public tresorerie_retard = 0,
    public prochaine_echeance = null,
    public tao_last_studied_name = null,
    public tao_last_studied_date: Moment = null
  ) {}
}
