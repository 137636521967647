import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { EntityMetadataMap, EntityDataModule, EntityDefinitionService, EntityDataService } from '@ngrx/data';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ServiceWorkerModule } from '@angular/service-worker';

import { DROPDOWN_SERVICE_TOKEN } from '@shared/api/dropdown/dropdown.service';

import { CoreModule } from '@core/core.module';
import { AuthEffects } from '@authenticate/effect/auth.effects';
import { environment } from '@env';

import { JwtInterceptor } from '@authenticate/interceptor/jwt-interceptor.service';
import { CotisationDataService } from '@cotisation/api/cotisation.data-service';
import { CotisationConfig } from '@cotisation/resource/cotisation.config';
import { CotisationEntityService } from '@cotisation/api/cotisation.entity-service';
import { ErrorInterceptor } from '@core/interceptor/error-interceptor.service';
import { SaisonDataService } from '@saison/api/saison/saison.data-service';
import { SaisonConfig } from '@saison/resource/saison.config';
import { TresorerieCategorieConfig } from '@tresorerie/resource/tresorerie-categorie.config';
import { TresorerieEtatConfig } from '@tresorerie/resource/tresorerie-etat.config';
import { TresorerieEtatDataService } from '@tresorerie/api/etat/tresorerie-etat.data-service';
import { TresorerieEtatEntityService } from '@tresorerie/api/etat/tresorerie-etat.entity-service';
import { TresorerieCategorieEntityService } from '@tresorerie/api/categorie/tresorerie-categorie.entity-service';
import { TresorerieCategorieDataService } from '@tresorerie/api/categorie/tresorerie-categorie.data-service';
import { UserDataService } from '@user/api/user/user.data-service';
import { UserConfig } from '@user/resource/user.config';
import { UserEntityService } from '@user/api/user/user.entity-service';
import { CurrentUserEffects } from '@user/store/current-user/current-user.effects';
import { KungFuNiveauConfig } from '@kungfu/resource/kungfu-niveau.config';
import { KungFuStyleConfig } from '@kungfu/resource/kungfu-style.config';
import { KungFuNiveauDataService } from '@kungfu/api/niveau/kungfu-niveau.data-service';
import { KungFuStyleDataService } from '@kungfu/api/style/kungfu-style.data-service';
import { KungFuNiveauEntityService } from '@kungfu/api/niveau/kungfu-niveau.entity-service';
import { KungFuStyleEntityService } from '@kungfu/api/style/kungfu-style.entity-service';
import { CurrentSaisonEffects } from '@saison/store/current-saison/current-saison.effects';
import { SaisonEntityService } from '@saison/api/saison/saison.entity-service';
import { appReducer, metaReducers } from './store/app.reducer';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { DriveDataService } from '@drive/api/drive/drive.data-service';
import { DriveConfig } from '@drive/resource/drive.config';
import { DashboardModule } from '@dashboard/dashboard.module';
import { RouterModule } from '@angular/router';
import { WebpushModule } from './webpush/webpush.module';
import { AssociationDataService } from '@association/api/association.data-service';
import { AssociationConfig } from '@association/ressource/association.config';
import { TaoHistoConfig } from '@tao/resource/tao-histo.config';
import { TaoHistoDataService } from '@tao/api/tao-histo/tao-histo.data-service';
import { PhotoDirectoryEntityService } from '@photo/api/photo-directory/photo-directory.entity-service';
import { PhotoDirectoryDataService } from '@photo/api/photo-directory/photo-directory.data-service';
import { PhotoDirectoryConfig } from '@photo/resource/photo-directory.config';

const entityMetadata: EntityMetadataMap = {};
entityMetadata[SaisonConfig.STORE] = {
  entityDispatcherOptions: {
    optimisticUpdate: true, //  default false
  },
};
entityMetadata[TresorerieCategorieConfig.STORE] = {
  // TODO defautl sort => sortComparer:
  entityDispatcherOptions: {
    optimisticUpdate: true, //  default false
  },
};
entityMetadata[TresorerieEtatConfig.STORE] = {
  // TODO defautl sort => sortComparer:
  entityDispatcherOptions: {
    optimisticUpdate: true, //  default false
  },
};
entityMetadata[UserConfig.STORE] = {
  // TODO defautl sort => sortComparer:
  entityDispatcherOptions: {
    optimisticUpdate: true, //  default false
  },
};
entityMetadata[CotisationConfig.STORE] = {
  // TODO defautl sort => sortComparer:
  entityDispatcherOptions: {
    optimisticUpdate: true, //  default false
  },
};

entityMetadata[KungFuNiveauConfig.STORE] = {
  // TODO defautl sort => sortComparer:
  entityDispatcherOptions: {
    optimisticUpdate: true, //  default false
  },
};
entityMetadata[KungFuStyleConfig.STORE] = {
  // TODO defautl sort => sortComparer:
  entityDispatcherOptions: {
    optimisticUpdate: true, //  default false
  },
};

entityMetadata[DriveConfig.STORE] = {
  // TODO defautl sort => sortComparer:
  entityDispatcherOptions: {
    optimisticUpdate: true, //  default false
  },
};
entityMetadata[AssociationConfig.STORE] = {
  // TODO defautl sort => sortComparer:
  entityDispatcherOptions: {
    optimisticUpdate: true, //  default false
  },
};

entityMetadata[TaoHistoConfig.STORE] = {
  entityDispatcherOptions: {
    optimisticUpdate: true, //  default false
  },
};

entityMetadata[PhotoDirectoryConfig.STORE] = {
  // TODO defautl sort => sortComparer:
  entityDispatcherOptions: {
    optimisticUpdate: true, //  default false
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    // APP STATE
    StoreModule.forRoot(appReducer, { metaReducers }),
    EffectsModule.forRoot([AuthEffects, CurrentUserEffects, CurrentSaisonEffects]),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal,
    }),
    EntityDataModule.forRoot({}),
    CoreModule,
    DashboardModule,

    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressAnimation: 'increasing',
    }),
    // SERVICE WORKER
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    RouterModule,
    WebpushModule,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // LISTE SERVICE POUR LES DROPDOWN //
    { provide: DROPDOWN_SERVICE_TOKEN, useClass: SaisonEntityService, multi: true },
    { provide: DROPDOWN_SERVICE_TOKEN, useClass: TresorerieCategorieEntityService, multi: true },
    { provide: DROPDOWN_SERVICE_TOKEN, useClass: TresorerieEtatEntityService, multi: true },
    { provide: DROPDOWN_SERVICE_TOKEN, useClass: CotisationEntityService, multi: true },
    { provide: DROPDOWN_SERVICE_TOKEN, useClass: UserEntityService, multi: true },
    { provide: DROPDOWN_SERVICE_TOKEN, useClass: KungFuNiveauEntityService, multi: true },
    { provide: DROPDOWN_SERVICE_TOKEN, useClass: KungFuStyleEntityService, multi: true },
    { provide: DROPDOWN_SERVICE_TOKEN, useClass: PhotoDirectoryEntityService, multi: true },
  ],

  exports: [],
})
export class AppModule {
  constructor(
    private entityDefinitionService: EntityDefinitionService,
    private entityDataService: EntityDataService,

    private saisonDataService: SaisonDataService,
    private tresorerieCategorieDataService: TresorerieCategorieDataService,
    private tresorerieEtatDataService: TresorerieEtatDataService,
    private userDataService: UserDataService,
    private cotisationDataService: CotisationDataService,
    private kungfuNiveauDataService: KungFuNiveauDataService,
    private kungfuStyleDataService: KungFuStyleDataService,
    private driveDataService: DriveDataService,
    private associationDataService: AssociationDataService,
    private taoHistoDataService: TaoHistoDataService,
    private photoDirectoryDataService: PhotoDirectoryDataService
  ) {
    this.entityDefinitionService.registerMetadataMap(entityMetadata);
    this.entityDataService.registerService(SaisonConfig.STORE, this.saisonDataService);
    this.entityDataService.registerService(TresorerieCategorieConfig.STORE, this.tresorerieCategorieDataService);
    this.entityDataService.registerService(TresorerieEtatConfig.STORE, this.tresorerieEtatDataService);
    this.entityDataService.registerService(UserConfig.STORE, this.userDataService);
    this.entityDataService.registerService(CotisationConfig.STORE, this.cotisationDataService);
    this.entityDataService.registerService(KungFuNiveauConfig.STORE, this.kungfuNiveauDataService);
    this.entityDataService.registerService(KungFuStyleConfig.STORE, this.kungfuStyleDataService);
    this.entityDataService.registerService(DriveConfig.STORE, this.driveDataService);
    this.entityDataService.registerService(AssociationConfig.STORE, this.associationDataService);
    this.entityDataService.registerService(TaoHistoConfig.STORE, this.taoHistoDataService);
    this.entityDataService.registerService(PhotoDirectoryConfig.STORE, this.photoDirectoryDataService);
  }
}
