import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ICrudService } from '@shared/api/crud/i-crud.service';
import { AbstractRtlqService } from '@shared/api/crud/abstract-main.service';

import { IPhotoDirectoryModelLike } from '@photo/models/photo-directory.model';
import { PhotoDirectoryBuilder } from '@photo/builder/photo-directory.builder';
import { PhotoDirectoryConfig } from '@photo/resource/photo-directory.config';

export interface IPhotoDirectoryServiceLike extends ICrudService<IPhotoDirectoryModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class PhotoDirectoryService
  extends AbstractRtlqService<IPhotoDirectoryModelLike>
  implements IPhotoDirectoryServiceLike {
  constructor(protected http: HttpClient, protected builder: PhotoDirectoryBuilder) {
    super(http, builder, PhotoDirectoryConfig.REST_URL);
  }
}
