import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AbstractRtlqService } from '@shared/api/crud/abstract-main.service';
import { ICrudService } from '@shared/api/crud/i-crud.service';

import { TresorerieEtatBuilder } from '@tresorerie/builder/tresorerie-etat.builder';
import { ITresorerieEtatModelLike } from '@tresorerie/models/tresorerie-etat.model';
import { TresorerieEtatConfig } from '@tresorerie/resource/tresorerie-etat.config';

export interface ITresorerieEtatServiceLike extends ICrudService<ITresorerieEtatModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class TresorerieEtatService
  extends AbstractRtlqService<ITresorerieEtatModelLike>
  implements ITresorerieEtatServiceLike {
  constructor(protected http: HttpClient, protected builder: TresorerieEtatBuilder) {
    super(http, builder, TresorerieEtatConfig.REST_URL);
  }
}
