import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';
import { IDropdownModelLike } from '@shared/modeles/dropdown/dropdown.model';
import { IDateCreationModelLike } from '@shared/modeles/crud/i-crud-with-date.model';
import { AbstractRootService } from '@shared/api/crud/abstract-root.service';
import { DateService } from '@shared/api/date.service';
import { IToolTipModel } from '@shared/modeles/tooltips/i-tooltip.model';
import { IActifLike } from '@shared/modeles/crud/i-actif.like';
import { AbstractCrudActifWithDateModelLike } from '@shared/modeles/crud/abstract-crud-actif-with-date.model';

export interface IUserModelLike
  extends ICrudModelLike,
    IDateCreationModelLike,
    IToolTipModel,
    IActifLike,
    IDropdownModelLike {
  nom: string;
  prenom: string;
  username: string;
  email: string;
  pwd: string;
  telephone: string;
  date_naissance: moment.Moment;
  publique: boolean;
  adresse: string;
  avatar: string;
  avatar_uri: string;
  avatar_url: string;
  code_postal: string;
  ville: string;
  date_last_auth: moment.Moment;
  licence_number: string;
  licence_etat: string;
  montant_total_encaisse: number;
  montant_total_en_retard: number;
  montant_total_previsionnel: number;
  cotisation_id: number;
  cotisation_name: string;
  saison_courante: boolean;
  has_tresorerie_in_saison_courante: boolean;
  taos: any[];

  getPrenomNom(): string;
  getAge(): number;
  desactiver(): void;
  activer(): void;
  getNbTaos(): number;
}

export class UserModel extends AbstractCrudActifWithDateModelLike implements IUserModelLike {
  public cotisation = {};

  static getPrenomNom(value) {
    if (value.nom && value.prenom) {
      return value.prenom + ' ' + value.nom;
    } else if (!value.nom && value.prenom) {
      return value.prenom;
    } else {
      return value.nom;
    }
  }

  constructor(
    public id = null,
    public nom = null,
    public prenom = null,
    public email = null,
    public username = null,
    public pwd = null,
    public telephone = null,
    public date_naissance = null,
    public saison_courante = null,
    public publique = null,
    public adresse = null,
    public avatar = null,
    public avatar_uri = null,
    public avatar_url = null,
    public avatar_name = null,
    public code_postal = null,
    public ville = null,
    public date_last_auth = null,
    public licence_number = null,
    public licence_etat = null,
    public montant_total_encaisse = null,
    public montant_total_en_retard = null,
    public montant_total_previsionnel = null,
    public cotisation_id = null,
    public cotisation_name = null,
    public taos = null,
    public has_tresorerie_in_saison_courante = null
  ) {
    super(id);
  }

  getPrenomNom() {
    return UserModel.getPrenomNom(this);
  }

  getAge(): number {
    const diff = Math.abs(Date.now() - this.date_naissance);
    const diffYear = Math.ceil(diff / (1000 * 3600 * 24 * 365)) - 1;

    return diffYear;
  }

  /**
   * Get the number of form known by the user.
   */
  getNbTaos(): number {
    return this.taos.length;
  }

  consolidation() {
    super.consolidation();

    this.date_last_auth = DateService.string2Moment(this.date_last_auth);
    this.date_naissance = DateService.string2Moment(this.date_naissance);
    this.date_creation_text = DateService.convertDate2Text(this.date_naissance);
    this.avatar_url = this._generateAvatarUrl(this);
    this.initDropDown('cotisation');
  }

  initBeforeCreating() {
    super.initBeforeCreating();

    this.nom = null;
    this.prenom = null;
    this.email = null;
    this.username = null;
    this.pwd = null;
    this.telephone = null;
    this.date_naissance = null;
    this.actif = false;
    this.publique = true;
    this.adresse = null;
    this.avatar = null;
    this.avatar_uri = null;
    this.avatar_url = null;
    this.code_postal = null;
    this.ville = null;
    this.date_last_auth = null;
    this.licence_number = 'TODO';
    this.licence_etat = null;
    this.cotisation_id = null;
    this.cotisation_name = null;
    this.saison_courante = null;
    this.has_tresorerie_in_saison_courante = null;

    this._generatePwd();
  }

  showTooltipInformation(): string {
    return this.prenom + ' ' + this.nom;
  }

  desactiver() {
    this.publique = false;
    this.actif = false;
  }

  activer() {
    this.publique = true;
    this.actif = true;
  }

  /**
   * override default name in drop down list.
   */
  protected getLabelDropDown() {
    return this.getPrenomNom();
  }

  private _generateAvatarUrl(dto) {
    return `${AbstractRootService.BASE_URL}${dto.avatar_uri}`;
  }
  private _generatePwd() {
    this.pwd = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 12; i++) {
      this.pwd += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
  }
}
