<div *ngIf="dropDownStream$ | async as dropdowns">
  <mat-form-field [formGroup]="parentForm" class="mb-3" *ngIf="parentForm; else sansParentForm">
    <mat-label>{{ label }}</mat-label>
    <mat-select
      [formControlName]="field"
      class="form-control"
      [compareWith]="compareObjects"
      [required]="required"
      (selectionChange)="selectionChange(entity, field, $event)"
      [multiple]="multiple"
    >
      <mat-option *ngFor="let value of dropdowns" [value]="value">
        {{ value.value }}
      </mat-option>
    </mat-select>
    <button
      *ngIf="!required && !readonly"
      class="btn btn-outline-danger resetbtn"
      type="button"
      matSuffix
      (click)="clearChange(entity, field)"
    >
      <i class="fas fa-times"></i>
    </button>

    <app-generic-field-error [field]="parentForm.get(field)"></app-generic-field-error>
  </mat-form-field>

  <!-- 
  SANS PARENT FORM
-->
  <ng-template #sansParentForm>
    <mat-form-field class="mb-3">
      <mat-label>{{ label }}</mat-label>

      <mat-select
        class="form-control"
        [compareWith]="compareObjects"
        [required]="required"
        (selectionChange)="selectionChange(entity, field, $event)"
      >
        <mat-option *ngFor="let value of dropdowns" [value]="value">
          {{ value.value }}
        </mat-option>
      </mat-select>
      <button class="btn btn-outline-danger resetbtn" type="button" matSuffix (click)="clearChange(entity, field)">
        <i class="fas fa-times"></i>
      </button>

      <app-generic-field-error [field]="field"></app-generic-field-error>
    </mat-form-field>
  </ng-template>
</div>
