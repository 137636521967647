import { Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { first, map, take, tap } from 'rxjs/operators';
import { EntityCollectionServiceBase } from '@ngrx/data';
import { VgApiService } from '@videogular/ngx-videogular/core';

import { DropDownService } from '@shared/api/dropdown/dropdown.service';
import { DateService } from '@shared/api/date.service';
import { IDropdown, IDropdownModelLike } from '@shared/modeles/dropdown/dropdown.model';
import { CrudConfigModel } from '@shared/modeles/crud/crud-config.model';

import { UploadImage } from '../photo-field/upload-image.component';

@Component({
  selector: 'app-generic-field',
  templateUrl: './generic-field.component.html',
  styleUrls: ['./generic-field.component.scss'],
})
export class GenericFieldComponent {
  @Input() parentForm: FormGroup;
  @Input() entity: any;
  @Input() col: CrudConfigModel;
  @Input() checkRole: boolean;
  @Input() uploadImage: UploadImage;
  @Input() change;
  
  labelPosition = 'before';
  
  // ////////////////////// VIDEO ////////////////// //
  video_api: VgApiService;
  constructor() {
  }

  ngOnInit(): void {
    this.parentForm.registerControl(
      this.col.field,
      new FormControl(
        {
          value: this.entity ? this.entity[this.col.field] : this.col.value,
          disabled: this.col.user_edit ? this.col.form_readonly : true,
        },
        this.col.validators
      )
    );
  }



  get today() {
    return DateService.nowAsMoment();
  }

  onViewerReady(video_api: VgApiService) {
    this.video_api = video_api;
    this.video_api.getDefaultMedia().subscriptions.ended.subscribe(() => {
      // Set the video to the beginning
      this.video_api.getDefaultMedia().currentTime = 0;
    });
  }

  selectionChange(entity, field, event) {
    entity[field] = event.value;
    entity[field + '_id'] = event.value.id;
    entity[field + '_name'] = event.value.value;
  }
  clearChange(entity, field) {
    // entity[field] = new Dropdown(null, null);
    this.parentForm.get(field).reset();
    entity[field] = null;
    entity[field + '_id'] = null;
    entity[field + '_name'] = null;
  }
  
  selectionValue(entity, field, event) {
    entity[field] = event.value;
  }

  
}
