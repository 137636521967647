import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';

export interface IBuilderLike<T> {
  convertJsonToModele(response: {}): T;
  convertModeleToForm(entity: T): {};
  getNewInstance(preset?: boolean): T;
  convertToInstance(json): T;
  getEntityClassName(): string;
  getEntity(): { name: string };
  initEntity(entity: T);
}

interface Class<T> {
  new (...args: any[]): T;
}
function newInstance<T>(TheClass: Class<T>, ...args: any[]): T {
  return new TheClass(...args);
}

export abstract class AbstractRtlqBuilder<T> implements IBuilderLike<T> {
  private _entityClassname: string;

  constructor() {
    this._entityClassname = this.getEntity().name;
  }
  abstract getEntity(): Class<T>;

  public getEntityClassName(): string {
    return this._entityClassname;
  }

  public getNewInstance(preset = false): T {
    const entity: T = newInstance(this.getEntity());
    if (preset) {
      this.initEntity(entity);
    }
    return entity;
  }
  public initEntity(entity: T) {}

  public convertJsonToModele(json): T {
    const inputJson = {...json};
    let instanceUpdated = Object.assign( this.getNewInstance(), inputJson);
    instanceUpdated = this.customConvertResponseToModele(inputJson, instanceUpdated);

    return instanceUpdated;
  }

  protected customConvertResponseToModele(json: {}, object: T): T {
    return object;
  }

  public convertModeleToForm(entity: T): {} {
    let json = JSON.parse(JSON.stringify(entity));
    json = this.customConvertModeleToForm(json);
    return json;
  }

  protected customConvertModeleToForm(json: {}): {} {
    return json;
  }

  /**
   * @deprecated
   */
  public convertToInstance(json): T {
    console.error('==DEPRECATED==')
    if (this.getEntityClassName() === json.constructor.name) {
      return json;
    } else {
      return this.convertJsonToModele(json);
    }
  }
}

export abstract class AbstractCrudBuilder<T extends ICrudModelLike> extends AbstractRtlqBuilder<T> {
  getNewInstance(preset = false): T {
    const entity: T = super.getNewInstance(preset);
    entity.consolidation();
    return entity;
  }

  public convertJsonToModele(json): T {
    const instanceUpdated = super.convertJsonToModele(json);
    instanceUpdated.consolidation();
    return instanceUpdated;
  }

  public convertModeleToForm(entity: T): {} {
    entity.consolidation();
    const instanceUpdated = super.convertModeleToForm(entity);
    return instanceUpdated;
  }

  public convertJsonToForm(json: {}) : {} {
    const modele = this.convertJsonToModele(json);
    return this.convertModeleToForm(modele);
  }

  /**
   * @deprecated
   */
  public convertToInstance(json): T {
    if (this.getEntityClassName() === json.constructor.name) {
      return json;
    } else {
      const instance = super.convertToInstance(json);
      try {
        instance.consolidation();
      } catch (e) {
        console.log(e);
      }
      return instance;
    }
  }
}
