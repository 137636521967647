import { Injectable } from '@angular/core';

import { RessourceService } from '@shared/api/config/ressource.service';
import { ICrudConfigModel } from '@shared/modeles/crud/crud-config.model';
import { IRouteItemModelLike } from '@shared/modeles/menu/route-item.model';
import { AbstractRtlqConfig } from '@shared/resource/rtlq.config';
import { app_config } from 'app/app.config';



@Injectable({
  providedIn: 'root',
})
export class PhotoDirectoryConfig extends AbstractRtlqConfig {
  static MENU: IRouteItemModelLike = RessourceService.MENUS.URL_PHOTO_DIRECTORY;
  static REST_URL = 'association/photo_directories';
  static MODULE = PhotoDirectoryConfig.MENU.module;
  static DOMAINE = PhotoDirectoryConfig.MENU.uri;
  static STORE = app_config.STORE.PhotoDirectoryConfig;

  get module(): string {
    return PhotoDirectoryConfig.MODULE;
  }

  get domaine(): string {
    return PhotoDirectoryConfig.DOMAINE;
  }

  get id(): string {
    return PhotoDirectoryConfig.MENU.id;
  }
  getCols(): Array<ICrudConfigModel> {
    const config: Array<ICrudConfigModel> = [];

    config.push(this.createCrudConfigModel('id').withType(RessourceService.CONST.TYPE_NUMBER).withoutFormOrTable());
    config.push(this.createCrudConfigModel('nom').isFormRequired());
    config.push(this.createCrudConfigModel('nb_photos').withType(RessourceService.CONST.TYPE_NUMBER).withOnlyTable());
    config.push(
      this.createCrudConfigModel('preview_url').withType(RessourceService.CONST.TYPE_URL_IMAGE).withOnlyTable()
    );
    config.push(this.createCrudConfigModel('actif').withType(RessourceService.CONST.TYPE_BOOLEAN));

    return config;
  }
}
