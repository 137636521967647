import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';
import { AbstractCrudModel } from '@shared/modeles/crud/absract-crud.model';

export interface IDriveModelLike extends ICrudModelLike {
  name: string;
  size: string;
  type: string;
  converting: boolean;
  formData: FormData;
  formDataName: string;

  isVideo(): boolean;
}

export class DriveModel extends AbstractCrudModel implements IDriveModelLike {
  static VIDEO_REGEXP = new RegExp('^video\/(.*)$');

  constructor(
    public id = null,
    public name = null,
    public size = null,
    public type = null,
    public converting = null,
    public formData = null,
    public formDataName = null
  ) {
    super(id);
    this.formDataName = 'source';
  }

  isVideo(): boolean {
    const isVideo = DriveModel.VIDEO_REGEXP.test(this.type);
    return isVideo;
  }
}
