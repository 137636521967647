import { Injectable } from '@angular/core';
import { KungFuNiveauModel, IKungFuNiveauModelLike } from '@kungfu/models/kungfu-niveau.model';
import { AbstractCrudBuilder, IBuilderLike } from '@shared/builder/abstract-rtlq.build';

export interface IKungFuNiveauBuilderLike extends IBuilderLike<IKungFuNiveauModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class KungFuNiveauBuilder extends AbstractCrudBuilder<IKungFuNiveauModelLike> implements IKungFuNiveauBuilderLike {
  getEntity() {
    return KungFuNiveauModel;
  }
}
