import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Observer, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { MessageService } from '@shared/api/notif/message.service';
import { NotificationService } from '@shared/api/notif/notifier.service';
import { RessourceService } from '@shared/api/config/ressource.service';
import { RtlqComponent } from '@shared/components/rtlq.component';
import { TranslateService } from '@shared/api/config/translate.service';
import { IMenuItemModel } from '@shared/modeles/menu/i.menu-item.model';
import { PageConfigModel } from '@shared/modeles/config/page-config.model';

import { AuthenticateFacade } from '@authenticate/authenticate.facade';
import { IRouteItemModelLike } from '@shared/modeles/menu/route-item.model';
import { AutoUnsubDecorator } from '@shared/components/decorator/auto-unsub.decorator';

import { AppState } from '@app_store/app.reducer';
import { selectCurrentUserState } from '@user/store/current-user/current-user.selectors';
import { IUserModelLike } from '@user/models/user.model';
import { selectAuthState } from '@authenticate/store/auth.selectors';

@AutoUnsubDecorator()
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends RtlqComponent {
  @ViewChild('navbarToggler') navbarToggler: ElementRef;

  displayMenuBar;
  enableMenuBar;
  items: IMenuItemModel[];
  message: string;

  routerHome = [];
  public userStream$: Observable<IUserModelLike>;
  private _authSub: Subscription;

  constructor(
    private store: Store<AppState>,
    protected messageService: MessageService,
    protected authenticationFacade: AuthenticateFacade,
    protected modalService: NgbModal,
    protected notificationService: NotificationService
  ) {
    super(modalService, notificationService);
    this.routerHome = PageConfigModel.getUri(RessourceService.MENU.URL_HOME);
    this.close();
    this.disable();
  }

  ngOnInit() {
    this._authSub = this.store.select(selectAuthState).subscribe(
      (authUser) => {
        if (authUser.auth) {
          this.loadMenu(authUser.auth.roles);
          this.enable();
        } else {
          this.disable();
        }
      },
      (error) => {
        this.disable();
      }
    );
    this.userStream$ = this.store.select(selectCurrentUserState).pipe(
      map((userState) => {
        return userState.user;
      })
    );
  }

  navBarTogglerIsVisible() {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }

  collapseNav() {
    if (this.navBarTogglerIsVisible()) {
      this.navbarToggler.nativeElement.click();
    }
  }

  checkAcces(rolesUser, rolesMenu): boolean {
    try {
      const etat = rolesUser.filter((value) => {
        if (-1 !== rolesMenu.indexOf(value)) {
          return true;
        } else {
          return false;
        }
      });
      return etat.length !== 0;
    } catch (e) {
      return false;
    }
  }

  creationMenu(
    roles: string[],
    icon: string,
    menuConfig: IRouteItemModelLike
  ): { label: string; icon: string; routerLink: string[] } {
    if (this.checkAcces(roles, menuConfig.roles)) {
      const routerLink = PageConfigModel.getUri(menuConfig);
      const translate = TranslateService.getTranslate(menuConfig.id, 'menu');

      return {
        label: translate,
        icon: icon,
        routerLink: routerLink,
      };
    }
    return null;
  }

  cleanMenu() {
    this.items = [];
  }

  addItem(menu, item) {
    if (item !== null) {
      menu.items.push(item);
    }
  }
  addMenu(menu) {
    if (menu.items.length !== 0) {
      this.items.push(menu);
    }
  }

  createNewMenu(label) {
    return { label: label, items: [] };
  }

  loadMenu(roles) {
    this.cleanMenu();
    const menus = new Map();
    // creation des menus
    Object.keys(RessourceService.CATEGORIES).forEach((element) => {
      const menu = this.createNewMenu(RessourceService.CATEGORIES[element]);
      menus.set(menu.label, menu);
    });

    // creation des sous- menus
    Object.keys(RessourceService.MENUS).forEach((element) => {
      const menu = menus.get(RessourceService.MENUS[element].category);
      if (!menu) {
        console.error('Category "' + RessourceService.MENUS[element].category + '" not found.');
      } else {
        this.addItem(menu, this.creationMenu(roles, 'fas fa-chevron-circle-right', RessourceService.MENUS[element]));
      }
    });

    // Ajout dans items si il y a des menus à ajouter.
    menus.forEach((element) => {
      this.addMenu(element);
    });
  }
  openOrClose() {
    if (this.displayMenuBar) {
      this.close();
    } else {
      this.open();
    }
  }

  close() {
    this.displayMenuBar = false;
  }
  open() {
    this.displayMenuBar = true;
  }

  disable() {
    this.enableMenuBar = false;
  }
  enable() {
    this.enableMenuBar = true;
  }

  ngOnDestroy() {}

  logout() {
    this.showInfoMessage('Déconnexion en cours');
    this.authenticationFacade.logout();
  }
}
