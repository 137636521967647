import { Injectable } from '@angular/core';

import { RessourceService } from '@shared/api/config/ressource.service';
import { ICrudConfigModel } from '@shared/modeles/crud/crud-config.model';
import { IRouteItemModelLike } from '@shared/modeles/menu/route-item.model';
import { AbstractRtlqConfig } from '@shared/resource/rtlq.config';
import { app_config } from 'app/app.config';

@Injectable({
  providedIn: 'root',
})
export class TresorerieCategorieConfig extends AbstractRtlqConfig {
  static MENU: IRouteItemModelLike = RessourceService.MENUS.URL_TRESORERIE_CATEGORIE;
  static REST_URL = 'tresorerie/categories';
  static MODULE = TresorerieCategorieConfig.MENU.module;
  static DOMAINE = TresorerieCategorieConfig.MENU.uri;
  static STORE = app_config.STORE.TresorerieCategorieConfig;

  get module(): string {
    return TresorerieCategorieConfig.MODULE;
  }

  get domaine(): string {
    return TresorerieCategorieConfig.DOMAINE;
  }

  get id(): string {
    return TresorerieCategorieConfig.MENU.id;
  }
  getCols(): Array<ICrudConfigModel> {
    const config: Array<ICrudConfigModel> = [];

    config.push(this.createCrudConfigModel('id').withType(RessourceService.CONST.TYPE_NUMBER).withOnlyTable());
    config.push(this.createCrudConfigModel('nom').withClazzLarge().isFormRequired());

    return config;
  }
}
