import { Component, Input } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { HttpClient } from '@angular/common/http';

import { IRtlqComponent, RtlqComponent } from '@shared/components/rtlq.component';
import { CrudConfigModel, ICrudConfigModel } from '@shared/modeles/crud/crud-config.model';
import { NotificationService } from '@shared/api/notif/notifier.service';
import { ICrudService } from '@shared/api/crud/i-crud.service';
import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';
import { RtlqModalComponent } from '@shared/components/rtlq-modal.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
})
export class ViewerComponent<T extends ICrudModelLike> extends RtlqModalComponent<T> {
  public video_title: string; /** Link text */
  public video_url: string; /** Name used in form which will be sent in HTTP request. */
  public video_type: string;
  public parentForm: FormGroup;

  col: ICrudConfigModel;
  constructor(
    protected formBuilder: FormBuilder,
    protected modalService: NgbModal,
    protected notificationService: NotificationService
  ) {
    super(modalService, notificationService);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    //creation du formulaire parent
    this.parentForm = this.formBuilder.group({});

    this.col = new CrudConfigModel('video', 'default').withVideoValue(
      this.video_title,
      this.video_url,
      this.video_type
    );
  }

  customSetup(args: {
    formBuilder: FormBuilder;
    abstractController: IRtlqComponent;
    service: ICrudService<T>;
    modalService: NgbModal;
    notificationService: NotificationService;
    title: string;
    video_title: string;
    video_url: string;
    video_type: string;
  }) {
    this.video_title = args.video_title;
    this.video_url = args.video_url;
    this.video_type = args.video_type;
  }

  close() {
    this.modalService.dismissAll();
  }
}
