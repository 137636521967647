import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EntityCollectionService } from '@ngrx/data';
import { Observable, Subscription } from 'rxjs';

import { CrudConfigModel } from '@shared/modeles/crud/crud-config.model';
import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';

import { IEntitiesActionsBuilderLike } from '@association/_store/rtlq.actions';

import { AbstractModalEditorComponent } from './abstract-modal-editor.component';

@Component({
  selector: 'app-edit-modal-editor',
  templateUrl: './modal-editor.component.html',
  styleUrls: ['./modal-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditModalEditorComponent<
  T extends ICrudModelLike
> extends AbstractModalEditorComponent<T> {
  private _saveSub: Subscription;

  public onSaveAction(): void {
    let actionObservable: Observable<any>;
    if (this.entityForm.id != null) {
      actionObservable = this.entityService.update(this.entityForm);
    } else {
      actionObservable = this.entityService.add(this.entityForm);
    }

    this._saveSub = actionObservable.subscribe(
      (newEntity) => {
        this.runOnHide();
      },
      (error) => {
        this.errorMessage = error.message;
      }
    );
  }
}
