import { Component, OnInit, Input } from '@angular/core';
import { UploadImage } from './upload-image.component';

@Component({
  selector: 'app-photo-field',
  templateUrl: './photo-field.component.html',
  styleUrls: ['./photo-field.component.scss'],
})
export class PhotoFieldComponent implements OnInit {

  @Input() parentForm:any;
  @Input() propertyToShow: string;
  @Input() propertyToSet: string;
  @Input() entity: any;
  @Input() uploadImage: UploadImage;

  ngOnInit() {
    if (this.uploadImage == null) {
      this.uploadImage = new UploadImage();
    }
    this.uploadImage.reset();
  }

  getSrc() {
    return this.entity[this.propertyToShow];
  }

  validate() {
    this.entity[this.propertyToSet] = this.uploadImage.getCroppedImage();
    if (this.parentForm) {
      this.parentForm.value[this.propertyToSet] = this.uploadImage.getCroppedImage();
    }
    this.uploadImage.select();
  }

  cancel() {
    this.uploadImage.reset();
  }
}
