import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { ITresorerieEtatModelLike } from '@tresorerie/models/tresorerie-etat.model';
import { TresorerieEtatConfig } from '@tresorerie/resource/tresorerie-etat.config';

@Injectable({ providedIn: 'root' })
export class TresorerieEtatEntityService extends EntityCollectionServiceBase<ITresorerieEtatModelLike> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(TresorerieEtatConfig.STORE, serviceElementsFactory);
  }
}
