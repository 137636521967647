import { Injectable } from '@angular/core';
import { TresorerieCategorieModel, ITresorerieCategorieModelLike } from '@tresorerie/models/tresorerie-categorie.model';
import { AbstractCrudBuilder, IBuilderLike } from '@shared/builder/abstract-rtlq.build';

export interface ITresorerieCategorieBuilderLike extends IBuilderLike<ITresorerieCategorieModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class TresorerieCategorieBuilder
  extends AbstractCrudBuilder<ITresorerieCategorieModelLike>
  implements ITresorerieCategorieBuilderLike {
    getEntity() {
      return TresorerieCategorieModel;
  }
}
