import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-generic-field-error',
  templateUrl: './generic-field-error.component.html',
  styleUrls: ['./generic-field-error.component.scss'],
})
export class GenericFieldErrorComponent implements OnInit {
  @Input() field: FormControl;
  constructor() {}

  ngOnInit(): void {}
}
