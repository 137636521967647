import { AbstractCrudDropDownModel } from '@shared/modeles/crud/absract-crud.model';
import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';
import { IDropdownModelLike } from '@shared/modeles/dropdown/dropdown.model';

export interface ITresorerieCategorieModelLike extends IDropdownModelLike, ICrudModelLike {
  nom: string;
}

export class TresorerieCategorieModel extends AbstractCrudDropDownModel implements ITresorerieCategorieModelLike {
  constructor(public id = null, public nom = null) {
    super(id);
  }
}
