import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { IKungFuStyleModelLike } from '@kungfu/models/kungfu-style.model';

import { KungFuStyleConfig } from '@kungfu/resource/kungfu-style.config';

@Injectable({ providedIn: 'root' })
export class KungFuStyleEntityService extends EntityCollectionServiceBase<IKungFuStyleModelLike> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(KungFuStyleConfig.STORE, serviceElementsFactory);
  }
}
