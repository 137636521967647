import { ActionReducer, ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import {routerReducer} from '@ngrx/router-store';

import * as fromAuth from '@authenticate/store/auth.reducer';
import * as fromUser from '@user/store/current-user/current-user.reducer';
import * as fromSaison from '@saison/store/current-saison/current-saison.reducer';
import { environment } from '@env';

export interface AppState {
  auth: fromAuth.AuthState;
  currentUser: fromUser.CurrentUserState;
  currentSaison: fromSaison.CurrentSaisonState;
}

export const appReducer: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  currentUser: fromUser.reducer,
  currentSaison: fromSaison.reducer
};


export function logger(reducer:ActionReducer<any>)
    : ActionReducer<any> {
    return (state, action) => {
        return reducer(state, action);
    }

}

export const metaReducers: MetaReducer<AppState>[] =
    !environment.production ? [logger] : [];