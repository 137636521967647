import { HttpErrorResponse, HttpRequest } from '@angular/common/http';

export class HttpErrorResponseModel extends HttpErrorResponse {
  private _payload_body: any;

  constructor(error: HttpErrorResponse, request: HttpRequest<any>) {
    super(error);
    this._payload_body = JSON.parse(request.body);
  }

  public getPayloadBody(): any {
    return this._payload_body;
  }
}
