import { Component, Input, OnInit, Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { EntityCollectionServiceBase } from "@ngrx/data";
import { Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { DropDownService } from "@shared/api/dropdown/dropdown.service";
import { IDropdown, IDropdownModelLike } from "@shared/modeles/dropdown/dropdown.model";

@Component({
  selector: "app-suggestion",
  templateUrl: "./suggestion.component.html",
  styleUrls: ["./suggestion.component.scss"],
})
export class SuggestionComponent implements OnInit {
  @Input() parentForm: any;

  @Input() stream: Observable<any>;
  @Input() entity;
  @Input() label;
  @Input() field;
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Input() entityServiceName: string;

  @Output() select: EventEmitter<IDropdownModelLike> = new EventEmitter();
  @Output() clear: EventEmitter<void> = new EventEmitter();

  dropDownStream$: Observable<IDropdown[]>;
  suggestions: IDropdownModelLike[]; // list of suggestion pick up from dropDownStream filter by input field value

  constructor(protected dropDownService: DropDownService) {
    this.suggestions = [];
  }

  ngOnInit(): void {
    this.dropDownStream$ = this.getDropDownStream$();
  }

  /**
   * Method used when the autocomplete object has a new element selected
   * @param event
   * @param col
   * @param record
   */
  onSelectionChange(event, userId, dropdowns) {
    if (event.source.selected && this.select) {
      const ddSelected = dropdowns.find((dd) => dd.id === userId);
      this.select.emit(ddSelected);
    }
  }

  applyFilter(event, records) {
    this.suggestions = [];
    for (let i = 0; i < records.length; i++) {
      const record = records[i];
      if (record.value.toLowerCase().search(event.target.value.toLowerCase()) !== -1) {
        this.suggestions.push(record);
      }
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    if (!o1 || !o2) {
      return false;
    }
    return o1.id === o2.id;
  }

  public onClearChange() {
    this.suggestions = [];
    this.parentForm.get(this.field).reset();
    this.clear.emit();
  }

  /**
   * function enables to extract the stream of the facade required by the configuration.
   */
  public getDropDownStream$(): Observable<IDropdown[]> {
    if (this.entityServiceName) {
      const entityService: EntityCollectionServiceBase<IDropdownModelLike> = this.dropDownService.get(
        this.entityServiceName
      );
      if (entityService) {
        return entityService.entities$.pipe(
          map((entities) => {
            const dd: IDropdown[] = [];
            return entities.map((elt) => {
              try {
                const dropdownValue = (<IDropdownModelLike>elt).convertToDropDown();
                return dropdownValue;
              } catch {
                console.error("Impossible de convertir en DropDown");
              }
            });
          }),
          first()
        );
      } else {
        console.error("Problème de configuration du service " + this.entityServiceName);
        return null;
      }
    } else {
      return null;
    }
  }

  selectionChange(entity, field, event) {
    entity[field] = event.value;
    entity[field + "_id"] = event.value.id;
    entity[field + "_name"] = event.value.value;
  }
}
