import { Injectable } from '@angular/core';
import { SessionUserModel, ISessionUserModelLike } from '@authenticate/models/session-user.model';
import { AbstractRtlqBuilder } from '@shared/builder/abstract-rtlq.build';

@Injectable({
  providedIn: 'root',
})
export class SessionUserBuilder extends AbstractRtlqBuilder<ISessionUserModelLike> {
  public getEntity() {
    return SessionUserModel;
  }
}
