import { Injectable } from '@angular/core';

import { RessourceService } from '@shared/api/config/ressource.service';
import { ICrudConfigModel } from '@shared/modeles/crud/crud-config.model';
import { IRouteItemModelLike } from '@shared/modeles/menu/route-item.model';
import { AbstractRtlqConfig } from '@shared/resource/rtlq.config';

import { SaisonEntityService } from '@saison/api/saison/saison.entity-service';
import { DropDownService } from '@shared/api/dropdown/dropdown.service';
import { TresorerieCategorieEntityService } from '@tresorerie/api/categorie/tresorerie-categorie.entity-service';
import { app_config } from 'app/app.config';



@Injectable({
  providedIn: 'root',
})
export class CotisationConfig extends AbstractRtlqConfig {
  static MENU: IRouteItemModelLike = RessourceService.MENUS.URL_COTISATION;
  static REST_URL = 'cotisations';
  static MODULE = CotisationConfig.MENU.module;
  static DOMAINE = CotisationConfig.MENU.uri;
  static STORE = app_config.STORE.CotisationConfig;

  get module(): string {
    return CotisationConfig.MODULE;
  }

  get domaine(): string {
    return CotisationConfig.DOMAINE;
  }

  get id(): string {
    return CotisationConfig.MENU.id;
  }
  getCols(): Array<ICrudConfigModel> {
    const config: Array<ICrudConfigModel> = [];

    config.push(this.createCrudConfigModel('id').withType(RessourceService.CONST.TYPE_NUMBER).withoutFormOrTable());
    config.push(
      this.createCrudConfigModel('saison')
        .withOnlyForm()
        .isFormRequired()
        .withDropDown(DropDownService.DROPDOWNS.SAISON)
    );
    config.push(this.createCrudConfigModel('saison_name').withOnlyTable());

    config.push(
      this.createCrudConfigModel('categorie')
        .withOnlyForm()
        .isFormRequired()
        .withDropDown(DropDownService.DROPDOWNS.TRESORERIE_CATEGORIE)
    );
    config.push(this.createCrudConfigModel('categorie_name').withOnlyTable());

    config.push(
      this.createCrudConfigModel('description')
        .withType(RessourceService.CONST.TYPE_TEXTAREA)
        .isFormRequired()
        .withClazz('col-large text-left')
    );

    // config.push(
    //   this.createCrudConfigModel('type')
    //     .withOnlyForm()
    //     .isFormRequired()
    //     .withFormValues(['Adulte/Etudiant (Annuel)', 'Adulte/Etudiant (Trimestre)', 'Licence', 'Enfant/Mineur'])
    // );
    config.push(this.createCrudConfigModel('cotisation').isFormRequired());
    config.push(this.createCrudConfigModel('repartition_cheque').withOnlyForm().isFormRequired());
    config.push(
      this.createCrudConfigModel('nb_cheque')
        .withOnlyForm()
        .withType(RessourceService.CONST.TYPE_NUMBER)
        .isFormRequired()
    );
    config.push(this.createCrudConfigModel('actif').withType(RessourceService.CONST.TYPE_BOOLEAN));

    return config;
  }
}
