import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RessourceService } from '@shared/api/config/ressource.service';
import { PageConfigModel } from '@shared/modeles/config/page-config.model';
import { UserService } from '@user/api/user/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  model: any = {};
  loading = false;
  token = '';
  showHomeRouterLink = false;
  error = '';
  info = '';

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.token = params['token'];
    });
  }

  ngOnInit() {}

  changePassword(form: NgForm) {
    this.loading = true;
    this.info = '';
    this.error = '';
    this.showHomeRouterLink = false;

    this.userService.changePassword(this.token, form.value['password'], form.value['confirmation']).subscribe(
      (p) => {
        this._dealSuccess(p);
      },
      (error) => {
        this._dealError(error);
      }
    );
  }

  private _dealSuccess(p) {
    this.loading = false;
    this.info = 'Votre mot de passe a été changé !';
    this.showHomeRouterLink = true;
  }

  private _dealError(error) {
    this.loading = false;
    this.error = 'Votre demande a échoué.';
  }

  getLoginRoute() {
    return PageConfigModel.getUri(RessourceService.MENU.URL_LOGIN);
  }
}
