import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ICrudService } from '@shared/api/crud/i-crud.service';
import { AbstractRtlqService } from '@shared/api/crud/abstract-main.service';

import { ICotisationModelLike } from '@cotisation/models/cotisation.model';
import { CotisationBuilder } from '@cotisation/builder/cotisation.builder';
import { CotisationConfig } from '@cotisation/resource/cotisation.config';

export interface ICotisationServiceLike extends ICrudService<ICotisationModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class CotisationService
  extends AbstractRtlqService<ICotisationModelLike>
  implements ICotisationServiceLike {
  constructor(protected http: HttpClient, protected builder: CotisationBuilder) {
    super(http, builder, CotisationConfig.REST_URL);
  }
}
