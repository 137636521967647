import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { AutoUnsubDecorator } from '@shared/components/decorator/auto-unsub.decorator';

import * as fromAuth from '@authenticate/store/auth.reducer';
import { CredentialService } from './api/security/credential.service';
import { ISessionUserModelLike } from './models/session-user.model';
import { LogoutAction } from './store/auth.actions';
import { map, take } from 'rxjs/operators';
export interface IAuthenticateFacadeLike {
  userSession: ISessionUserModelLike;
  isRoleAuthorized(user: ISessionUserModelLike, roles: string[]): boolean;
  logout();
}
@Injectable({
  providedIn: 'root',
})
@AutoUnsubDecorator()
export class AuthenticateFacade implements IAuthenticateFacadeLike, OnDestroy {
  constructor(private credentialService: CredentialService, private store: Store<fromAuth.AuthState>) {
    this.store.pipe();
  }

  public logout() {
    this.store.dispatch(new LogoutAction());
  }

  /**
   * Method compare user roles with roles of the page provided.
   *
   * @params user : user connected trying to access a specific page.
   * @params roles : list of roles of the page.
   */
  public isRoleAuthorized(user: ISessionUserModelLike, roles: string[]): boolean {
    try {
      let allowed = false;
      if (user.roles.length > 0) {
        if (roles) {
          user.roles.forEach((element) => {
            if (roles.indexOf(element) !== -1) {
              allowed = true;
            }
            if (allowed) {
              return true;
            }
          });
        }
      }
      return allowed;
    } catch (e) {
      return false;
    }
  }

  public get userSession(): ISessionUserModelLike {
    let user: ISessionUserModelLike = null;
    this.store.pipe(
      take(1),
      map((authUser) => (user = authUser.auth))
    );
    return user;
  }

  public ngOnDestroy(): void {}
}
