import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable } from 'rxjs';

import { ICrudService } from '@shared/api/crud/i-crud.service';
import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';

export class RtlqDataService<T extends ICrudModelLike> extends DefaultDataService<T> {
  constructor(
    protected nameService: string,
    protected apiService: ICrudService<T>,
    protected http: HttpClient,
    protected httpUrlGenerator: HttpUrlGenerator
  ) {
    super(nameService, http, httpUrlGenerator);
  }

  getAll(): Observable<T[]> {
    return this.apiService.getAll();
  }

  add(entity: T): Observable<T> {
    return this.apiService.add(entity);
  }

  delete(key: number | string): Observable<number | string> {
    return this.apiService.delete(key);
  }
  getById(key: number | string): Observable<T> {
    return this.apiService.get(key);
  }
  update(update: Update<T>): Observable<T> {
    return this.apiService.update(<T> update.changes);
  }
  getWithQuery(param:any) {
    return this.apiService.getWithQuery(param);
  }
}
