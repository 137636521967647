import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuardService } from '@authenticate/api/security/route-guard.service';
import { RessourceService } from '@shared/api/config/ressource.service';

// Route config let's you map routes to components
const routes: Routes = [
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
