<!-- new syntax for ng-if -->
<section class="container" id="resetPassword">
  <div class="resetPasswordWindow">
    <span>Mot de passe oublié ?</span>

    <div class="logo"></div>
    <form (ngSubmit)="f.valid && resetPassword(f)" #f="ngForm">
      <div span class="mb-3" [ngClass]="{ 'has-error': email.touched && !email.valid }">
        <label for="email">Email</label>
        <input type="text" class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel" required />
        <div *ngIf="email.touched && !email.valid" class="help-block">Email is required</div>
      </div>

      <div class="mb-3 text-center">
        <app-button
          type="submit"
          [(disabled)]="!f.valid"
          [action_status]="loading"
          action_name="Réinitialiser mon mot de passe"
          action_name_inprogress="Réinitialisation"
          action_icone="fa-sign-out"
        ></app-button>
      </div>
    </form>

    <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
    <div *ngIf="info" class="alert alert-success">{{ info }}</div>
    <div class="mb-3 text-right">
      <a [routerLink]="getLoginRoute()">s'authentifier</a>
    </div>
  </div>
</section>
