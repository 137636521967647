import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';

import { RtlqDataService } from '@shared/api/ngrx/rtlq.data-service';

import { IDriveModelLike } from '@drive/models/drive.model';
import { DriveConfig } from '@drive/resource/drive.config';
import { DriveService } from './drive.service';

@Injectable({ providedIn: 'root' })
export class DriveDataService extends RtlqDataService<IDriveModelLike> {
  constructor(
    protected apiService: DriveService,
    protected http: HttpClient,
    protected httpUrlGenerator: HttpUrlGenerator
  ) {
    super(DriveConfig.STORE, apiService, http, httpUrlGenerator);
  }
}
