<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable modal-full" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="Title">{{ title }}</h5>

      <button type="button" class="btn-close" (click)="close()" aria-label="Fermer">
      </button>
    </div>

    <form [formGroup]="parentForm" (ngSubmit)="parentForm.valid && onSave()">
      <div class="modal-body">
        <app-loading [show]="isLoading" level="info" message="Traitement en cours"></app-loading>
        <app-loading
          [show]="errorMessage"
          level="warning"
          [message]="errorMessage"
          [withoutProgressBar]="true"
        ></app-loading>
        <app-loading [errors$]="errors$" level="warning" [withoutProgressBar]="true"></app-loading>

        <div class="ui-grid ui-grid-responsive ui-fluid" *ngIf="entityForm">
          <div class="ui-grid-row" *ngFor="let col of usedColsForm">
            <app-generic-field
              [parentForm]="parentForm"
              [entity]="entityForm"
              [col]="col"
              [suggestion]="suggestion"
              [uploadImage]="uploadImage"
              style="width: 100%"
            >
            </app-generic-field>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <app-button
          *ngIf="showSave"
          type="submit"
          [disabled]="!parentForm.valid"
          [action_status]="isLoading"
          action_name="Enregistrer"
          action_name_inprogress="Enregistrement"
          action_icone="fa-check-circle"
        ></app-button>

        <button *ngIf="showCancel" type="button" (click)="runOnHide()" class="btn btn-secondary">
          <i class="far fa-times-circle">Fermer</i>
        </button>
      </div>
    </form>
  </div>
</div>
