<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable modal-full" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="Title">{{ title }}</h5>
      <button type="button" class="btn-close" (click)="close()" aria-label="Fermer">
      </button>
    </div>
    <div class="modal-body">
      <app-loading [show]="infoMessage" level="info" [message]="infoMessage"></app-loading>
      <app-loading
        [show]="errorMessage"
        [message]="errorMessage"
        [withoutProgressBar]="true"
        level="warning"
      ></app-loading>

      <div class="mb-3 full-width text-center">
        <button mat-stroked-button color="warn" class="btn btn-outline-primary" (click)="doUpload()">
          <mat-icon>file_upload</mat-icon>
          {{ text }}
        </button>
        <br />
        <br />
        <mat-divider></mat-divider>
        <br />
        <ul>
          <div *ngIf="hasError && files.length > 0" class="red">
            Des fichiers sont marqués "en erreur", merci de réduire la taille de vos videos.<br />
            Vous pouvez utiliser le site
            <a href="https://www.any-video-converter.com/fr/any-video-converter-free.php" target="_blank"
              >any-video-converter-free</a
            >
            pour les convertir.
          </div>
          <li *ngFor="let file of files" [@fadeInOut]="file.state">
            <div class="row text-left">
              <div class="col-8">
                <span id="file-label">{{ file.data.name }}</span>
              </div>
              <div class="col-4">
                <a title="Retry" (click)="retryFile(file)" *ngIf="file.canRetry">
                  <span class="material-icons green"> replay </span>
                </a>
                <a title="Cancel" (click)="cancelFile(file)" *ngIf="file.canCancel">
                  <span class="material-icons red"> cancel </span>
                </a>
                <a title="Error" *ngIf="file.error">
                  <i class="red">{{ file.error }}</i>
                </a>
              </div>
            </div>
            <div class="row" *ngIf="file.inProgress">
              <div class="col-5 text-right">Téléchargement</div>
              <div class="col-7 text-left">
                <mat-progress-bar *ngIf="file.progress < 100" style="margin-top: 8px" [value]="file.progress">
                </mat-progress-bar>
                <span *ngIf="file.progress === 100" class="material-icons green">check</span>
              </div>
            </div>
            <div class="row" *ngIf="file.inProgress && file.inConvert">
              <div class="col-5 text-right">Conversion de la video</div>
              <div class="col-7 text-left">
                <mat-progress-bar mode="indeterminate" style="margin-top: 8px"></mat-progress-bar>
              </div>
            </div>
            <mat-divider></mat-divider>
          </li>
        </ul>
        <input type="file" id="fileUpload" name="fileUpload" accept="{{ accept }}" style="display: none" />
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="close()" class="btn btn-secondary">
        <i class="far fa-times-circle">Fermer</i>
      </button>
    </div>
  </div>
</div>
