/** @format */

import { IStatsModelLike } from '@dashboard/models/stats.model';
import { createAction, props } from '@ngrx/store';
import { ITaoUserModelLike } from '@tao/models/tao-user.model';
import { ITresorerieModelLike } from '@tresorerie/models/tresorerie.model';
import { IUserModelLike } from '@user/models/user.model';

const actionName = '[CURRENT USER] ';

export const fetchingCurrentUserAction = createAction(actionName + 'FETCHING USER');
export const fetchedCurrentUserAction = createAction(actionName + 'USER FETCHED', props<{ user: IUserModelLike }>());
// STATISTIQUES //
export const fetchingStatsAction = createAction(actionName + 'FETCHING USER STATS');
export const fetchedStatsAction = createAction(actionName + 'USER STATS FETCHED', props<{ stats: IStatsModelLike }>());
// SAVE //
export const savingCurrentUserAction = createAction(actionName + 'SAVING', props<{ userToUpdate: IUserModelLike }>());
