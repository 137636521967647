import { Injectable } from '@angular/core';
import { AppState } from '@app_store/app.reducer';
import { CotisationModel, ICotisationModelLike } from '@cotisation/models/cotisation.model';
import { Store } from '@ngrx/store';
import { selectCurrentSaisonState } from '@saison/store/current-saison/current-saison.selectors';
import { AbstractCrudBuilder, IBuilderLike } from '@shared/builder/abstract-rtlq.build';
import { first, tap } from 'rxjs/operators';

export interface ICotisationBuilderLike extends IBuilderLike<ICotisationModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class CotisationBuilder extends AbstractCrudBuilder<ICotisationModelLike> implements ICotisationBuilderLike {

  constructor(protected store: Store<AppState>) {
    super();
  }

  getEntity() {
    return CotisationModel;
  }

  initEntity(entity: ICotisationModelLike) {
    if (this.store) {
      this.store
        .select(selectCurrentSaisonState)
        .pipe(
          first(),
          tap((currentSaisonState) => {
            entity.setSaisonWithSaisonCourante(currentSaisonState.saison);
          })
        )
        .subscribe();
    }
  }

  protected customConvertResponseToModele(json: {}, entity: ICotisationModelLike): ICotisationModelLike {
    entity.nom = json['saison_name'] + ' - ' + json['categorie_name'];

    return entity;
  }

}
