import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';

import { RtlqDataService } from '@shared/api/ngrx/rtlq.data-service';

import { ISaisonModelLike } from '@saison/models/saison.model';
import { SaisonConfig } from '@saison/resource/saison.config';
import { SaisonService } from './saison.service';

@Injectable({ providedIn: 'root' })
export class SaisonDataService extends RtlqDataService<ISaisonModelLike> {
  constructor(
    protected apiService: SaisonService,
    protected http: HttpClient,
    protected httpUrlGenerator: HttpUrlGenerator
  ) {
    super(SaisonConfig.STORE, apiService, http, httpUrlGenerator);
  }
}

