import { Component, Input, OnInit } from "@angular/core";
import { EntityCollectionServiceBase } from "@ngrx/data";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";

import { DropDownService } from "@shared/api/dropdown/dropdown.service";
import { IDropdown, IDropdownModelLike } from "@shared/modeles/dropdown/dropdown.model";
import { UserService } from "@user/api/user/user.service";

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
})
export class DropdownComponent implements OnInit {
 
  @Input() parentForm: any;

  @Input() stream: Observable<any>;
  @Input() entity;
  @Input() label;
  @Input() field;
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Input() multiple= false;

  @Input() entityServiceName: string;

  dropDownStream$: Observable<IDropdown[]>;

  constructor(protected userService: UserService,  protected dropDownService: DropDownService) {
  }

  ngOnInit(): void {
    this.dropDownStream$ = this.getDropDownStream$();
  }

  compareObjects(o1: any, o2: any): boolean {
    if (!o1 || !o2) {
      return false;
    }
    return o1.id === o2.id;
  }

  /**
   * function enables to extract the stream of the facade required by the configuration.
   */
  public getDropDownStream$(): Observable<IDropdown[]> {
    if (this.entityServiceName) {
      const entityService: EntityCollectionServiceBase<IDropdownModelLike> = this.dropDownService.get(
        this.entityServiceName
      );
      if (entityService) {
        return entityService.entities$.pipe(
          map((entities) => {
            const dd: IDropdown[] = [];
            entities.forEach((elt) => {
              try {
                dd.push((<IDropdownModelLike>elt).convertToDropDown());
              } catch {
                console.error("Impossible de convertir en DropDown");
              }
            });
            return dd;
          }),
          first()
        );
      } else {
        console.error("Problème de configuration du service " + this.entityServiceName);
        return null;
      }
    } else {
      return null;
    }
  }


  clearChange(entity, field) {
    // entity[field] = new Dropdown(null, null);
    this.parentForm.get(field).reset();
    entity[field] = null;
    entity[field + '_id'] = null;
    entity[field + '_name'] = null;
  }
  
  selectionChange(entity, field, event) {
    entity[field] = event.value;
    entity[field + '_id'] = event.value.id;
    entity[field + '_name'] = event.value.value;
  }
}
