import { Injectable } from '@angular/core';

import { RessourceService } from '@shared/api/config/ressource.service';
import { DropDownService } from '@shared/api/dropdown/dropdown.service';
import { ICrudConfigModel } from '@shared/modeles/crud/crud-config.model';
import { IRouteItemModelLike } from '@shared/modeles/menu/route-item.model';
import { AbstractRtlqConfig } from '@shared/resource/rtlq.config';
import { app_config } from 'app/app.config';

@Injectable({
  providedIn: 'root',
})
export class UserConfig extends AbstractRtlqConfig {
  static MENU: IRouteItemModelLike = RessourceService.MENUS.URL_GROUPE;
  static REST_URL = 'association/adherents';
  static MODULE = UserConfig.MENU.module;
  static DOMAINE = UserConfig.MENU.uri;
  static STORE = app_config.STORE.UserConfig;

  get module(): string {
    return UserConfig.MODULE;
  }

  get domaine(): string {
    return UserConfig.DOMAINE;
  }

  get id(): string {
    return UserConfig.MENU.id;
  }
  getCols(): Array<ICrudConfigModel> {
    const config: Array<ICrudConfigModel> = [];

    config.push(
      this.createCrudConfigModel('id').withType(RessourceService.CONST.TYPE_NUMBER).withOnlyTable().withUserHidden()
    );
    config.push(this.createCrudConfigModel('avatar').isImage('avatar_url').withOnlyForm().withUserHidden());
    config.push(this.createCrudConfigModel('email').withOnlyForm().isFormRequired().withUserEdit());
    config.push(this.createCrudConfigModel('username').withOnlyForm().isFormRequired().withUserEdit());
    config.push(this.createCrudConfigModel('pwd').withUserHidden());
    config.push(this.createCrudConfigModel('nom').withOnlyForm().isFormRequired().withUserCannotEdit());
    config.push(this.createCrudConfigModel('prenom').withOnlyForm().isFormRequired().withUserCannotEdit());
    config.push(this.createCrudConfigModel('telephone').withOnlyForm().isFormRequired().withUserEdit());
    config.push(
      this.createCrudConfigModel('date_naissance')
        .withType(RessourceService.CONST.TYPE_DATE)
        .withOnlyForm()
        .isFormRequired()
        .withUserEdit()
    );
    config.push(this.createCrudConfigModel('adresse').withOnlyForm().withUserEdit().isFormRequired());
    config.push(
      this.createCrudConfigModel('code_postal')
        .withType(RessourceService.CONST.TYPE_NUMBER)
        .withOnlyForm()
        .withUserEdit()
        .isFormRequired()
    );
    config.push(this.createCrudConfigModel('ville').withOnlyForm().withUserEdit().isFormRequired());
    config.push(
      this.createCrudConfigModel('publique').withType(RessourceService.CONST.TYPE_BOOLEAN).withOnlyForm().withUserEdit()
    );
    config.push(
      this.createCrudConfigModel('actif').withType(RessourceService.CONST.TYPE_BOOLEAN).withOnlyForm().withUserHidden()
    );
    config.push(
      this.createCrudConfigModel('saison_courante')
        .withType(RessourceService.CONST.TYPE_BOOLEAN)
        .withOnlyForm()
        .withUserHidden()
    );
    config.push(
      this.createCrudConfigModel('date_creation')
        .withType(RessourceService.CONST.TYPE_DATE)
        .withOnlyForm()
        .withUserHidden()
        .isFormRequired()
    );
    config.push(this.createCrudConfigModel('date_creation_text').withOnlyTable().withUserCannotEdit());
    config.push(
      this.createCrudConfigModel('date_last_auth')
        .withType(RessourceService.CONST.TYPE_DATE)
        .withOnlyForm()
        .withFormReadonly()
        .withUserHidden()
    );
    config.push(this.createCrudConfigModel('licence_number').withOnlyForm().withUserCannotEdit());
    config.push(
      this.createCrudConfigModel('licence_etat')
        .withOnlyForm()
        .withFormValues(['TODO', 'ENCOURS', 'ACTIF', 'INACTIF'])
        .withUserCannotEdit()
    );
    config.push(
      this.createCrudConfigModel('cotisation')
        .withOnlyForm()
        .withUserHidden()
        .withDropDown(DropDownService.DROPDOWNS.COTISATION)
    );
    config.push(this.createCrudConfigModel('cotisation_name').withOnlyTable().withUserCannotEdit());

    return config;
  }
}
