import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';
import { IDropdownModelLike } from '@shared/modeles/dropdown/dropdown.model';
import { AbstractCrudDropDownModel } from '@shared/modeles/crud/absract-crud.model';

export interface IKungFuStyleModelLike extends IDropdownModelLike, ICrudModelLike {}

export class KungFuStyleModel extends AbstractCrudDropDownModel implements IKungFuStyleModelLike {
  constructor(public id = null, public nom = null) {
    super(id);
  }
}
