<div class="scene">
    <div class="planet">
      <div class="crater"></div>
      <div class="crater"></div>
      <div class="crater"></div>
      <div class="crater"></div>
      <div class="crater"></div>
      <div class="rover">
        <div class="body"></div>
        <div class="wheels"></div>
        <div class="trace"></div>
      </div>
      <div class="flag">
        404
      </div>
    </div>
    <div class="message">
      <p>
        There is no life, try to find <a [routerLink]="homeUri">something else</a>
      </p>
    </div>
  </div>