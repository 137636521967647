import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '@app_store/app.reducer';
import { version } from '@core/../../package.json';

import { AssociationEntityService } from '@association/api/association.entity-service';
import { AutoUnsubDecorator } from '@shared/components/decorator/auto-unsub.decorator';
import { currentUserIsLoaded } from '@user/store/current-user/current-user.selectors';
import { tap } from 'rxjs/operators';

@AutoUnsubDecorator()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  private _assosSub$;
  public version = version;
  public intranet: string;
  public extranet: string;
  constructor(protected store: Store<AppState>, protected associationEntityService: AssociationEntityService) {}

  ngOnInit() {
    this.store.select(currentUserIsLoaded).pipe(
      tap((currentUserLoaded) => {
        if (currentUserLoaded) {
          this._assosSub$ = this.associationEntityService.getAll().subscribe((associations) => {
            const assoActives = associations.filter((asso) => asso.actif === true);
            this.intranet = assoActives ? assoActives[0].url_intranet : null;
            this.extranet = assoActives ? assoActives[0].url_extranet : null;
          });
        }
      })
    );
  }

  ngOnDestroy(): void {}
}
