import { Injectable } from "@angular/core";
import { ActiveToast, ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private toastr: ToastrService) {}

  hideAll() {
    this.toastr.clear();
  }

  hide(idtoaster) {
    this.toastr.remove(idtoaster);
  }
  showSuccess(message, idToasterToHide = null): number {
    if (idToasterToHide) {
      this.hide(idToasterToHide);
    }
    return this.toastr.success(message).toastId;
  }

  showError(message, idToasterToHide = null): number {
    if (idToasterToHide) {
      this.hide(idToasterToHide);
    }

    return this.toastr.error(message).toastId;
  }

  showInfo(message, title = null, idToasterToHide = null): number {
    if (idToasterToHide) {
      this.hide(idToasterToHide);
    }

    return this.toastr.info(message, title).toastId;
  }

  showWarning(message, idToasterToHide = null): number {
    if (idToasterToHide) {
      this.hide(idToasterToHide);
    }
    return this.toastr.warning(message).toastId;
  }
}
