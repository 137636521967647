import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { ITresorerieCategorieModelLike } from '@tresorerie/models/tresorerie-categorie.model';
import { TresorerieCategorieConfig } from '@tresorerie/resource/tresorerie-categorie.config';

@Injectable({ providedIn: 'root' })
export class TresorerieCategorieEntityService extends EntityCollectionServiceBase<ITresorerieCategorieModelLike> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(TresorerieCategorieConfig.STORE, serviceElementsFactory);
  }
}
