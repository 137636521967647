import { ICrudModelLike } from './i-crud.model';
import * as clone from 'clone';
import { Dropdown, IDropdown, IDropdownModelLike } from '../dropdown/dropdown.model';

export abstract class AbstractCrudModel implements ICrudModelLike {
  static DROPDOWN_USER = 'dropdown_user';
  static DROPDOWN_SAISON = 'dropdown_saison';
  static DROPDOWN_CATEGORIE = 'dropdown_categorie';
  static DROPDOWN_PHOTO = 'dropdown_photo';
  static DROPDOWN_PHOTO_DIRECTORY = 'dropdown_photodir';
  static DROPDOWN_TRESORERIECATEGORIE = 'dropdown_tresoreriecategorie';
  static DROPDOWN_NIVEAU = 'dropdown_niveau';
  static DROPDOWN_STYLE = 'dropdown_style';
  static DROPDOWN_ETAT = 'dropdown_tresorerieetat';
  static DROPDOWN_PRESIDENT = 'dropdown_president';
  static DROPDOWN_SECRETAIRE = 'dropdown_secretaire';
  static DROPDOWN_TRESORIER = 'dropdown_tresorier';

  public processing = false;
  public error = false;
  public success = null;
  public showActions = true;

  constructor(public id = null) {}

  toArray() {
    return JSON.parse(JSON.stringify(this));
  }

  perfectForSearching(value: string) {
    let valueOut = '';
    if (value != null) {
      valueOut = value.replace(' ', '').toLowerCase();
    }
    return valueOut;
  }

  /**
   * @deprecated
   */
  convertToModele(dto: any): ICrudModelLike {
    const model = this.customConvertToModele(dto);
    model.consolidation();
    return model;
  }

  consolidation() {}

  beforeRunning() {
    this.processing = true;
    this.error = false;
  }
  afterRunningSuccessfully() {
    this.processing = false;
    this.error = false;
  }
  afterRunningUnSuccessfully() {
    this.processing = false;
    this.error = true;
  }

  initBeforeCreating() {
    this.id = null;
    this.consolidation();
  }

  initBeforeSaving() {
    this.consolidation();
  }

  extract(value, defaultValue = null) {
    if (undefined === value) {
      return defaultValue;
    } else {
      return value;
    }
  }

  public clone<T extends ICrudModelLike>() {
    return clone(this);
  }

  public mergeIntoClone<T extends ICrudModelLike>(data: T): T {
    const newInstance = this.clone();
    const json = { ...this, ...data };
    return Object.assign(newInstance, json);
  }

  public update(entityToMerge: any, skipNull: boolean = true) {
    for (const key in entityToMerge) {
      if (entityToMerge.hasOwnProperty(key)) {
        if ((skipNull && entityToMerge[key] != null) || !skipNull) {
          this[key] = entityToMerge[key];
        }
      }
    }
    this.consolidation();
    return this;
  }

  public initDropDown(val: string) {
    this[val] = new Dropdown(this[val + '_id'], this[val + '_name']);
  }

  /**
   * @deprecated
   */
  public getUrlConfiguration() {
    throw new Error('Method deprecated.');
    return null;
  }
  /**
   * @deprecated
   */
  public getMenuConfiguration() {
    // throw new Error('Method deprecated.');
    return null;
  }
  /**
   * @deprecated
   */
  public customConvertToModele(dto: any): ICrudModelLike {
    throw new Error('Method deprecated.');
    return null;
  }
}

export abstract class AbstractCrudDropDownModel extends AbstractCrudModel implements IDropdownModelLike {
  public nom: string = null;
  public convertToDropDown(): IDropdown {
    return new Dropdown(this.id, this.getLabelDropDown());
  }

  protected getLabelDropDown() {
    return this.nom;
  }
}
