<div class="text-center" *ngIf="uploadImage != null">
  <div class="text-center">
    <img *ngIf="!uploadImage.imageHasChanged()" class="avatar-90" [src]="getSrc()" />
    <img
      *ngIf="uploadImage.imageHasChanged() && !uploadImage.isImageSelecting()"
      class="avatar-90"
      [src]="uploadImage.croppedImage"
    />

    <div *ngIf="uploadImage.isImageSelecting()">
      <image-cropper
        [imageChangedEvent]="uploadImage.imageChangedEvent"
        imageVisible="uploadImage.isImageSelecting()"
        format="jpeg"
        (imageCropped)="uploadImage.imageCropped($event)"
      >
      </image-cropper>
    </div>
    <label for="files" *ngIf="!uploadImage.isImageSelecting()" class="btn btn-outline-primary">Changer de Photo</label>
  </div>

  <div class="text-center">
    <button
      *ngIf="uploadImage.isImageSelecting() && !uploadImage.isImageSelected()"
      (click)="cancel()"
      class="btn btn-outline-primary"
      type="button"
    >
      <i class="fas fa-sign-in-alt"></i> Annuler
    </button>
    <button *ngIf="uploadImage.isImageSelecting()" (click)="validate()" class="btn btn-outline-primary" type="button">
      <i class="fas fa-sign-in-alt"></i> Valider
    </button>

      <input
        [formControlName]="propertyToSet"
        id="files"
        style="visibility: hidden"
        class="btn btn-outline-primary"
        type="file"
        (change)="uploadImage.fileChangeEvent($event)"
      />

   
  </div>
</div>
