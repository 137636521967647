import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { exhaustMap, map, take } from 'rxjs/operators';

import { RessourceService } from '@shared/api/config/ressource.service';
import { AppState } from '@app_store/app.reducer';
import { ISessionUserModelLike } from '@authenticate/models/session-user.model';
import { selectAuthState } from '@authenticate/store/auth.selectors';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(selectAuthState).pipe(
      take(1),
      map((authUser) => {
        return authUser ? authUser.auth : authUser;
      }),
      exhaustMap((userSession: ISessionUserModelLike) => {
        if (!userSession) {
          // not autehnticate case
          return next.handle(request);
        }

        const contentType = request.headers.get('Content-Type');
        let newHeader = request.headers
          .append('Authorization', 'Bearer ' + userSession.token)
          .append(RessourceService.CONST.X_AUTH_TOKEN, userSession.token)
          .append('Cache-Control', 'no-cache')
          .append('Content-Type', contentType ? contentType : 'application/json');

        if (contentType === 'multipart/form-data') {
          newHeader = newHeader.delete('Content-Type');
        }

        const modifiedRequest = request.clone({
          headers: newHeader,
        });
        return next.handle(modifiedRequest);
      })
    );

    // if (this.hasToken()) {
    //   const contentType = request.headers.get('Content-Type');

    //   if (contentType === '') {
    //     request = request.clone({
    //       headers: request.headers.delete('Content-Type'),
    //     });
    //   }
    // }

    // const timeout = Number(request.headers.get('timeout')) || this.defaultTimeout;
    // return next.handle(request);
  }
  // protected getToken(): string {
  //   const authToken = CredentialService.retrieveToken();
  //   return authToken ? authToken.token : null;
  // }

  // protected hasToken() {
  //   return this.getToken() != null;
  // }
}
