import { ValidatorFn, Validators } from '@angular/forms';

import { RessourceService } from '@shared/api/config/ressource.service';
import { TranslateService } from '@shared/api/config/translate.service';
import * as clone from 'clone';

export interface ICrudConfigModel {
  validators: ValidatorFn[];
  withOnlyForm();
  withOnlyTable();
  withFormReadonly();
  withVideoValue(video_title: string, video_url: string, video_type: string): CrudConfigModel;
  withTooltip(fieldName);
  withPlaceHolder(value);
  isTableHidden();
  isFormHidden();
  isUserHidden();
  isGlobalFilterField();
  getTableHeader();
  getType();
  getClazz();
  getDataClazz();
  getDataClazzDynamic(value);
  getField();
  getFieldToShow();
  getFormModele();
  getValue();
  setUrlVideo(value: string);
  getBoolClassOn();
  getBoolClassOff();
  getOnClick();
  clone(): ICrudConfigModel;
}

export class CrudConfigModel implements ICrudConfigModel {
  public module: string;
  public field: string;
  public field_ToShow: string;
  public clazz: string;
  public dataClazz: string;
  public dataClazzDynamic: string;
  public domaine: string;
  public table_header: string;
  public type: string;
  public sub_type: string;
  public table_hidden: boolean;
  public table_globalFilterField: boolean;
  public form_header: string;
  public form_required: boolean;
  public form_hidden: boolean;
  public form_readonly: boolean;
  public form_modele: string;
  public form_placeHolder: string;
  public form_optionLabel: string;
  public form_step: '1';
  public form_values = [];
  public user_edit: boolean;
  public user_show: boolean;
  public form_dropdownFieldValue: string;
  public onSelect;
  public onClearSelect;
  public componentParent;
  public value;
  public bool_class_on: string;
  public bool_class_off: string;
  public onClick: string;
  public tooltip_title: string;
  public entityService: string;
  public entityClass: any;
  private _validators: ValidatorFn[] = [];
  get validators(): ValidatorFn[] {
    return this._validators;
  }

  constructor(field, domaine, module = null) {
    this.field = field;
    this.module = module;
    this.field_ToShow = field;
    this.domaine = domaine;
    this.type = RessourceService.CONST.TYPE_STRING;
    this.table_header = TranslateService.getTranslate(
      this.module ? this.module + '_' + this.domaine : this.domaine,
      this.field
    );
    this.table_hidden = false;
    this.form_header = this.table_header;
    this.form_readonly = false;
    this.form_hidden = false;
    this.form_modele = '';
    this.form_placeHolder = '';
    this.table_globalFilterField = true;
    this.form_optionLabel = '';
    this.form_values = [];
    this.user_edit = true;
    this.user_show = true;
    this.clazz = 'col-medium';
    this.dataClazz = null;
    this.value = null;
    this.bool_class_on = 'fas fa-check green';
    this.bool_class_off = 'fas fa-times red';
    this.onClick = null;
  }

  withDropDown(entityService: string): ICrudConfigModel {
    this.type = RessourceService.CONST.TYPE_DROPDOWN;
    this.entityService = entityService;
    this.form_placeHolder = TranslateService.getTranslate(this.domaine, entityService);
    this.form_optionLabel = 'value';
    this.form_dropdownFieldValue = 'name';
    return this;
  }

  withDropDownMultiple(entityService: string): CrudConfigModel {
    this.type = RessourceService.CONST.TYPE_DROPDOWN_MULTIPLE;
    this.entityService = entityService;
    this.form_placeHolder = TranslateService.getTranslate(this.domaine, entityService);
    this.form_optionLabel = 'value';
    this.form_dropdownFieldValue = 'name';
    return this;
  }

  withSuggestion(entityService: string): CrudConfigModel {
    this.type = RessourceService.CONST.TYPE_LIST_SUGGESTION;
    this.entityService = entityService;
    this.form_placeHolder = TranslateService.getTranslate(this.domaine, entityService);
    this.form_optionLabel = 'value';
    this.form_dropdownFieldValue = 'name';
    return this;
  }

  withTooltip(fieldName): CrudConfigModel {
    this.type = RessourceService.CONST.TYPE_TOOLTIP;
    this.field_ToShow = fieldName;
    this.tooltip_title = TranslateService.getTranslate(this.domaine, 'tooltip_' + fieldName);
    this.withClazzXSmall();
    return this;
  }

  hasFormValidation(pattern): CrudConfigModel {
    this._validators.push(Validators.pattern(pattern));
    return this;
  }

  withUserCannotEdit(): CrudConfigModel {
    this.user_show = true;
    this.user_edit = false;
    return this;
  }

  withUserHidden(): CrudConfigModel {
    this.user_show = false;
    this.user_edit = false;
    return this;
  }

  withUserEdit(): CrudConfigModel {
    this.user_show = true;
    this.user_edit = true;
    return this;
  }

  isFormRequired(): CrudConfigModel {
    this._validators.push(Validators.required);
    this.form_required = true;
    return this;
  }
  isFormNotRequired() {
    this._validators = this._validators.filter((elt) => elt !== Validators.required);
    this.form_required = false;
    return this;
  }

  withFormReadonly(): CrudConfigModel {
    this.form_readonly = true;
    return this;
  }

  withFormHidden(): CrudConfigModel {
    this.form_hidden = true;
    return this;
  }

  withPlaceHolder(value): CrudConfigModel {
    this.form_placeHolder = value;
    return this;
  }

  withFormHeader(form_header): CrudConfigModel {
    this.form_header = TranslateService.getTranslate(this.domaine, form_header);
    return this;
  }

  withFormValues(values: string[]): CrudConfigModel {
    this.type = RessourceService.CONST.TYPE_LISTSTRING;
    this.form_values = [];
    values.forEach((value) => {
      this.form_values.push({ label: value, value: value });
    });

    return this;
  }
  withTableHidden(table_hidden): CrudConfigModel {
    this.table_hidden = table_hidden;
    return this;
  }

  withType(type): CrudConfigModel {
    this.type = type;
    if (this.type === RessourceService.CONST.TYPE_DATE) {
      this.withClazzMedium();
      this.addClazzCenter();
    } else if (this.type === RessourceService.CONST.TYPE_BOOLEAN) {
      this.withClazzXSmall();
      this.addClazzCenter();
    }
    return this;
  }

  isImage(field_ToShow) {
    this.withType(RessourceService.CONST.TYPE_PHOTO);
    this.field_ToShow = field_ToShow;
    return this;
  }

  withoutFormOrTable(): CrudConfigModel {
    this.form_hidden = true;
    this.table_hidden = true;
    this.table_globalFilterField = false;
    return this;
  }
  withGlobalFilterField(): CrudConfigModel {
    this.table_globalFilterField = true;
    return this;
  }

  withOnlyForm(): CrudConfigModel {
    this.form_hidden = false;
    this.table_hidden = true;
    return this;
  }
  withOnlyTable(): CrudConfigModel {
    this.form_hidden = true;
    this.table_hidden = false;
    return this;
  }
  withFieldToShow(field): CrudConfigModel {
    this.field_ToShow = field;
    return this;
  }
  withClazz(value): CrudConfigModel {
    this.clazz = value;
    return this;
  }
  withClazzSmall(): CrudConfigModel {
    this.clazz += ' col-small';
    return this;
  }
  withClazzXSmall(): CrudConfigModel {
    this.clazz = 'col-xsmall';
    return this;
  }
  withClazzLarge(): CrudConfigModel {
    this.clazz = 'col-large';
    return this;
  }
  withClazzMedium(): CrudConfigModel {
    this.clazz = 'col-medium';
    return this;
  }
  addClazzCenter(): CrudConfigModel {
    this.clazz += ' center';
    return this;
  }
  withDataClazz(value): CrudConfigModel {
    this.dataClazz = value;
    return this;
  }

  withDataClazzDynamic(value): CrudConfigModel {
    this.dataClazzDynamic = value;
    return this;
  }

  withVideoValue(video_title: string, video_url: string, video_type: string): CrudConfigModel {
    this.value = video_url;
    this.form_placeHolder = video_title;
    this.type = RessourceService.CONST.TYPE_VIDEO;
    this.sub_type = video_type;
    return this;
  }

  withConfigBool(on: string, off: string) {
    this.bool_class_on = on;
    this.bool_class_off = off;
    return this;
  }

  withOnClick(name) {
    this.onClick = name;
    return this;
  }

  withOnChange(name) {
    this.onClick = name;
    return this;
  }

  public isTableHidden() {
    return this.table_hidden;
  }
  public isFormHidden() {
    return this.form_hidden;
  }
  public isUserHidden() {
    return !this.user_show;
  }
  public getTableHeader() {
    return this.table_header;
  }
  public getField() {
    return this.field;
  }
  public getValue() {
    return this.value;
  }
  public getFieldToShow() {
    return this.field_ToShow;
  }
  public getFormModele() {
    return this.form_modele;
  }
  public getType() {
    return this.type;
  }
  public getClazz() {
    return this.clazz;
  }
  public getDataClazz() {
    return this.dataClazz;
  }
  public getDataClazzDynamic(value) {
    return this.dataClazzDynamic && value ? this.dataClazzDynamic + '-' + value.replace(/\s/g, '') : '';
  }
  public isGlobalFilterField() {
    return this.table_globalFilterField;
  }
  public setUrlVideo(value: string) {
    this.value = value;
  }
  public getBoolClassOn() {
    return this.bool_class_on;
  }
  public getBoolClassOff() {
    return this.bool_class_off;
  }
  public getOnClick() {
    return this.onClick;
  }

  public clone() {
    return clone(this);
  }
}
