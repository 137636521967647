import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';
import { IPhotoModelLike } from './photo.model';
import { IDropdownModelLike } from '@shared/modeles/dropdown/dropdown.model';
import { AbstractCrudActifWithDropDownModelLike } from '@shared/modeles/crud/abstract-crud-actif-with-date.model';
import { IActifLike } from '@shared/modeles/crud/i-actif.like';

export interface IPhotoDirectoryModelLike extends IDropdownModelLike, ICrudModelLike, IActifLike {
  preview_id?: number;
  preview_url?: string;
  photo?: IPhotoModelLike;
  nb_photos?: number;
}

export class PhotoDirectoryModel extends AbstractCrudActifWithDropDownModelLike implements IPhotoDirectoryModelLike {
  constructor(
    public id = null,
    public nom = null,
    public actif = null,
    public preview_id = null,
    public preview_url = null,
    public photo = null,
    public nb_photos = 0
  ) {
    super(id);
  }

}
