import { Injectable } from '@angular/core';
import { AssociationModel, IAssociationModelLike } from '@association/models/association.model';
import { AbstractCrudBuilder, IBuilderLike } from '@shared/builder/abstract-rtlq.build';

export interface IAssociationBuilderLike extends IBuilderLike<IAssociationModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class AssociationBuilder extends AbstractCrudBuilder<IAssociationModelLike> implements IAssociationBuilderLike {
  getEntity() {
    return AssociationModel;
  }

}
