import { Injectable } from '@angular/core';
import { PhotoDirectoryModel, IPhotoDirectoryModelLike } from '@photo/models/photo-directory.model';
import { PhotoModel } from '@photo/models/photo.model';
import { AbstractCrudBuilder, IBuilderLike } from '@shared/builder/abstract-rtlq.build';

export interface IPhotoDirectoryBuilderLike extends IBuilderLike<IPhotoDirectoryModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class PhotoDirectoryBuilder
  extends AbstractCrudBuilder<IPhotoDirectoryModelLike>
  implements IPhotoDirectoryBuilderLike {
    getEntity() {
      return PhotoDirectoryModel;
  }

  protected customConvertResponseToModele(json: {}, entity: IPhotoDirectoryModelLike): IPhotoDirectoryModelLike {
    entity.photo = new PhotoModel(json['preview_id']);
    entity.preview_id = +entity.photo.id;
    entity.preview_url = entity.photo.preview;

    return entity;
  }
}
