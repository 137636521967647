<button *ngIf="!action_status" [disabled]="disabled" class="btn btn-{{ class }}" [type]="type">
  <i class="fas {{ action_icone }}"></i>{{ action_name }}
</button>

<button *ngIf="action_status" class="btn btn-outline-secondary" type="button">
  <div (click)="action_status = false">
    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    {{ action_name_inprogress }}
  </div>
</button>
