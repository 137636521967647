import { Injectable } from '@angular/core';
import { KungFuStyleModel, IKungFuStyleModelLike } from '@kungfu/models/kungfu-style.model';
import { AbstractCrudBuilder, IBuilderLike } from '@shared/builder/abstract-rtlq.build';

export interface IKungFuStyleBuilderLike extends IBuilderLike<IKungFuStyleModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class KungFuStyleBuilder extends AbstractCrudBuilder<IKungFuStyleModelLike> implements IKungFuStyleBuilderLike {
  getEntity() {
    return  KungFuStyleModel;
  }
}
