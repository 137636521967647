import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { IPhotoDirectoryModelLike } from '@photo/models/photo-directory.model';

import { PhotoDirectoryConfig } from '@photo/resource/photo-directory.config';

@Injectable({ providedIn: 'root' })
export class PhotoDirectoryEntityService extends EntityCollectionServiceBase<IPhotoDirectoryModelLike> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(PhotoDirectoryConfig.STORE, serviceElementsFactory);
  }
}
