import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RessourceService } from '@shared/api/config/ressource.service';
import { ICrudService } from '@shared/api/crud/i-crud.service';

import { IStatsModelLike, StatsModel } from '@dashboard/models/stats.model';
import { TresorerieService } from '@tresorerie/api/tresorerie/tresorerie.service';
import { TaoService } from '@tao/api/tao/tao.service';
import { ITaoUserModelLike } from '@tao/models/tao-user.model';
import { ITaoModelLike } from '@tao/models/tao.model';

import { IUserModelLike } from '../../models/user.model';
import { AbstractRtlqService } from '@shared/api/crud/abstract-main.service';
import { UserBuilder } from '@user/builder/user.builder';
import { UserConfig } from '@user/resource/user.config';

export enum EmailType {
  URI_USER_SEND_EMAIL_WELCOME,
}

export interface IUserServiceLike extends ICrudService<IUserModelLike> {
  getFullUrl(): string;
  // getMyTaoUri();

  getListe(): Observable<IUserModelLike[]>;
  getCurrentUser(): Observable<IUserModelLike>;
  updateCurrentUser(entity: IUserModelLike): Observable<IUserModelLike>;

  updateTaoOfCurrentUser(tao: ITaoModelLike): Observable<any>;


  resetPassword(email: string): Observable<any>;
  changePassword(token: string, password: string, confirmation: string): Observable<any>;

  finalizeInscripion(id: number): Observable<IUserModelLike>;
  sendEmail(id: number, type_email: EmailType): Observable<any>;
}

@Injectable({
  providedIn: 'root',
})
export class UserService extends AbstractRtlqService<IUserModelLike> implements IUserServiceLike {
  constructor(
    protected http: HttpClient,
    protected builder: UserBuilder,
  ) {
    super(http, builder, UserConfig.REST_URL);
  }

  /**
   * @deprecated
   */

  getListe(): Observable<IUserModelLike[]> {
    const entity = this.http
      .get(`${this.getFullUrl()}/${RessourceService.CONFIG.URI_USER_LISTE}`)
      .pipe(map((response) => this.mapEntities(response)));
    return entity;
  }
  /**
   * @deprecated
   */

  getCurrentUser(): Observable<IUserModelLike> {
    const entity = this.http
      .get(`${this.getFullUrl()}/${RessourceService.CONFIG.URI_USER_BY_TOKEN}`)
      .pipe(map((response) => this.mapEntity(response)));

    return entity;
  }

    /**
   * @deprecated
   */
  updateCurrentUser(entity: IUserModelLike): Observable<IUserModelLike> {
    return this.http
      .put(`${this.getFullUrl()}/${RessourceService.CONFIG.URI_USER_BY_TOKEN}`, JSON.stringify(entity))
      .pipe(map((response) => this.mapEntity(response)));
  }

  /**
   * @deprecated
   */
  updateTaoOfCurrentUser(tao: ITaoModelLike): Observable<any> {
    //   const entity = this.http
    //     .put(
    //       `${this.getFullUrl()}/${RessourceService.CONFIG.URI_USER_BY_TOKEN}/${
    //         RessourceService.CONFIG.URI_USER_MY_TAO
    //       }/${taoId}`,
    //       JSON.stringify(tao)
    //     )
    //     .pipe(map((response) => this.adherentTaoService.mapEntity(response)));
    //   return entity;
    return null;
  }


  resetPassword(email: string): Observable<any> {
    const entity = this.http.post(
      `${this.getFullUrl()}/${RessourceService.CONFIG.URI_USER_PASSWORD_RESET}`,
      JSON.stringify({ email: email })
    );

    return entity;
  }

  changePassword(token: string, password: string, confirmation: string): Observable<any> {
    const entity = this.http.post(
      `${this.getFullUrl()}/${RessourceService.CONFIG.URI_USER_PASSWORD_CHANGE}`,
      JSON.stringify({
        token: token,
        password: password,
        confirmation: confirmation,
      })
    );

    return entity;
  }

  finalizeInscripion(id: number): Observable<IUserModelLike> {
    const entity = this.http
      .post(`${this.getFullUrl()}/${id}/${RessourceService.CONFIG.URI_USER_FINALIZE_INSCRIPTION}`, {})
      .pipe(map((data) => this.mapEntity(data)));

    return entity;
  }

  sendEmail(id: number, type_email: EmailType): Observable<any> {
    const entity = this.http
      .post(
        `${this.getFullUrl()}/${id}/${RessourceService.CONFIG.URI_USER_SEND_EMAIL}/${
          RessourceService.CONFIG[EmailType[type_email]]
        }`,
        JSON.stringify({})
      )
      .pipe(map((data) => this.mapEntity(data)));

    return entity;
  }
}
