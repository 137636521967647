import { Injectable } from '@angular/core';
import {
  CanLoad,
  CanActivate,
  Router,
  Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';

import { RessourceService } from '@shared/api/config/ressource.service';

import { AuthenticateFacade } from '@authenticate/authenticate.facade';
import { AppState } from '@app_store/app.reducer';
import { PageConfigModel } from '@shared/modeles/config/page-config.model';
@Injectable({
  providedIn: 'root',
})
export class RouteGuardService implements CanLoad, CanActivate {
  constructor(private store: Store<AppState>, private authenticateFacade: AuthenticateFacade, private router: Router) {}

  canLoad(route: Route): boolean | Promise<boolean> | Observable<boolean> {
    return this.store.select('auth').pipe(
      take(1),
      map((authUser) => !!authUser.auth)
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    router: RouterStateSnapshot
  ): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    return this.store.select('auth').pipe(
      take(1),
      map((authUser) => {
        if (!authUser.auth) {
          return this.router.createUrlTree(PageConfigModel.getUri(RessourceService.MENU.URL_LOGIN), {
            queryParams: { url: router.url ? router.url : RessourceService.MENU.URL_HOME.uri },
          });
        } else {
          return this.authenticateFacade.isRoleAuthorized(authUser.auth, route.data.roles);
        }
      })
    );
  }
}
