import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { AuthenticationService } from '@authenticate/api/security/authentication.service';
import * as AuthActions from '@authenticate/store/auth.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { RessourceService } from '@shared/api/config/ressource.service';
import { CredentialService } from '@authenticate/api/security/credential.service';
import { PageConfigModel } from '@shared/modeles/config/page-config.model';
import { ISessionUserModelLike } from '@authenticate/models/session-user.model';
import { AuthState } from '@authenticate/store/auth.reducer';
import { CurrentUserActions } from '@user/store/current-user/current-user.types';

@Injectable({ providedIn: 'root' })
export class AuthEffects {
  @Effect()
  authAutoLogin = this.actions$.pipe(
    ofType(AuthActions.AUTO_LOGIN),
    map(() => {
      const userToken: ISessionUserModelLike = this.credentialService.retrieveUserSessionFromLocaleStorage();
      if (userToken) {
        userToken.setNotAuthenticate();
        return new AuthActions.AutoLoginCheckAction(userToken);
      } else {
        return new AuthActions.AutoLoginFailedAction();
      }
    })
  );

  @Effect()
  authAutoLoginCheck = this.actions$.pipe(
    ofType(AuthActions.AUTO_LOGIN_CHECK),
    switchMap((authUser: AuthActions.AutoLoginCheckAction) => {
      return this.credentialService.checkUserAuthentication().pipe(
        map(() => {
          return new AuthActions.AutoLoginSuccessedAction(authUser.payload); // return a new observable without error
        }),
        catchError((errorResp) => {
          return of(new AuthActions.LoginFailedAction(errorResp)); // return a new observable without error
        })
      );
    })
  );

  @Effect()
  authLoginStart = this.actions$.pipe(
    ofType(AuthActions.LOGIN_START),
    switchMap((authUser: AuthActions.LoginStartAction) => {
      return this.authenticationService.login(authUser.payload.username, authUser.payload.password).pipe(
        map((resData) => {
          this.credentialService.storeUserSessionInLocaleStorage(resData);
          resData.authenticate = true;
          return new AuthActions.LoginSuccessedAction(resData); // return a new observable without error
        }),
        catchError((errorResp) => {
          return of(new AuthActions.LoginFailedAction(errorResp)); // return a new observable without error
        })
      );
    })
  );

  @Effect()
  authSuccess = this.actions$.pipe(
    ofType(AuthActions.LOGIN_SUCCESSED, AuthActions.AUTO_LOGIN_SUCCESSED),
    tap((action) => {
      const pageTarget = this.route.snapshot.queryParams.hasOwnProperty('return')
        ? this.route.snapshot.queryParams.return
        : PageConfigModel.getUri(RessourceService.MENU.URL_HOME);

      if (PageConfigModel.getStringUri(RessourceService.MENU.URL_LOGIN) === this.router.url) {
        this.router.navigate(pageTarget);
      }
    }),
    switchMap((res) => [CurrentUserActions.fetchingCurrentUserAction(), CurrentUserActions.fetchingStatsAction()])
  );

  @Effect({ dispatch: false })
  authLogout = this.actions$.pipe(
    ofType(AuthActions.LOGOUT),
    tap(
      () => {
        this.credentialService.removeUserSessionFromLocaleStorage();
        this.router.navigate(PageConfigModel.getUri(RessourceService.MENU.URL_LOGIN));
      },
      switchMap(() => {
        return this.authenticationService.logout().pipe(
          catchError((errorResp) => {
            return of(new AuthActions.LoginFailedAction(errorResp)); // return a new observable without error
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private authenticationService: AuthenticationService,
    private credentialService: CredentialService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngrxOnInitEffects(): AuthActions.AutoLoginAction {
    return new AuthActions.AutoLoginAction();
  }
}
