import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ICrudService } from '@shared/api/crud/i-crud.service';
import { AbstractRtlqService } from '@shared/api/crud/abstract-main.service';

import { IKungFuStyleModelLike } from '@kungfu/models/kungfu-style.model';
import { KungFuStyleBuilder } from '@kungfu/builder/kungfu-style.builder';
import { KungFuStyleConfig } from '@kungfu/resource/kungfu-style.config';

export interface IKungFuStyleServiceLike extends ICrudService<IKungFuStyleModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class KungFuStyleService
  extends AbstractRtlqService<IKungFuStyleModelLike>
  implements IKungFuStyleServiceLike {
  constructor(protected http: HttpClient, protected builder: KungFuStyleBuilder) {
    super(http, builder, KungFuStyleConfig.REST_URL);
  }
}
