import { Component, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICrudService } from '@shared/api/crud/i-crud.service';
import { NotificationService } from '@shared/api/notif/notifier.service';
import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';
import * as EventEmitter from 'events';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IRtlqComponent, RtlqComponent } from './rtlq.component';

export interface IRtlqModalComponentLike<T extends ICrudModelLike> extends IRtlqComponent {
  setup(arg: { abstractController: IRtlqComponent; title: string });
  close();
}

export abstract class RtlqModalComponent<T extends ICrudModelLike>
  extends RtlqComponent
  implements IRtlqModalComponentLike<T> {
  public title: string;

  protected abstractController: IRtlqComponent;
  protected service: ICrudService<T>;

  /** Allow you to add handler after its completion. Bubble up response text from remote. */
  private _complete : Subject<any>= new Subject() ;
  public complete$ : Observable<any> = this._complete.asObservable();

  constructor(protected modalService: NgbModal, protected notificationService: NotificationService) {
    super(modalService, notificationService);
  }

  protected setCompleted(data:any) {
    this._complete.next(data);
  }
  
  public ngOnInit() {
    super.ngOnInit();
    this.customNgOnInit();
  }
  protected customNgOnInit() {}

  public setup(args) {
    this.abstractController = args.abstractController;
    this.title = args.title;
    this.customSetup(args);
  }
  protected customSetup(args) {}

  public close(code: string = '') {
    this.modalService.dismissAll(code);
  }

  /**
   * runOnHide
   */
  public runOnHide(): void {
    this.resetInfoMessage();
    this.close('close');
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }
}
