import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() public action_name;
  @Input() public action_status;
  @Input() public action_name_inprogress;
  @Input() public action_icone;
  @Input() public class = 'outline-primary';
  @Input() public disabled = false;
  @Input() public type = 'submit';

  constructor() {}

  ngOnInit(): void {}
}
