import { Injectable } from '@angular/core';
import { SaisonModel, ISaisonModelLike } from '@saison/models/saison.model';
import { AbstractCrudBuilder, IBuilderLike } from '@shared/builder/abstract-rtlq.build';

export interface ISaisonBuilderLike extends IBuilderLike<ISaisonModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class SaisonBuilder extends AbstractCrudBuilder<ISaisonModelLike> implements ISaisonBuilderLike {
  getEntity() {
    return SaisonModel;
  }
}
