import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { IRtlqEntityServiceLike } from '@shared/api/ngrx/rtlq.entity-service';

import { IUserModelLike } from '@user/models/user.model';
import { UserConfig } from '@user/resource/user.config';

export interface IUserEntityServiceLike extends IRtlqEntityServiceLike<IUserModelLike> {}
@Injectable({ providedIn: 'root' })
export class UserEntityService extends EntityCollectionServiceBase<IUserModelLike> implements IUserEntityServiceLike {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(UserConfig.STORE, serviceElementsFactory);
  }
}
