import { createReducer, on,  Action } from '@ngrx/store';
import { ISaisonModelLike } from '@saison/models/saison.model';
import { CurrentSaisonActions } from './current-saison.types';

export interface CurrentSaisonState {
  saison: ISaisonModelLike;
  saisonLoaded: boolean;
}
export const currentSaisonFeatureKey = 'currentSaison';
export const initialCurrentSaisonState = {
  saison: null,
  saisonLoaded: false,
};

export const currentSaisonReducer = createReducer(
  initialCurrentSaisonState,
  on(CurrentSaisonActions.fetchingCurrentSaisonAction, (state, action) => {
    return { ...state,  saison: null, saisonLoaded: false };
  }),
  on(CurrentSaisonActions.fetchedCurrentSaisonAction, (state, action) => {
    return { ...state, saison: action.saison, saisonLoaded: true };
  }),
);

export function reducer(state: CurrentSaisonState | undefined, action: Action) {
  return currentSaisonReducer(state, action);
}
