import { IDateCreationModelLike } from './i-crud-with-date.model';
import { AbstractCrudDropDownModel } from './absract-crud.model';
import { DateService } from '@shared/api/date.service';
import { Moment } from 'moment';

export abstract class AbstractCrudWithDateModel extends AbstractCrudDropDownModel implements IDateCreationModelLike {
  public date_creation_text: string = null;
  public date_creation: Moment = null;

  setDate(date: Moment) {
    this.date_creation = date;
    this.date_creation = DateService.secureDateHourAndMSecToZero(this.date_creation);
  }

  consolidation() {
    super.consolidation();
    this.date_creation = DateService.string2Moment(this.date_creation);
    this.date_creation_text = DateService.convertDate2Text(this.date_creation);
  }

  initBeforeCreating() {
    super.initBeforeCreating();
    this.setDate(DateService.nowAsMoment());
  }
}
