import { OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RessourceService } from '@shared/api/config/ressource.service';
import { NotificationService } from '@shared/api/notif/notifier.service';
import { PageConfigModel } from '@shared/modeles/config/page-config.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { AutoUnsubDecorator } from './decorator/auto-unsub.decorator';

export interface IRtlqComponent extends OnInit, OnDestroy {
  errorMessage: string;
  infoMessage: string;
  isLoading: boolean;
  length: number;

  showSuccessMessage(msg, title?);
  showWarnMessage(msg, loading?);
  showInfoMessage(msg, loading?);
  setInfoMessage(text, loading?);
  resetInfoMessage();
  getModalService(): NgbModal;
  showErrorMessage(msg, exception?);
  /**
   * @deprecated
   */
  setErrorMessageWithString(text);
  resetErrorMessage();
}

@AutoUnsubDecorator()
export class RtlqComponent implements IRtlqComponent {
  public errorMessage = null;
  public errors$ = null;
  public infoMessage = null;
  /**
   * @deprecated use loader$
   */
  public isLoading = true;
  public subLoading$ = new BehaviorSubject(false);
  public loading$: Observable<boolean> = this.subLoading$.asObservable();
  public length = 0;

  public routeUserProfile: string[];
  public routeUserTrombinoscope: string[];
  public routeUserDrive: string[];
  public routeUserTaos: string[];
  public routeUserTresorerie: string[];
  public routeTaoHistorique: string[];
 

  constructor(protected modalService: NgbModal, protected notificationService: NotificationService) {
    this.routeUserTrombinoscope = PageConfigModel.getUri(RessourceService.MENUS.URL_TROMBINOSCOPE);
    this.routeUserProfile = PageConfigModel.getUri(RessourceService.MENUS.URL_USER_PROFIL);
    this.routeUserDrive = PageConfigModel.getUri(RessourceService.MENUS.URL_DRIVE);
    this.routeUserTaos = PageConfigModel.getUri(RessourceService.MENUS.URL_USER_TAOS_USER);
    this.routeUserTresorerie = PageConfigModel.getUri(RessourceService.MENUS.URL_USER_TRESORERIES);
    this.routeTaoHistorique = PageConfigModel.getUri(RessourceService.MENUS.URL_TAOS_HISTO);
  }

  public ngOnInit() {
    this.resetErrorMessage();
    this.resetInfoMessage();
  }

  public ngOnDestroy(): void {}

  getModalService(): NgbModal {
    return this.modalService;
  }
  getNotificationService(): NotificationService {
    return this.notificationService;
  }

  public showSuccessMessage(msg, title = null) {
    this.stopLoader();
    this.notificationService.hideAll();
    this.notificationService.showSuccess(msg, title);
  }

  public showWarnMessage(msg, loading = false) {
    this.setLoader(loading);
    this.notificationService.showWarning(msg);
  }

  public showInfoMessage(msg, loading = false, cleanAllMessages = false) {
    if (cleanAllMessages) {
      this.notificationService.hideAll();
    }
    this.notificationService.showInfo(msg);
    this.setInfoMessage(msg, loading);
  }

  public setInfoMessage(text, loading = true) {
    this.setLoader(loading);
    this.infoMessage = text;
  }
  public resetInfoMessage() {
    this.stopLoader();
    this.infoMessage = null;
  }

  public showErrorMessage(msg, exception = null) {
    this.stopLoader();
    this.notificationService.hideAll();
    if (msg.constructor.name === 'String') {
      this.errorMessage = msg;
    } else {
      if (msg.status === 0) {
        this.errorMessage = "Le serveur n'est pas disponible";
      } else {
        this.errorMessage = '[' + msg.status + '] ' + msg.statusText;
      }
    }
    if (exception) {
      console.error(exception);
    }
    this.notificationService.showError(this.errorMessage);
    console.error(this.errorMessage);
  }

  public setErrorMessageWithString(text) {
    this.errorMessage = text;
  }

  public resetErrorMessage() {
    this.errorMessage = null;
  }

  public startLoader() {
    this.setLoader(true);
  }

  public stopLoader() {
    this.setLoader(false);
  }

  public setLoader(state: boolean) {
    this.isLoading = state;
    this.subLoading$.next(state);
  }
}
