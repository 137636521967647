import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AbstractRtlqService } from '@shared/api/crud/abstract-main.service';
import { ICrudService } from '@shared/api/crud/i-crud.service';

import { TresorerieCategorieBuilder } from '@tresorerie/builder/tresorerie-categorie.builder';
import { ITresorerieCategorieModelLike } from '@tresorerie/models/tresorerie-categorie.model';
import { TresorerieCategorieConfig } from '@tresorerie/resource/tresorerie-categorie.config';

export interface ITresorerieCategorieServiceLike extends ICrudService<ITresorerieCategorieModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class TresorerieCategorieService
  extends AbstractRtlqService<ITresorerieCategorieModelLike>
  implements ITresorerieCategorieServiceLike {
  constructor(protected http: HttpClient, protected builder: TresorerieCategorieBuilder) {
    super(http, builder, TresorerieCategorieConfig.REST_URL);
  }
}
