import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';

import { RtlqDataService } from '../../shared/api/ngrx/rtlq.data-service';
import { IAssociationModelLike } from '@association/models/association.model';
import { AssociationService } from './association.service';
import { AssociationConfig } from '@association/ressource/association.config';

@Injectable({ providedIn: 'root' })
export class AssociationDataService extends RtlqDataService<IAssociationModelLike> {
  
  constructor(
    protected apiService: AssociationService,
    protected http: HttpClient,
    protected httpUrlGenerator: HttpUrlGenerator
  ) {
    super(AssociationConfig.STORE, apiService, http, httpUrlGenerator);
  }
}
