import { Injectable } from '@angular/core';
import { TresorerieEtatModel, ITresorerieEtatModelLike } from '@tresorerie/models/tresorerie-etat.model';
import { AbstractCrudBuilder, IBuilderLike } from '@shared/builder/abstract-rtlq.build';

export interface ITresorerieEtatBuilderLike extends IBuilderLike<ITresorerieEtatModelLike> {}
@Injectable({
  providedIn: 'root',
})
export class TresorerieEtatBuilder
  extends AbstractCrudBuilder<ITresorerieEtatModelLike>
  implements ITresorerieEtatBuilderLike {
    getEntity() {
      return TresorerieEtatModel;
  }
}
