import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RessourceService } from '@shared/api/config/ressource.service';

import { SessionUserBuilder } from '@authenticate/builder/session-user.builder';
import { ISessionUserModelLike } from '@authenticate/models/session-user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  static URL_BASE = RessourceService.CONFIG.URL_BACK_OFFICE;
  static URL_LOGIN = RessourceService.CONFIG.URL_AUTHENTICATION_LOGIN;
  static URL_LOGOUT = RessourceService.CONFIG.URL_AUTHENTICATION_LOGOUT;
  static URL_CHECK_USER = RessourceService.CONFIG.URL_AUTHENTICATION_CHECKUSER;

  public static get loginUrl() {
    return `${AuthenticationService.URL_BASE}/${AuthenticationService.URL_LOGIN}`;
  }
  public static get logoutUrl() {
    return `${AuthenticationService.URL_BASE}/${AuthenticationService.URL_LOGOUT}`;
  }

  constructor(private http: HttpClient, protected builder: SessionUserBuilder) {}

  /**
   * call server logout path to revome the user Session form the server.
   */
  public logout(): Observable<void> {
    return this.http.delete<void>(AuthenticationService.logoutUrl);
  }

  /**
   * method to connect a user.
   *
   * @param username username of the user
   * @param password password
   */
  public login(username: string, password: string): Observable<ISessionUserModelLike> {
    return this.http
      .post<ISessionUserModelLike>(
        AuthenticationService.loginUrl,
        JSON.stringify({ login: username, password: password })
      )
      .pipe(map((response) => this.builder.convertJsonToModele(response)));
  }
}
