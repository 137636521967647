import { ICrudConfigModel, CrudConfigModel } from '@shared/modeles/crud/crud-config.model';

export interface IRtlqConfigLike {
  id: string;
  module: string;
  domaine: string;
}
export interface IRtlqWithColumnConfigLike extends IRtlqConfigLike {
  getCols(): ICrudConfigModel[];
}

export abstract class AbstractRtlqConfig implements IRtlqWithColumnConfigLike {
  abstract get domaine(): string;
  abstract get module(): string;
  abstract get id(): string;
  abstract getCols(): ICrudConfigModel[];

  createCrudConfigModel(key: string): CrudConfigModel {
    return new CrudConfigModel(key, this.domaine, this.module);
  }
}
