export function AutoUnsubDecorator() {
  return function (constructor) {
    const orig = constructor.prototype.ngOnDestroy;
    constructor.prototype.ngOnDestroy = function () {
      orig.apply();

      if (this) {
        Object.getOwnPropertyNames(this).forEach((prop) => {
          const property = this[prop];
          if (property && typeof property.subscribe === 'function') {
            if (property.unsubscribe === 'function') {
              property.unsubscribe();
            }
          }
        });
      }
    };
  };
}
