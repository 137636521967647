/** @format */

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CurrentUserService } from '@user/api/current-user/current-user.service';
import { map, switchMap } from 'rxjs/operators';
import { CurrentUserActions } from './current-user.types';

@Injectable()
export class CurrentUserEffects {
  @Effect()
  fectchingCurrentUser() {
    return this.actions$.pipe(
      ofType(CurrentUserActions.fetchingCurrentUserAction),
      switchMap((action) => {
        return this.currentUserService.get().pipe(
          map((resData) => {
            return CurrentUserActions.fetchedCurrentUserAction({ user: resData });
          })
        );
      })
    );
  }

  @Effect()
  fectchingStats() {
    return this.actions$.pipe(
      ofType(CurrentUserActions.fetchingStatsAction),
      switchMap((action) => {
        return this.currentUserService.getStats().pipe(
          map((resData) => {
            return CurrentUserActions.fetchedStatsAction({ stats: resData });
          })
        );
      })
    );
  }

  @Effect()
  savingCurrentUser() {
    return this.actions$.pipe(
      ofType(CurrentUserActions.savingCurrentUserAction),
      switchMap((action) => {
        return this.currentUserService.update(action.userToUpdate).pipe(
          map((resData) => {
            return CurrentUserActions.fetchedCurrentUserAction({ user: resData });
          })
        );
      })
    );
  }
  constructor(private actions$: Actions, private currentUserService: CurrentUserService) {}
}
