import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { IAssociationModelLike } from '@association/models/association.model';
import { AssociationConfig } from '@association/ressource/association.config';

@Injectable({ providedIn: 'root' })
export class AssociationEntityService extends EntityCollectionServiceBase<IAssociationModelLike> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(AssociationConfig.STORE, serviceElementsFactory);
  }
}
