import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ICrudService } from '@shared/api/crud/i-crud.service';
import { AbstractRtlqService } from '@shared/api/crud/abstract-main.service';

import { ISaisonModelLike } from '@saison/models/saison.model';
import { SaisonBuilder } from '@saison/builder/saison.builder';
import { SaisonConfig } from '@saison/resource/saison.config';

export interface ISaisonServiceLike extends ICrudService<ISaisonModelLike> {
  doDuplicateAndCreate(id: number): Observable<ISaisonModelLike>;
  getCurrentSaison(): Observable<ISaisonModelLike>;
}
@Injectable({
  providedIn: 'root',
})
export class SaisonService extends AbstractRtlqService<ISaisonModelLike> implements ISaisonServiceLike {
  constructor(protected http: HttpClient, protected builder: SaisonBuilder) {
    super(http, builder, SaisonConfig.REST_URL);
  }
  doDuplicateAndCreate(id: number): Observable<ISaisonModelLike> {
    return this.getHttp()
      .post(`${this.baseUrl}/${this.getUrl()}/${id}/${SaisonConfig.REST_URI_SAISON_DUPLICATE}`, {})
      .pipe(map((response) => this.mapEntity(response)));
  }

  getCurrentSaison(): Observable<ISaisonModelLike> {
    return this.getHttp()
      .get(`${this.baseUrl}/${this.getUrl()}/${SaisonConfig.REST_URI_SAISON_ACTIVE}`, {})
      .pipe(
        map((response) => {
          const firstResponse = response[0];
          return this.mapEntity(firstResponse);
        })
      );
  }
}
