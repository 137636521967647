/** @format */

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SaisonService } from '@saison/api/saison/saison.service';
import { AppState } from 'app/store/app.reducer';
import { map, switchMap } from 'rxjs/operators';
import { CurrentSaisonActions } from './current-saison.types';

@Injectable()
export class CurrentSaisonEffects {
  @Effect()
  fectchingData() {
    return this.actions$.pipe(
      ofType(CurrentSaisonActions.fetchingCurrentSaisonAction),
      switchMap((action) => {
        return this.saisonService.getCurrentSaison().pipe(
          map((resData) => {
            return CurrentSaisonActions.fetchedCurrentSaisonAction({ saison: resData });
          })
        );
      })
    );
  }

  constructor(
    private actions$: Actions,
    private saisonService: SaisonService,
    private store: Store<AppState>
  ) {}
}
