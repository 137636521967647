import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';
import { IDropdownModelLike } from '@shared/modeles/dropdown/dropdown.model';
import { AbstractCrudDropDownModel } from '@shared/modeles/crud/absract-crud.model';

export interface IKungFuNiveauModelLike extends ICrudModelLike, IDropdownModelLike {}

export class KungFuNiveauModel extends AbstractCrudDropDownModel implements IKungFuNiveauModelLike {
  constructor(public id = null, public nom = null) {
    super(id);
  }
}
