<div *ngIf="field.invalid && field.errors && (field.dirty || field.touched)">
  <small class="text-danger" *ngIf="field.hasError('required')"> 
    Champ obligatoire. </small>
  <small class="text-danger" *ngIf="field.hasError('minlength')">
    Ce champ doit avoir au moins {{ field.errors.minlength.requiredLength }} caractères.
  </small>
  <small class="text-danger" *ngIf="field.hasError('maxlength')">
    Ce champ doit avoir au maximum {{ field.errors.minlength.requiredLength }} caractères.
  </small>
</div>
