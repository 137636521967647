import { IDateCreationModelLike } from '@shared/modeles/crud/i-crud-with-date.model';
import { IActifLike } from '@shared/modeles/crud/i-actif.like';
import { AbstractCrudActifWithDateModelLike } from '@shared/modeles/crud/abstract-crud-actif-with-date.model';
import { ICrudModelLike } from '@shared/modeles/crud/i-crud.model';

export interface IAssociationModelLike extends ICrudModelLike, IDateCreationModelLike, IActifLike {
  long_name: string;
  short_name: string;
  siege_social_address: string;
  siege_social_postal_code: string;
  siege_social_city: string;
  numero_siren: string;
  numero_compte_bancaire: string;
  url_intranet: string;
  url_extranet: string;
  president_nom_prenom: string;
  tresorier_nom_prenom: string;
  secretaire_nom_prenom: string;
  message: string;
  closed: boolean;
  logo_url: string;
  cours_short_description: string;
  contact_phone_number: string;
  contact_email: string;
  contact_name: string;
  url_agenda: string;
}

export class AssociationModel extends AbstractCrudActifWithDateModelLike implements IAssociationModelLike {
  constructor(
    public id = null,
    public long_name: string = null,
    public short_name: string = null,
    public siege_social_address: string = null,
    public siege_social_postal_code: string = null,
    public siege_social_city: string = null,
    public numero_siren: string = null,
    public numero_compte_bancaire: string = null,
    public url_intranet: string = null,
    public url_extranet: string = null,
    public president_nom_prenom: string = null,
    public tresorier_nom_prenom: string = null,
    public secretaire_nom_prenom: string = null,
    public message: string = null,
    public closed: boolean = null,
    public logo_url: string = null,
    public cours_short_description: string = null,
    public contact_phone_number: string = null,
    public contact_email: string = null,
    public contact_name: string = null,
    public url_agenda: string = null
  ) {
    super(id);
  }

  customConvertToModele(dto: any): IAssociationModelLike {
    return null;
  }

  public consolidation() {
    super.consolidation();
  }

  initBeforeCreating() {
    super.initBeforeCreating();

    this.actif = false;
  }
}
