import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { RessourceService } from '@shared/api/config/ressource.service';
import { PageConfigModel } from '@shared/modeles/config/page-config.model';
import { UserService } from '@user/api/user/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  model: any = {};
  loading = false;
  error = '';
  info = '';

  constructor(private router: Router, private userService: UserService) {}

  ngOnInit() {}

  resetPassword(form : NgForm) {
    this.loading = true;
    this.info = '';
    this.error = '';

    this.userService.resetPassword(form.value['email']).subscribe(
      (p) => {
        this._dealSuccess(p);
      },
      (error) => {
        this._dealError(error);
      }
    );
  }

  private _dealSuccess(p) {
    this.loading = false;
    this.info = 'Si votre compte est connu alors un email vous a été envoyé !';
  }

  private _dealError(error) {
    this.loading = false;
    this.info = 'Si votre compte est connu alors un email vous a été envoyé !';
  }

  public getLoginRoute() {
    return PageConfigModel.getUri(RessourceService.MENU.URL_LOGIN);
  }
}
